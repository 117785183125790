import "./hk-dashboard.scss";
import * as template from "./hk-dashboard.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DataStorageTile } from "../data-storage-tile/data-storage-tile";
import { ItemInspectorTile } from "../item-inspector-tile/item-inspector-tile";
import { Dashboard } from "../dashboard/dashboard";
import { HkDashboardOptions } from "./types";
import { ParkingAreaTile } from "../parking-area-tile/parking-area-tile";
import { TrafficOccupancyAreaTile } from "../traffic-occupancy-area-tile/traffic-occupancy-area-tile";
import { TrafficIncidentTile } from "../traffic-incident-tile/traffic-incident-tile";
import { WazeFeedDetail } from "../../traffic/waze-feed-detail/waze-feed-detail";
import { WazeFeedTile } from "../waze-feed-tile/waze-feed-tile";

export class HkDashboard extends Dashboard<HkDashboardOptions> {

    constructor(context: InvipoContext, options?: HkDashboardOptions) {
        super(context, template, options);
    }

    protected createColumns(): void {
        // Create columns
        this.columns = [
            {
                name: "System",
                width: "1",
                tiles: [
                    new DataStorageTile(this.context),
                    new ItemInspectorTile(this.context)
                ]
            },
            {
                name: "Parking",
                width: "1",
                tiles: [
                    new ParkingAreaTile(this.context, {
                        title: "components.ParkingAreaTile.title",
                        areaId: "65807382359ad999c173fa78"
                    })
                ]
            },
            {
                name: "Traffic",
                width: "2",
                tiles: [
                    new TrafficOccupancyAreaTile(this.context, {
                        title: "components.TrafficOccupancyAreaTile.title",
                        areaId: "63613cfcd61148e06968a39f"
                    }),
                    new TrafficIncidentTile(this.context, {
                        type: "PedestrianOnRoad"
                    }),
                    new WazeFeedTile(this.context)
                ]
            }

        ];
    }
}
