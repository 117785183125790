import "./hanoi-violation-decision-form.scss";
import * as template from "./hanoi-violation-decision-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { HanoiViolationDecisionFormOptions } from "./types";
import { TextInput } from "../../../../muklit/components/text-input/text-input";
import { DateInput } from "../../../../muklit/components/date-input/date-input";
import { Select } from "../../../../muklit/components/select/select";
import { COLUMN_LEGAL_DECISION, COLUMN_REPORT_MANAGEMENT } from "../hanoi-violation-browser/hanoi-violation-browser";

export class HanoiViolationDecisionForm extends Detail<InvipoContext, HanoiViolationDecisionFormOptions> {

    // Components
    public form: Form;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: HanoiViolationDecisionFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
    }

    private createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "Row",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "plateNumber",
                            label: "forms.fields.plateNumber",
                            value: this.options.violation.data.incident.extras.plate.number,
                            width: 288,
                            bright: true,
                            disabled: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "violationType",
                            label: "forms.fields.violationType",
                            value: this.options.violation.type,
                            width: 288,
                            bright: true,
                            disabled: true
                        })
                    ]
                },
                {
                    name: "Row",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "reportNumber",
                            label: "forms.fields.reportNumber",
                            value: this.options.violation.extras.report.reportNumber,
                            width: 288,
                            bright: true,
                            disabled: true
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            type: "DateTime",
                            name: "reportTimestamp",
                            label: "forms.fields.reportTimestamp",
                            value: this.options.violation.extras.report.timestamp,
                            width: 288,
                            bright: true,
                            disabled: true
                        })
                    ]
                },
                {
                    name: "Row",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "decisionNumber",
                            label: "forms.fields.decisionNumber",
                            value: `N${Math.floor(Date.now() / 1000)}`,
                            width: 288,
                            bright: true,
                            required: true
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            type: "DateTime",
                            name: "timestamp",
                            label: "forms.fields.decisionTimestamp",
                            value: new Date().toISOString(),
                            width: 288,
                            bright: true,
                            required: true
                        }),
                    ]
                },
                {
                    name: "Row",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "decisionMakerName",
                            label: "forms.fields.decisionMaker",
                            value: this.context.data.getUsers().find(x => x.id == this.context.user.id).name,
                            width: 216,
                            bright: true,
                            disabled: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "decisionMakerTitle",
                            label: "forms.fields.title",
                            value: this.context.data.getUsers().find(x => x.id == this.context.user.id).title,
                            width: 172,
                            bright: true,
                            disabled: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "decisionMakerDepartment",
                            label: "forms.fields.department",
                            value: this.context.data.getUsers().find(x => x.id == this.context.user.id).department,
                            width: 172,
                            bright: true,
                            disabled: true
                        })
                    ]
                },
                {
                    name: "Row",
                    label: "details.blocks.fine",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            format: "Number",
                            name: "fineAmount",
                            label: "forms.fields.fineAmount",
                            placeholderText: "VND",
                            width: 288,
                            bright: true,
                            required: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            format: "Number",
                            name: "finePeriod",
                            label: "forms.fields.finePeriod",
                            placeholderText: "forms.placeholders.days",
                            width: 288,
                            bright: true,
                            required: true
                        }),
                    ]
                },
                {
                    name: "Row",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "fineLocation",
                            label: "forms.fields.fineLocation",
                            width: 592,
                            bright: true,
                            required: true
                        })
                    ]
                }
            ]
        });

        // Set report data to form if not empty
        if (this.options.violation.extras?.decision) {
            this.form.setData(this.options.violation.extras.decision);
        }
    }

    public async submit(): Promise<void> {
        // Basic form validation?
        if (!this.form.validate()) {
            return;
        }

        // Get form data
        let data = this.form.getData(true);

        // Get extras reference
        let extras = this.options.violation.extras || {};

        // Set decision data to extras
        extras.decision = {
            ...data
        }

        // Show loader
        this.showLoader();

        // Create new user with two form merged together
        try {
            // Update data
            await this.context.invipo.putManagement(`violations/${this.options.violation.id}/extras`, {
                extras: extras
            });

            // Update column
            await this.context.invipo.putManagement(`violations/${this.options.violation.id}/workflow/column`, {
                column: COLUMN_LEGAL_DECISION
            });
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnNotificationSubmit handler
        this.onSubmit();
    }

}
