import "./catalog-dataset-file-form.scss";
import * as template from "./catalog-dataset-file-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { CatalogDatasetFormOptions } from "./types";
import { ImageInput } from "../../../../muklit/components/image-input/image-input";

export class CatalogDatasetFileForm extends Detail<InvipoContext, CatalogDatasetFormOptions> {

    // Components
    public form: Form;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: CatalogDatasetFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
    }

    private createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new ImageInput(this.context, {
                            style: "Light",
                            name: "file",
                            label: "forms.fields.file",
                            width: 420,
                            height: 220,
                            required: true
                        })
                    ]
                }
            ]
        });

        // Register component
        this.registerComponent(this.form, "form");
    }

    public async submit(): Promise<void> {
        // Basic form validation?
        if (!this.form.validate()) {
            return;
        }

        // Get form data
        let data = this.form.getData(true);

        // Show loader
        this.showLoader();

        // Send command request to cahnge parking occupancy
        try {
            await this.context.invipo.postManagement(`catalog/datasets/${this.options.dataset.id}/files`, {
                filename: this.form.getField<ImageInput>("file").name,
                content: this.form.getField<ImageInput>("file").options.value
            });
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnSubmit handler
        this.onSubmit();
    }

}
