import "./patra-dashboard.scss";
import * as template from "./patra-dashboard.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { Dashboard } from "../dashboard/dashboard";
import { DataStorageTile } from "../data-storage-tile/data-storage-tile";
import { ItemInspectorTile } from "../item-inspector-tile/item-inspector-tile";
import { PatraDashboardOptions } from "./types";
import { ParkingOccupancyTile } from "../parking-occupancy-tile/parking-occupancy-tile";

export class PatraDashboard extends Dashboard<PatraDashboardOptions> {

    constructor(context: InvipoContext, options?: PatraDashboardOptions) {
        super(context, template, options);
    }

    protected createColumns(): void {
        // Create columns
        this.columns = [
            {
                name: "System",
                width: "1",
                tiles: [
                    new DataStorageTile(this.context),
                    new ItemInspectorTile(this.context)
                ]
            },
            {
                name: "Parking",
                width: "4",
                tiles: []
            }
        ];

        // Get list of traffic volume areas
        let items = this.context.data.getItems({
            class: "ParkingLot",
            sort: "name:asc"
        });

        // Create traffic tiles dynamically
        for (let item of items) {
            // Add tile
            this.columns[1].tiles.push(new ParkingOccupancyTile(this.context, {
                itemId: item.id
            }));
        }
    }
}
