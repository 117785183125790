import { ContentView } from "./content-view";
import { DrawerMenu } from "../components/common/drawer-menu/drawer-menu";
import { DrawerItem } from "../components/common/drawer-menu/types";

export class Profile extends ContentView {

    // Components
    public menu: DrawerMenu;

    public onCreate(): void {
        // Create components
        this.createMenu();

        // Register components that will be automatically attached/detached
        this.registerComponent(this.menu);

        // Default content
        this.setContent("UserProfile");
    }

    private createMenu(): void {
        // Create component
        this.menu = new DrawerMenu(this.context, {
            style: "Light",
            groups: [
                {
                    name: "User",
                    items: [
                        {
                            name: "UserProfile",
                            label: "components.UserProfile.title",
                            selected: true
                        },
                        {
                            name: "UserExports",
                            label: "components.UserExports.title"
                        }
                    ]
                }
            ]
        })

        // Change content component
        this.menu.onSelect = (item: DrawerItem) => {
            this.setContent(item.name);
        }
    }
}
