import "./traffic-incident-detail.scss";
import * as template from "./traffic-incident-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { TrafficIncidentDetailOptions } from "./types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { LineString, Point } from "geojson";
import { ZOOM_LABEL } from "muklit/components/basic-map/map-layer";
import { TrafficIncidentCircleLayer } from "../../../layers/traffic/traffic-incident-circle-layer";
import { TrafficIncidentSymbolLayer } from "../../../layers/traffic/traffic-incident-symbol-layer";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { VehicleRegistryDetail } from "../vehicle-registry-detail/vehicle-registry-detail";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { VideoDetail } from "../../common/video-detail/video-detail";
import { OffensePreview } from "../offense-preview/offense-preview";

export class TrafficIncidentDetail extends Detail<InvipoContext, TrafficIncidentDetailOptions> {

    // Properties
    public item: InvipoItem;

    // Components
    public map: BasicMap;
    public layer: TrafficIncidentDetailOptions;

    constructor(context: InvipoContext, options: TrafficIncidentDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
    }

    public onAttach() {
        // Remove layers we have added previously
        this.map.removeLayers();

        // Recreate new layers to fetch new data
        this.map.addLayer(new TrafficIncidentCircleLayer(this.context, <any>this.options.incident));
        this.map.addLayer(new TrafficIncidentSymbolLayer(this.context, <any>this.options.incident));

        // Center map to first point in line
        if (this.options.incident.position.type == "LineString") {
            this.map.setCenter((<LineString>this.options.incident.position).coordinates[0]);
        }

        // Center map to coordinates
        if (this.options.incident.position.type == "Point") {
            this.map.setCenter((<Point>this.options.incident.position).coordinates);
        }
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.home.center,
            zoom: ZOOM_LABEL,
            minZoom: 5,
            maxZoom: 20
        });
    }

    public selectImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Show
        detail.attach();
    }

    public openVideo(): void {
        // New image detail
        let detail = new VideoDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            timestamp: new Date(new Date(this.options.incident.timestamp).getTime() - 1000).toISOString(),
            position: (<Point>this.item.geometry.location).coordinates,
            overlay: true,
            closable: true
        });

        // Show
        detail.attach();
    }

    public openOffense(): void {
        let form = new OffensePreview(this.context, {
            style: "Light",
            title: "components.OffensePreview.title",
            key: "incident.offense",
            data: this.options.incident,
            overlay: true,
            printable: true,
            closable: true
        });

        // Show form
        form.attach();
    }

    public openOffenseImages(): void {
        let form = new OffensePreview(this.context, {
            style: "Light",
            title: "components.OffensePreview.title",
            key: "incident.offense.images",
            data: this.options.incident,
            overlay: true,
            printable: true,
            closable: true
        });

        // Show form
        form.attach();
    }

    public openRegistry(): void {
        // Detail form
        let detail = new VehicleRegistryDetail(this.context, {
            style: "Light",
            title: null,
            overlay: true,
            closable: true,
            lpn: this.options.incident.extras.plate?.number
        });

        // Show form
        detail.attach();
    }

    public async load(): Promise<void> {
        // Load item
        this.item = this.context.data.getItem(this.options.incident.item.id);

        // Redraw with all components
        this.invalidate(true);
    }
}
