import "./catalog-dataset-detail.scss";
import * as template from "./catalog-dataset-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Dialog } from "muklit/components/dialog/dialog";
import { CatalogDatasetDetailOptions } from "./types";
import { CatalogDatasetGeneralForm } from "../catalog-dataset-general-form/catalog-dataset-general-form";
import { CatalogDatasetFileForm } from "../catalog-dataset-file-form/catalog-dataset-file-form";

export class CatalogDatasetDetail extends Detail<InvipoContext, CatalogDatasetDetailOptions> {

    // Properties
    public dataset: any;

    // Event handling methods
    public onDetailUpdate(): void {}

    constructor(context: InvipoContext, options: CatalogDatasetDetailOptions) {
        super(context, template, options);
    }

    public openGeneral(): void {
        // Details form
        let form = new CatalogDatasetGeneralForm(this.context, {
            style: "Light",
            title: null,
            dataset: this.dataset,
            overlay: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Update
            await this.load();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show form
        form.attach();
    }

    public openFile(): void {
        // Details form
        let form = new CatalogDatasetFileForm(this.context, {
            style: "Light",
            title: null,
            dataset: this.dataset,
            overlay: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Update
            await this.load();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show form
        form.attach();
    }

    public deleteFile(i: number): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.CatalogDatasetDetail.fileDelete",
            text: this.context.locale.getMessage("components.CatalogDatasetDetail.reallyDelete", this.dataset.files[i].name),
            labelCancel: "labels.cancel",
            labelConfirm: "labels.delete",
            escalated: true
        })

        // OnUserLogout handler
        dialog.onConfirm = async () => {
            // Delete call
            await this.context.invipo.deleteManagement(`/catalog/datasets/${this.dataset.id}/files/${this.dataset.files[i].uuid}`);

            // Close dialog
            dialog.close();

            // Relaod
            await this.load();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show dialog
        dialog.attach();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        this.dataset = await this.context.invipo.getManagement(`catalog/datasets/${this.options.datasetId}`);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Redraw with all components
        this.invalidate(true);
    }

}
