import "./notification-form.scss";
import * as template from "./notification-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { TextInput } from "muklit/components/text-input/text-input";
import { NotificationFormOptions } from "./types";
import { Input } from "muklit/components/input/input";
import { Select } from "muklit/components/select/select";
import { ItemSelect } from "../../common/item-select/item-select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { TextArea } from "muklit/components/text-area/text-area";
import { Notification } from "../../../clients/invipo-client/types";
import { PickerMenu } from "../../common/picker-menu/picker-menu";
import { Helpers } from "hiyo/helpers";
import { PickerGroup, PickerItem } from "../../common/picker-menu/types";
import { FormFieldset } from "muklit/components/form/types";
import { Checkbox } from "muklit/components/checkbox/checkbox";
import { MenuItem } from "muklit/components/overflow-menu/types";

export const NOTIFICATION_GROUPS: any = {
    Data: ["ItemData", "TimeDifference", "OppositeVehicle", "TrafficEvent", "TrafficMessage", "TrafficIncident", "VehicleSpeed", "WasteCollectionWeight", "GarbageLevel", "GarbageTemperature", "WazeData"],
    Status: ["ItemStatus", "AccessEvaluation"],
    Violations: ["WatchlistVehicle"],
    TrafficControl: ["TrafficControlState", "TrafficControlMode", "TrafficControlPlan"],
    Power: ["PowerLost", "BatteryVoltage", "BatteryTemperature"],
    Other: ["DoorOpened"]
}

export class NotificationForm extends Detail<InvipoContext, NotificationFormOptions> {

    // Components
    public menu: PickerMenu;
    public form: Form;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: NotificationFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMenu();
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.menu, "menu");
        this.registerComponent(this.form, "form");
    }

    public onAttach() {
        // Show proper content
        this.toggle(this.options.notification.type ? "form" : "menu");
    }

    private createMenu(): void {
        // Create component
        this.menu = new PickerMenu(this.context, {
            style: "Light",
            title: "components.NotificationForm.createNotification",
            closable: true,
            overlay: true,
            groups: []
        });

        // Auto create items based on group definition and invipo.json cofiguration
        for (let g of Object.keys(NOTIFICATION_GROUPS)) {
            // New group to add
            let group: PickerGroup = {
                name: g,
                title: `components.NotificationManager.groups.${g}`,
                items: []
            }

            // Get all items from defintion
            for (let n of NOTIFICATION_GROUPS[g]) {
                // Notification not supported by config?
                if (!this.context.options.notifications.includes(n)) {
                    continue;
                }

                // New group
                group.items.push({
                    name: n,
                    icon: g == "Data" ? "Data" : "Change",
                    label: `components.NotificationManager.types.${n}.label`,
                    description: `components.NotificationManager.types.${n}.description`
                });
            }

            // Adding only non-empty groupd to menu
            if (group.items.length > 0) {
                this.menu.options.groups.push(group);
            }
        }

        this.menu.onSelect = (item: PickerItem) => {
            // Set selected notification type
            this.options.notification.type = item.name;

            // Detachg and recreate the form due to custom validation fields
            this.form.detach();
            this.createForm();

            // Rerender form because field options have changed
            this.form.attach(this.query("div.content-form div.form"));

            // Finally switch to form content
            this.toggle("form");
        }
    }

    private createForm(): void {
        // Custom form fields based on rule type
        let fields: Input[] = [];

        // BatteryVoltage condition
        if (this.options.notification.type == "BatteryVoltage") {
            fields.push(
                new TextInput(this.context, {
                    style: "Light",
                    format: "Number",
                    name: "conditionLow",
                    label: "components.NotificationManager.types.BatteryVoltage.condition.low.label",
                    value: this.options.notification.condition?.low,
                    width: 320,
                    required: true,
                    bright: true
                }),
                new TextInput(this.context, {
                    style: "Light",
                    format: "Number",
                    name: "conditionEmpty",
                    label: "components.NotificationManager.types.BatteryVoltage.condition.empty.label",
                    value: this.options.notification.condition?.empty,
                    width: 320,
                    required: true,
                    bright: true
                }),
            );
        }

        // BatteryTemperature condition
        if (this.options.notification.type == "BatteryTemperature") {
            fields.push(
                new TextInput(this.context, {
                    style: "Light",
                    format: "Number",
                    name: "conditionLow",
                    label: "components.NotificationManager.types.BatteryTemperature.condition.low.label",
                    value: this.options.notification.condition?.low,
                    width: 320,
                    required: true,
                    bright: true
                }),
                new TextInput(this.context, {
                    style: "Light",
                    format: "Number",
                    name: "conditionHigh",
                    label: "components.NotificationManager.types.BatteryTemperature.condition.high.label",
                    value: this.options.notification.condition?.high,
                    width: 320,
                    required: true,
                    bright: true
                }),
            );
        }

        // ItemData condition
        if (this.options.notification.type == "ItemData") {
            fields.push(new Select(this.context, {
                style: "Light",
                name: "conditionKey",
                label: "components.NotificationManager.types.ItemData.condition.key.label",
                items: [
                    {
                        name: "traffic",
                        label: "traffic"
                    },
                    {
                        name: "occupancy",
                        label: "occupancy"
                    },
                    {
                        name: "consumption",
                        label: "consumption"
                    },
                    {
                        name: "snapshot",
                        label: "snapshot"
                    }
                ],
                value: this.options.notification.condition?.key,
                width: 320,
                required: true,
                bright: true
            }));
        }

        // TimeDifference condition
        if (this.options.notification.type == "TimeDifference") {
            fields.push(
                new TextInput(this.context, {
                    style: "Light",
                    format: "Number",
                    name: "conditionTolerance",
                    label: "components.NotificationManager.types.TimeDifference.condition.tolerance.label",
                    value: this.options.notification.condition?.tolerance,
                    width: 320,
                    required: true,
                    bright: true
                })
            );
        }

        // ItemStatus condition
        if (this.options.notification.type == "ItemStatus") {
            fields.push(
                new Select(this.context, {
                    style: "Light",
                    format: "Number",
                    name: "conditionState",
                    label: "components.NotificationManager.types.ItemStatus.condition.state.label",
                    items: [
                        {
                            name: "Better",
                            label: "components.NotificationManager.types.ItemStatus.condition.state.enum.Better"
                        },
                        {
                            name: "Worse",
                            label: "components.NotificationManager.types.ItemStatus.condition.state.enum.Worse"
                        },
                        {
                            name: "Both",
                            label: "components.NotificationManager.types.ItemStatus.condition.state.enum.Both"
                        },
                    ],
                    value: this.options.notification.condition?.state,
                    width: 320,
                    required: true,
                    bright: true
                })
            );
        }

        // AccessEvaluation condition
        if (this.options.notification.type == "AccessEvaluation") {
            fields.push(
                new Select(this.context, {
                    style: "Light",
                    name: "conditionAuthorization",
                    label: "components.NotificationManager.types.AccessEvaluation.condition.authorization.label",
                    placeholderText: "components.NotificationManager.types.AccessEvaluation.condition.authorization.placeholder",
                    items: [
                        {
                            name: "Granted",
                            label: "enums.AccessAuthorization.Granted"
                        },
                        {
                            name: "Denied",
                            label: "enums.AccessAuthorization.Denied"
                        }
                    ],
                    value: this.options.notification.condition?.authorization,
                    width: 672,
                    required: true,
                    bright: true
                })
            );
        }

        // VehicleSpeed condition
        if (this.options.notification.type == "VehicleSpeed") {
            fields.push(
                new TextInput(this.context, {
                    style: "Light",
                    name: "conditionLimit",
                    label: "components.NotificationManager.types.VehicleSpeed.condition.limit.label",
                    placeholderText: "components.NotificationManager.types.VehicleSpeed.condition.limit.placeholder",
                    value: this.options.notification.condition?.limit,
                    width: 672,
                    required: true,
                    bright: true
                })
            );
        }

        // TrafficEvent condition
        if (this.options.notification.type == "TrafficEvent") {
            fields.push(
                new Select(this.context, {
                    style: "Light",
                    name: "conditionType",
                    label: "components.NotificationManager.types.TrafficEvent.condition.type.label",
                    placeholderText: "components.NotificationManager.types.TrafficEvent.condition.type.placeholder",
                    items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.TrafficEventType"), null, (a: MenuItem, b: MenuItem) => {
                        return this.context.locale.getMessage(a.name).localeCompare(this.context.locale.getMessage(b.name));
                    }),
                    value: this.options.notification.condition?.type,
                    width: 320,
                    required: true,
                    bright: true
                })
            );
        }

        // TrafficMessage condition
        if (this.options.notification.type == "TrafficMessage") {
            fields.push(
                new Select(this.context, {
                    style: "Light",
                    name: "conditionType",
                    label: "components.NotificationManager.types.TrafficMessage.condition.type.label",
                    placeholderText: "components.NotificationManager.types.TrafficMessage.condition.type.placeholder",
                    items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.TrafficMessageType"), null, (a: MenuItem, b: MenuItem) => {
                        return this.context.locale.getMessage(a.name).localeCompare(this.context.locale.getMessage(b.name));
                    }),
                    value: this.options.notification.condition?.type,
                    width: 320,
                    required: true,
                    bright: true
                })
            );
            fields.push(
                new Select(this.context, {
                    style: "Light",
                    name: "conditionSource",
                    label: "components.NotificationManager.types.TrafficMessage.condition.source.label",
                    placeholderText: "components.NotificationManager.types.TrafficMessage.condition.source.placeholder",
                    items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.TrafficMessageSource"), null, (a: MenuItem, b: MenuItem) => {
                        return this.context.locale.getMessage(a.name).localeCompare(this.context.locale.getMessage(b.name));
                    }),
                    value: this.options.notification.condition?.source,
                    width: 320,
                    required: true,
                    bright: true
                })
            );
        }

        // TrafficIncident condition
        if (this.options.notification.type == "TrafficIncident") {
            fields.push(
                new Select(this.context, {
                    style: "Light",
                    name: "conditionType",
                    label: "components.NotificationManager.types.TrafficIncident.condition.type.label",
                    placeholderText: "components.NotificationManager.types.TrafficIncident.condition.type.placeholder",
                    items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.TrafficIncidentType"), null, (a: MenuItem, b: MenuItem) => {
                        return this.context.locale.getMessage(a.name).localeCompare(this.context.locale.getMessage(b.name));
                    }),
                    value: this.options.notification.condition?.type,
                    width: 320,
                    required: true,
                    bright: true
                })
            );
        }

        // WasteCollectionWeight condtion
        if (this.options.notification.type == "WasteCollectionWeight") {
            fields.push(
                new Select(this.context, {
                    style: "Light",
                    name: "conditionOperator",
                    label: "components.NotificationManager.types.WasteCollectionWeight.condition.operator.label",
                    value: this.options.notification.condition?.operator,
                    items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.NotificationOperator")),
                    width: 320,
                    bright: true
                }),
                new TextInput(this.context, {
                    style: "Light",
                    format: "Number",
                    name: "conditionLimit",
                    label: "components.NotificationManager.types.WasteCollectionWeight.condition.limit.label",
                    value: this.options.notification.condition?.limit,
                    width: 320,
                    required: true,
                    bright: true
                }),
            );
        }

        // GarbageLevel condtion
        if (this.options.notification.type == "GarbageLevel") {
            fields.push(
                new Select(this.context, {
                    style: "Light",
                    name: "conditionOperator",
                    label: "components.NotificationManager.types.GarbageLevel.condition.operator.label",
                    value: this.options.notification.condition?.operator,
                    items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.NotificationOperator")),
                    width: 320,
                    bright: true
                }),
                new TextInput(this.context, {
                    style: "Light",
                    format: "Number",
                    name: "conditionLimit",
                    label: "components.NotificationManager.types.GarbageLevel.condition.limit.label",
                    value: this.options.notification.condition?.limit,
                    width: 320,
                    required: true,
                    bright: true
                }),
            );
        }

        // GarbageTemperature condtion
        if (this.options.notification.type == "GarbageTemperature") {
            fields.push(
                new Select(this.context, {
                    style: "Light",
                    name: "conditionOperator",
                    label: "components.NotificationManager.types.GarbageTemperature.condition.operator.label",
                    value: this.options.notification.condition?.operator,
                    items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.NotificationOperator")),
                    width: 320,
                    bright: true
                }),
                new TextInput(this.context, {
                    style: "Light",
                    format: "Number",
                    name: "conditionLimit",
                    label: "components.NotificationManager.types.GarbageTemperature.condition.limit.label",
                    value: this.options.notification.condition?.limit,
                    width: 320,
                    required: true,
                    bright: true
                }),
            );
        }

        // TrafficControlState condtion
        if (this.options.notification.type == "TrafficControlState") {
            fields.push(
                new Select(this.context, {
                    style: "Light",
                    name: "conditionState",
                    label: "components.NotificationManager.types.TrafficControlState.condition.state.label",
                    value: this.options.notification.condition?.state,
                    items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.TrafficLightControllerState")),
                    width: 320,
                    bright: true
                })
            );
        }

        // TrafficControlMode condtion
        if (this.options.notification.type == "TrafficControlMode") {
            fields.push(
                new Select(this.context, {
                    style: "Light",
                    name: "conditionMode",
                    label: "components.NotificationManager.types.TrafficControlMode.condition.mode.label",
                    value: this.options.notification.condition?.state,
                    items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.TrafficLightControllerMode")),
                    width: 320,
                    bright: true
                })
            );
        }

        // WatchlistVehicle condtion
        if (this.options.notification.type == "WatchlistVehicle") {
            fields.push(
                new Select(this.context, {
                    style: "Light",
                    name: "conditionWatchlist",
                    label: "components.NotificationManager.types.WatchlistVehicle.condition.watchlist.label",
                    value: this.options.notification.condition?.watchlist,
                    items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("WatchlistsVehicles", "watchlist")),
                    width: 320,
                    bright: true
                })
            );
        }

        // Single fields constructed separately due to direct event handling
        // Notification name
        let name = new TextInput(this.context, {
            style: "Light",
            name: "name",
            label: "forms.fields.name",
            value: this.options.notification.name,
            width: 672,
            required: true,
            bright: true
        });

        // Item context
        let items = new ItemSelect(this.context, {
            style: "Light",
            name: "items",
            label: "forms.fields.item",
            placeholderText: "forms.placeholders.all",
            //value: this.options.notification.items,
            itemClass: this.context.options.classes.join(","),
            items: [],
            value: this.options.notification.items && InvipoHelpers.toInputValue(this.options.notification.items, "id", "name"),
            width: 320,
            disabled: this.options.notification.classes?.length > 0,
            multiselect: true,
            bright: true
        });

        // Item class
        let classes = new Select(this.context, {
            style: "Light",
            name: "classes",
            label: "item.class",
            placeholderText: "forms.placeholders.all",
            items: InvipoHelpers.toClassItems(this.context.options.classes, (a, b) => {
                return this.context.locale.getMessage(a.label).localeCompare(this.context.locale.getMessage(b.label));
            }),
            value: this.options.notification.classes && InvipoHelpers.toInputValue(this.options.notification.classes),
            width: 320,
            disabled: this.options.notification.items?.length > 0,
            multiselect: true,
            bright: true
        });

        // Retention
        let retention = new TextInput(this.context, {
            style: "Light",
            format: "Number",
            name: "retention",
            label: "forms.fields.retention",
            placeholderText: "forms.placeholders.retention",
            value: this.options.notification.retention,
            width: 320,
            disabled: this.options.notification.items?.length > 0,
            bright: true
        });

        // Inbox subject
        let inboxSubject = new TextInput(this.context, {
            style: "Light",
            title: "forms.sections.inbox",
            name: "inboxSubject",
            label: "forms.fields.emailSubject",
            value: this.options.notification.channel?.inbox?.subject,
            width: 672,
            bright: true
        });

        // Inbox body
        let inboxBody = new TextArea(this.context, {
            style: "Light",
            name: "inboxBody",
            label: "forms.fields.inboxBody",
            value: this.options.notification.channel?.inbox?.body,
            rows: 8,
            width: 672,
            bright: true
        });

        // Email addresses
        let emailAddresses = new TextInput(this.context, {
            style: "Light",
            title: "forms.sections.email",
            name: "emailAddresses",
            label: "forms.fields.emailAddresses",
            placeholderText: "forms.placeholders.emailAddresses",
            value: this.options.notification.channel?.email?.addresses?.join("; "),
            width: 672,
            bright: true
        });

        // Email subject
        let emailSubject = new TextInput(this.context, {
            style: "Light",
            name: "emailSubject",
            label: "forms.fields.emailSubject",
            //placeholderText: "forms.placeholders.emailSubject",
            value: this.options.notification.channel?.email?.subject,
            width: 672,
            bright: true
        });

        // Email body
        let emailBody = new TextArea(this.context, {
            style: "Light",
            name: "emailBody",
            label: "forms.fields.emailBody",
            //placeholderText: "forms.placeholders.emailBody",
            value: this.options.notification.channel?.email?.body,
            rows: 8,
            width: 672,
            bright: true
        });

        // SMS numbers
        let smsNumbers = new TextInput(this.context, {
            style: "Light",
            title: "forms.sections.sms",
            name: "smsNumbers",
            label: "forms.fields.smsNumbers",
            placeholderText: "forms.placeholders.smsNumbers",
            value: this.options.notification.channel?.sms?.numbers?.join("; "),
            width: 672,
            bright: true
        });

        // SMS text
        let smsText = new TextArea(this.context, {
            style: "Light",
            name: "smsText",
            label: "forms.fields.smsText",
            //placeholderText: "forms.placeholders.smsText",
            value: this.options.notification.channel?.sms?.text,
            rows: 4,
            width: 672,
            bright: true
        });

        // Toast title
        let toastTitle = new TextInput(this.context, {
            style: "Light",
            title: "forms.sections.toast",
            name: "toastTitle",
            label: "forms.fields.toastTitle",
            //placeholderText: "forms.placeholders.toastTitle",
            value: this.options.notification.channel?.toast?.title,
            width: 672,
            bright: true
        });

        // Toast text
        let toastText = new TextArea(this.context, {
            style: "Light",
            name: "toastText",
            label: "forms.fields.toastText",
            //placeholderText: "forms.placeholders.toastText",
            value: this.options.notification.channel?.toast?.text,
            rows: 4,
            width: 672,
            bright: true
        });

        // Toast beep
        let toastBeep = new Checkbox(this.context, {
            style: "Light",
            name: "toastBeep",
            label: "forms.fields.toastBeep",
            value: this.options.notification.channel?.toast?.beep,
            bright: true
        });

        let fieldsets: FormFieldset[] = [
            {
                name: "General",
                label: `components.NotificationManager.types.${this.options.notification.type}.label`,
                fields: [name, items, classes, retention]
            },
            {
                name: "Channel",
                label: "forms.fieldsets.channel",
                fields: [inboxSubject, inboxBody, emailAddresses, emailSubject, emailBody, smsNumbers, smsText, toastTitle, toastText, toastBeep]
            }
        ];

        // Conditions fields defined?
        if (fields.length) {
            // Insert condition fields as second fieldset
            fieldsets.splice(1, 0,
                {
                    name: "Condition",
                    label: "forms.fieldsets.condition",
                    fields: fields
                }
            )
        }

        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: fieldsets
        });

        // Disable classes if items selected
        items.onSubmit = () => {
            classes.setDisabled(items.options.items.some(x => x.selected));
        }

        // Disable items if classes selected
        classes.onSubmit = () => {
            items.setDisabled(classes.options.items.some(x => x.selected));
        }

        // All inbox inputs required when one is filled
        inboxSubject.onKey = inboxBody.onKey = () => {
            inboxSubject.options.required = inboxBody.options.value;
            inboxBody.options.required = inboxSubject.options.value;
        }

        // All email inputs required when one is filled
        emailAddresses.onKey = emailSubject.onKey = emailBody.onKey = () => {
            emailAddresses.options.required = emailSubject.options.value || emailBody.options.value;
            emailSubject.options.required = emailAddresses.options.value || emailBody.options.value;
            emailBody.options.required = emailAddresses.options.value || emailSubject.options.value;
        }

        // All SMS inputs required when one is filled
        smsNumbers.onKey = smsText.onKey = () => {
            smsNumbers.options.required = smsText.options.value;
            smsText.options.required = smsNumbers.options.value;
        }

        // All toast inputs required when one is filled
        toastTitle.onKey = toastText.onKey = () => {
            toastTitle.options.required = toastText.options.value;
            toastText.options.required = toastTitle.options.value;
        }
    }

    public toggle(content: string): void {
        // Hide all contents
        this.queryAll("div.content").forEach((element: HTMLElement) => {
            element.style.display = "none";
        });

        // Show only selected content
        this.query(`div.content-${Helpers.toKebabCase(content)}`).style.display = "block";

        // Show control panel on form only
        this.query("div.control").style.display = (content == "form") ? "flex" : "none";
    }

    public async submit(): Promise<void> {
        // Basic form validation?
        if (!this.form.validate()) {
            return;
        }

        // Get form data
        let data = this.form.getData(true);

        // Create notification data
        let notification: Notification = {
            type: this.options.notification.type,
            name: data.name,
            channel: {}
        };

        // BatteryVoltage condition
        if (this.options.notification.type == "BatteryVoltage") {
            notification.condition = {
                low: data.conditionLow,
                empty: data.conditionEmpty
            }
        }

        // BatteryTemperature condition
        if (this.options.notification.type == "BatteryTemperature") {
            notification.condition = {
                low: data.conditionLow,
                high: data.conditionHigh
            }
        }

        // ItemData condition
        if (this.options.notification.type == "ItemData") {
            notification.condition = {
                key: data.conditionKey
            }
        }

        // TimeDifference condition
        if (this.options.notification.type == "TimeDifference") {
            notification.condition = {
                tolerance: data.conditionTolerance
            }
        }

        // ItemStatus condition
        if (this.options.notification.type == "ItemStatus") {
            notification.condition = {
                state: data.conditionState
            }
        }

        // AccessEvaluation condition
        if (this.options.notification.type == "AccessEvaluation") {
            notification.condition = {
                authorization: data.conditionAuthorization
            }
        }

        // VehicleSpeed condition
        if (this.options.notification.type == "VehicleSpeed") {
            notification.condition = {
                limit: data.conditionLimit
            }
        }

        // TrafficEvent condition
        if (this.options.notification.type == "TrafficEvent") {
            notification.condition = {
                type: data.conditionType
            }
        }

        // TrafficMessage condition
        if (this.options.notification.type == "TrafficMessage") {
            notification.condition = {
                type: data.conditionType,
                source: data.conditionSource
            }
        }

        // TrafficIncident condition
        if (this.options.notification.type == "TrafficIncident") {
            notification.condition = {
                type: data.conditionType
            }
        }

        // WasteCollectionWeight condition
        if (this.options.notification.type == "WasteCollectionWeight") {
            notification.condition = {
                operator: data.conditionOperator,
                limit: data.conditionLimit
            }
        }

        // GarbageLevel condition
        if (this.options.notification.type == "GarbageLevel") {
            notification.condition = {
                operator: data.conditionOperator,
                limit: data.conditionLimit
            }
        }

        // GarbageTemperature condition
        if (this.options.notification.type == "GarbageTemperature") {
            notification.condition = {
                operator: data.conditionOperator,
                limit: data.conditionLimit
            }
        }

        // TrafficControlState condition
        if (this.options.notification.type == "TrafficControlState") {
            notification.condition = {
                state: data.conditionState
            }
        }

        // TrafficControlMode condition
        if (this.options.notification.type == "TrafficControlMode") {
            notification.condition = {
                state: data.conditionMode
            }
        }

        // WatchlistVehicle condition
        if (this.options.notification.type == "WatchlistVehicle") {
            notification.condition = {
                watchlist: data.conditionWatchlist
            }
        }

        // Items selected?
        if (data.items) {
            notification.items = data.items.split(",").map((x: any) => { return { id: x }});
        }

        // Classes selected?
        if (data.classes) {
            notification.classes = data.classes.split(",");
        }

        // Retention selected?
        if (data.retention) {
            notification.retention = data.retention;
        }

        // Inbox selected?
        if (data.inboxSubject) {
            notification.channel.inbox = {
                subject: data.inboxSubject,
                body: data.inboxBody
            }
        }

        // Email selected?
        if (data.emailAddresses) {
            notification.channel.email = {
                addresses: Helpers.trim(data.emailAddresses.split(";")),
                subject: data.emailSubject,
                body: data.emailBody
            }
        }

        // SMS selected?
        if (data.smsNumbers) {
            notification.channel.sms = {
                numbers: Helpers.trim(data.smsNumbers.split(";")),
                text: data.smsText
            }
        }

        // Toast selected?
        if (data.toastTitle) {
            notification.channel.toast = {
                title: data.toastTitle,
                text: data.toastText,
                beep: data.toastBeep
            }
        }

        // Show loader
        this.showLoader();

        // Create new user with two form merged together
        try {
            if (this.options.notification.id) {
                // Update existing
                await this.context.invipo.updateNotification(this.options.notification.id, notification);
            }
            else {
                // Create new
                await this.context.invipo.createNotification(notification);
            }
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnNotificationSubmit handler
        this.onSubmit();
    }

}
