// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.invipo-draw-map {
  position: relative;
  width: 100%;
  height: 100%;
}
div.invipo-draw-map div.mapboxgl-canvas-container canvas {
  outline: none;
  animation: keyframes-draw-map-fade-in 1200ms;
}
div.invipo-draw-map div.mapboxgl-control-container a.mapboxgl-ctrl-logo, div.invipo-draw-map div.mapboxgl-control-container div.mapboxgl-ctrl-attrib {
  display: none;
}
div.invipo-draw-map div.mapboxgl-control-container div.mapboxgl-ctrl-group {
  box-shadow: none;
}
div.invipo-draw-map div.mapboxgl-control-container div.mapboxgl-ctrl-group button {
  border: 0;
}
div.invipo-draw-map button.mapbox-gl-draw_trash {
  display: none;
}
div.invipo-draw-map div.placeholder-control {
  position: absolute;
  right: 8px;
  bottom: 8px;
  z-index: 1;
}
@keyframes keyframes-draw-map-fade-in {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/muklit/components/draw-map/draw-map.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AAFF;AAKI;EACE,aAAA;EACA,4CAAA;AAHN;AAQI;EACE,aAAA;AANN;AASI;EACE,gBAAA;AAPN;AASM;EACE,SAAA;AAPR;AAYE;EACE,aAAA;AAVJ;AAaE;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,UAAA;AAXJ;AAcE;EACE;IACE,UAAA;EAZJ;EAcE;IACE,UAAA;EAZJ;EAcE;IACE,UAAA;EAZJ;AACF","sourcesContent":["@import \"../../styles/mixins\";\r\n@import \"../../styles/colors\";\r\n\r\ndiv.invipo-draw-map {\r\n  position: relative;\r\n  width: 100%;\r\n  height: 100%;\r\n\r\n  div.mapboxgl-canvas-container {\r\n    canvas {\r\n      outline: none;\r\n      animation: keyframes-draw-map-fade-in 1200ms;\r\n    }\r\n  }\r\n\r\n  div.mapboxgl-control-container {\r\n    a.mapboxgl-ctrl-logo, div.mapboxgl-ctrl-attrib {\r\n      display: none;\r\n    }\r\n\r\n    div.mapboxgl-ctrl-group {\r\n      box-shadow: none;\r\n\r\n      button {\r\n        border: 0;\r\n      }\r\n    }\r\n  }\r\n\r\n  button.mapbox-gl-draw_trash {\r\n    display: none;\r\n  }\r\n\r\n  div.placeholder-control {\r\n    position: absolute;\r\n    right: 8px;\r\n    bottom: 8px;\r\n    z-index: 1;\r\n  }\r\n\r\n  @keyframes keyframes-draw-map-fade-in {\r\n    0% {\r\n      opacity: 0;\r\n    }\r\n    70% {\r\n      opacity: 0;\r\n    }\r\n    100% {\r\n      opacity: 1;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
