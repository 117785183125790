// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.invipo-design-map {
  position: relative;
  width: 100%;
  height: 100%;
}
div.invipo-design-map div.mapboxgl-canvas-container canvas {
  outline: none;
}
div.invipo-design-map div.mapboxgl-control-container a.mapboxgl-ctrl-logo, div.invipo-design-map div.mapboxgl-control-container div.mapboxgl-ctrl-attrib {
  display: none;
}
div.invipo-design-map button.mapbox-gl-draw_trash {
  display: none;
}
div.invipo-design-map button.mapbox-gl-draw_trash.visible {
  display: block;
}
div.invipo-design-map div.placeholder-control {
  position: absolute;
  right: 8px;
  bottom: 8px;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/muklit/components/design-map/design-map.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AAFF;AAKI;EACE,aAAA;AAHN;AAQI;EACE,aAAA;AANN;AAUE;EACE,aAAA;AARJ;AAUI;EACE,cAAA;AARN;AAYE;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,UAAA;AAVJ","sourcesContent":["@import \"../../styles/mixins\";\n@import \"../../styles/colors\";\n\ndiv.invipo-design-map {\n  position: relative;\n  width: 100%;\n  height: 100%;\n\n  div.mapboxgl-canvas-container {\n    canvas {\n      outline: none;\n    }\n  }\n\n  div.mapboxgl-control-container {\n    a.mapboxgl-ctrl-logo, div.mapboxgl-ctrl-attrib {\n      display: none;\n    }\n  }\n\n  button.mapbox-gl-draw_trash {\n    display: none;\n\n    &.visible {\n      display: block;\n    }\n  }\n\n  div.placeholder-control {\n    position: absolute;\n    right: 8px;\n    bottom: 8px;\n    z-index: 1;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
