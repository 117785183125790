import "./emovis-wim-wall.scss";
import * as template from "./emovis-wim-wall.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { WallMap } from "../wall-map/wall-map";
import { EmovisWimWallOptions } from "./types";
import { MuklitComponent } from "../../../../muklit/components/muklit-component/muklit-component";
import { WallItems } from "../wall-items/wall-items";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { MonitoringDetailOptions } from "../../monitoring/monitoring-detail/types";
import { MonitoringDetail } from "../../monitoring/monitoring-detail/monitoring-detail";

export class EmovisWimWall extends MuklitComponent<InvipoContext, EmovisWimWallOptions> {

    // Properties
    private resizeListener: (event: Event) => void;

    // Components
    public map: WallMap;
    public items: WallItems;
    public detail: MonitoringDetail;

    constructor(context: InvipoContext, options?: EmovisWimWallOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
        this.createItems();
    }

    public onAttach(): void {
        // Define fullscreen listener
        this.resizeListener = (event: Event) => {
            // Resize all maps
            this.map.map.resize();
        }

        // Add listeners
        window.addEventListener("resize", this.resizeListener);
    }

    public onDetach(): void {
        // Remove listeners
        window.removeEventListener("resize", this.resizeListener);
    }

    public createMap(): void {
        // Create Zlin region map component
        this.map = new WallMap(this.context, {
            title: "WIM stations &mdash; Ashghal",
            itemClass: "WimStation",
            scale: 2.4,
            mapOptions: {
                style: "Dark",
                center: [51.443691, 25.265274],
                zoom: 10
            }
        });

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
    }

    public createItems(): void {
        // Create Zlin region map component
        this.items = new WallItems(this.context, {
            itemClass: "WimStation",
            //issues: true
        });

        // Open monitoring detail
        this.items.onSelect = async (item: InvipoItem) => {
            await this.openDetail(item);
        }

        // Register components that will be automatically attached
        this.registerComponent(this.items, "items");
    }

    private async openDetail(item: InvipoItem): Promise<void> {
        // Detail options
        let options: MonitoringDetailOptions = {
            style: "Dark",
            title: item.name,
            subtitle: `classes.${item.class}`,
            itemId: item.id,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = {
                ...this.detail.options,
                ...options
            };

            // Force reload
            await this.detail.load();

            // Not continue
            return;
        }

        // New detail
        this.detail = new MonitoringDetail(this.context, options);

        // Attach detail and redraw map because of viewport changed
        this.detail.attach();
    }

}
