import "./garbage-bin-overview.scss";
import * as template from "./garbage-bin-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { GarbageBinOverviewOptions, GarbageMetrics } from "./types";
import { METRICS } from "../../city/city-subdomain/types";
import { Helpers } from "../../../../hiyo/helpers";
import { Panel } from "../../common/panel/panel";

export class GarbageBinOverview extends Panel<GarbageBinOverviewOptions> {

    // Properties
    public metrics: GarbageMetrics[];

    constructor(context: InvipoContext, options?: GarbageBinOverviewOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load item history data
        let from = new Date(new Date(Date.now() - 48 * 60 * 60 * 1000).setMinutes(0, 0, 0));
        let to = new Date(new Date().setMinutes(0, 0, 0));
        let history = await this.context.invipo.getDataset("items-history", `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Clear metrics
        this.metrics = [];

        // Build all chart data from all envi blocks and properties into one history object
        for (let d of (Object.keys(METRICS.waste.garbage))) {

            // Calculate extrems
            let highest = Math.max(...history.data.map(x => x.item?.data?.garbage && x.item.data.garbage[d]));
            let lowest = Math.min(...history.data.map(x => x.item?.data?.garbage && x.item.data.garbage[d]));

            // Current metrics
            let metrics: GarbageMetrics = {
                kpis: {
                    label: `components.GarbageBinOverview.labels.${d}`,
                    size: "Third",
                    tight: true,
                    data: [
                        {
                            label: "components.GarbageBinOverview.current",
                            value: (this.item?.data?.garbage && this.item.data?.garbage[d]) ? `${Helpers.toNumber(this.item.data.garbage[d], 1)} ${METRICS.waste.garbage[d].unit}` : "common.unknown",
                            description: (this.item?.data?.garbage && this.item.data?.garbage[d]) ? Helpers.toDateTimeString(this.item.data.garbage[d].timestamp) : ""
                        },
                        {
                            label: "components.GarbageBinOverview.lowest",
                            value: lowest ? `${Helpers.toNumber(lowest, 1)} ${METRICS.waste.garbage[d].unit}` : "common.unknown",
                            description: lowest ? Helpers.toDateTimeString(history.data.find(x => (x.item?.data?.garbage && x.item.data.garbage[d]) == lowest)?.timestamp) : ""
                        },
                        {
                            label: "components.GarbageBinOverview.highest",
                            value: highest ? `${Helpers.toNumber(highest, 1)} ${METRICS.waste.garbage[d].unit}` : "common.unknown",
                            description: highest ? Helpers.toDateTimeString(history.data.find(x => (x.item?.data?.garbage && x.item.data.garbage[d]) == highest)?.timestamp) : ""
                        }
                    ]
                },
                chart: {
                    type: "Bar",
                    size: "Small",
                    length: 48,
                    series: []
                }
            }

            // Add hourly data to chart series
            let timestamp = new Date(from);
            do {
                // Find hour in data
                let data = history.data.find(x => x.timestamp == timestamp.toISOString());

                // Has data?
                if (data.item.data.garbage[d]) {
                    metrics.chart.series.push(
                        [
                            {
                                timestamp: timestamp.toISOString(),
                                valueX: Helpers.toShortDateTimeString(timestamp.toISOString()),
                                label: `${Helpers.toNumber(data.item.data.garbage[d], 1)} ${METRICS.waste.garbage[d].unit}`,
                                percent: Helpers.range(0, 100, METRICS.waste.garbage[d].range[0], METRICS.waste.garbage[d].range[1], data.item.data.garbage[d]),
                                color: METRICS.waste.garbage[d].colors[Math.round(Helpers.range(0,METRICS.waste.garbage[d].colors.length - 1, METRICS.waste.garbage[d].range[0], METRICS.waste.garbage[d].range[1], data.item.data.garbage[d]))],
                            }
                        ]
                    );
                }
                // No data
                else {
                    metrics.chart.series.push(
                        [
                            {
                                timestamp: timestamp.toISOString(),
                            }
                        ]
                    );
                }

                // Move to next hour
                timestamp.setTime(timestamp.getTime() + 3600000);
            }
            while (timestamp.getTime() < to.getTime())

            // Custom range
            metrics.chart.range = [
                {
                    label: Helpers.toShortDateTimeString(from)
                },
                {
                    label: Helpers.toShortDateTimeString(new Date(from.getTime() + (to.getTime() - from.getTime()) / 2))
                },
                {
                    label: Helpers.toShortDateTimeString(to)
                }
            ];

            // Push metrics to array
            this.metrics.push(metrics);
        }
    }

}
