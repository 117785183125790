import "./travel-time-feed-browser.scss";
import * as template from "./travel-time-feed-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterItem } from "muklit/components/filter/types";
import { RangeInput } from "muklit/components/range-input/range-input";
import { TravelTimeFeedBrowserOptions } from "./types";
import { TravelTimeFeedDetail } from "../travel-time-feed-detail/travel-time-feed-detail";
import { TravelTimeFeedDetailOptions } from "../travel-time-feed-detail/types";
import { Helpers } from "hiyo/helpers";

export class TravelTimeFeedBrowser extends MuklitComponent<InvipoContext, TravelTimeFeedBrowserOptions> {

    // Components
    public table: FilterTable;
    public detail: TravelTimeFeedDetail;

    constructor(context: InvipoContext, options?: TravelTimeFeedBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    public createTable(): void {
        // Create component

        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/travel-time-feeds`,
            http: this.context.invipo.http,
            filter: {
                title: "components.TravelTimeFeedBrowser.title",
                items: [
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "General",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "interval.from",
                        type: "DateTime",
                        property: "interval.from",
                        label: "tables.columns.timestamp",
                        width: 160,
                        selected: true,
                        sortable: true,
                        descendent: true
                    },
                    {
                        name: "congestion",
                        type: "Number",
                        property: "congestion",
                        formatter: (value: any, data: any) => {
                            let routes = <any[]>data.routes;

                            // Calculate congestion percentige as level 4 distance ration to total length of all routes
                            let total = routes.map(x => x.route.length).reduce((x, y) => x + y, 0);
                            let stand = routes.filter(x => x.level >= 3 && x.level <= 4).map(x => x.route.length).reduce((x, y) => x + y, 0);

                            return `<div class="cell cell-center">${Math.round(stand / total * 100)} %</div>`;
                        },
                        label: "tables.columns.congestion",
                        align: "Center",
                        width: 80,
                        ellipsis: true
                    },
                    {
                        name: "routes",
                        type: "Number",
                        property: (data: any) => {
                            return data.routes.length
                        },
                        label: "tables.columns.routes",
                        ellipsis: true
                    }
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    protected openDetail(data: any): void {
        // Detail options
        let options: TravelTimeFeedDetailOptions = {
            style: "Light",
            feed: data,
            title: "components.TravelTimeFeedDetail.title",
            subtitle: `${Helpers.toDateTimeString(data.interval.from)} &mdash; ${Helpers.toShortTimeString(data.interval.to)}`,
            printable: false,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Redraw completely
            this.detail.invalidate();

            // Not continue
            return;
        }

        // New detail
        this.detail = new TravelTimeFeedDetail(this.context, options);

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.feed.id);
            this.detail = null;
        }

        // Show detail
        this.detail.attach();
    }
}
