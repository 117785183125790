import "./travel-time-route-map.scss";
import * as template from "./travel-time-route-map.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { TravelTimeRouteMapOptions } from "./types";
import { TemplateForm } from "../../settings/template-form/template-form";
import { TravelTimeRouteDetailOptions } from "../travel-time-route-detail/types";
import { TravelTimeRouteDetail } from "../travel-time-route-detail/travel-time-route-detail";
import { BasicMap } from "../../../../muklit/components/basic-map/basic-map";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";
import { ItemLabelLayer } from "../../../layers/infrastructure/item-label-layer";
import { Feature } from "geojson";
import { TravelTimeMapLineLayer } from "../../../layers/management/travel-time-map-line-layer";

export class TravelTimeRouteMap extends MuklitComponent<InvipoContext, TravelTimeRouteMapOptions> {

    // Components
    public map: BasicMap;
    public detail: TravelTimeRouteDetail;

    constructor(context: InvipoContext, options?: TravelTimeRouteMapOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
    }

    private createMap(): void {
        // Get items
        let items = this.context.data.getItems({ class: "BluetoothDetector" });

        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.overview.center,
            zoom: this.context.options.overview.zoom,
            minZoom: 5,
            maxZoom: 20,
            layers: [
                new TravelTimeMapLineLayer(this.context),
                new ItemCircleLayer(this.context, items),
                new ItemLabelLayer(this.context, items)
            ]
        });

        // Open detail
        this.map.onFeatureClick = async (layer: string, feature: Feature, event?: MouseEvent): Promise<void> => {
            console.info(feature.properties);
            await this.openDetail(feature.properties);
        }

        // Register component
        this.registerComponent(this.map, "map");
    }

    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    private async openDetail(properties: any): Promise<void> {
        // Detail options
        let options: TravelTimeRouteDetailOptions = {
            style: "Light",
            title: properties.name,
            routeId: properties.id,
            closable: true,
            menu: [
                {
                    name: "Delete",
                    label: "labels.delete",
                    escalated: true
                }
            ]
        };

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = {
                ...this.detail.options,
                ...options
            };

            // Force reload
            await this.detail.load();

            // Not continue
            return;
        }

        // New detail
        this.detail = new TravelTimeRouteDetail(this.context, options);

        // Menu select
        this.detail.onMenuSelect = (item: MenuItem)=> {
            // Delete action?
            if (item.name == "Delete") {
                this.detail.delete();
            }
        }

        // Refresh table on detail update
        this.detail.onDetailUpdate = async () => {
            // TODO
        }

        // Unselect table row and null detail
        this.detail.onClose = () => {
            // TODO
            this.detail = null;
        }

        // Attach detail and redraw map because of viewport changed
        this.detail.attach(this.query("div.detail"));
    }

    public openNew(): void {
        // Details form
        let form = new TemplateForm(this.context, {
            style: "Light",
            title: "components.TemplateForm.title",
            template: null,
            overlay: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // TODO
        }

        // Show form
        form.attach();
    }

}
