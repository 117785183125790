import { ContentView } from "./content-view";
import { DrawerMenu } from "../components/common/drawer-menu/drawer-menu";
import { HiyoEvent } from "../../hiyo/event-broker";
import { DrawerItem } from "../components/common/drawer-menu/types";
import { Log } from "../../hiyo/log";

export abstract class MenuView extends ContentView {

    // Components
    public menu: DrawerMenu;

    public onCreate(): void {
        // Create components
        this.createMenu();

        // Handle select
        this.menu.onSelect = (item: DrawerItem) => {
            // OnItemSelect handler
            this.setContent(item.name);

            // Route without event
            this.context.application.route({
                viewId: this.id,
                componentName: item.name
            }, true);
        }

        // Find item from state or take just first
        let item = this.menu.find(this.context.application.state.componentName) || this.menu.options.groups[0]?.items[0];

        // No item?
        if (!item) {
            Log.w(`DrawerMenu: No item to select`);
            return;
        }

        // Make item selected
        item.selected = true;

        // Set item content to start
        this.setContent(item.name);

        // Register components that will be automatically attached/detached
        this.registerComponent(this.menu);

        // Route handling
        this.context.broker.subscribe(this, ["RouteChanged"]);
    }

    public onHandle(event: HiyoEvent) {
        // Routed to this view?
        if (event.payload?.viewId == this.id) {
            // Get item by name or default
            let item = this.menu.find(event.payload.componentName) || this.menu.options.groups[0].items[0];

            // Select in silence mode
            this.menu.select(item.name, true);

            // Set new content
            this.setContent(item.name);
        }
    }

    public abstract createMenu(): void;

}
