import { Http, HttpMethod } from "hiyo/http";
import { Log } from "hiyo/log";
import { AvailabilityItem, InvipoClientOptions, InvipoItem, User, Notification, InvipoArea, UserExport, Info, InvipoItemDocument, InvipoItemPassport, InvipoItemStream, InvipoItemSchedule, Ticket, InvipoEvent, License, Template } from "./types";
import { Helpers } from "hiyo/helpers";
import { DatasetResult } from "muklit/components/query-table/types";

export class InvipoClient {

    // Properties
    public options: InvipoClientOptions;
    public http: Http;

    public constructor(options: InvipoClientOptions) {
        this.options = options;

        // Http client with 30 seconds timeout
        this.http = new Http({
            timeout: 30000
        });
    }

    public enableBasicAuthorization(token: string) {
        this.options.accessToken = token;

        // Set authorization header to each api call
        this.http.options.authorization = `Basic ${token}`;

        Log.i(`InvipoClient: Basic authorization enabled`);
    }

    public enableUserUuid(uuid: string) {
        // Set uuid header to each service call
        this.http.options.uuid = uuid;

        Log.i(`InvipoClient: User UUID set to ${uuid}`);
    }

    public async getLicense(): Promise<License> {
        return await this.http.request(HttpMethod.GET, `${this.options.host}/api/invipo/license`);
    }

    public async getInfo(): Promise<Info> {
        return await this.http.request(HttpMethod.GET, `${this.options.host}/api/invipo/info`);
    }

    public async getLoggedUser(): Promise<User> {
        return await this.http.request(HttpMethod.GET, `${this.options.host}/api/invipo/auth`);
    }

    public async loginUser(username: string, password: string, code?: string): Promise<User> {
        // Send username and password to standard auth endpoint
        let user = await this.http.request(HttpMethod.POST, `${this.options.host}/api/invipo/auth`, {
            data: {
                username: username,
                password: password,
                code: code
            }
        });

        // Create unique client id
        user.uuid = Helpers.createUuid();

        return user;
    }

    public async loginSsoUser(provider: string, code: string): Promise<User> {
        // Send oath code to auth endpoint of selected provider
        let user = await this.http.request(HttpMethod.POST, `${this.options.host}/api/invipo/auth/${Helpers.toKebabCase(provider)}`, {
            data: {
                code: code
            }
        });

        // Create unique client id
        user.uuid = Helpers.createUuid();

        return user;
    }

    public async logoutUser(): Promise<User> {
        return await this.http.request(HttpMethod.DELETE, `${this.options.host}/api/invipo/auth`);
    }

    public async disableUser(id: string, disabled: boolean): Promise<User> {
        return await this.http.request(HttpMethod.POST, `${this.options.host}/api/invipo/users/${id}/disable`, {
            data: { disabled: disabled }
        });
    }

    public async getUsers(query?: string): Promise<User[]> {
        return await this.http.request(HttpMethod.GET, `${this.options.host}/api/invipo/users`);
    }

    public async getUser(id: string): Promise<User> {
        return await this.http.request(HttpMethod.GET, `${this.options.host}/api/invipo/users/${id}`);
    }

    public async getUserExports(id: string): Promise<UserExport[]> {
        return await this.http.request(HttpMethod.GET, `${this.options.host}/api/invipo/users/${id}/exports`);
    }

    public async updateUserProfile(id: string, data: any): Promise<User> {
        return await this.http.request(HttpMethod.PUT, `${this.options.host}/api/invipo/users/${id}/profile`, {
            data: data
        });
    }

    public async updateUserPassword(id: string, data: any): Promise<User> {
        return await this.http.request(HttpMethod.PUT, `${this.options.host}/api/invipo/users/${id}/password`, {
            data: data
        });
    }

    public async updateUserPermissions(id: string, data: any): Promise<User> {
        return await this.http.request(HttpMethod.PUT, `${this.options.host}/api/invipo/users/${id}/permissions`, {
            data: data
        });
    }

    public async updateUserAvatar(id: string, data: any): Promise<void> {
        return await this.http.request(HttpMethod.PUT, `${this.options.host}/api/invipo/users/${id}/avatar`, {
            data: { avatar: data }
        });
    }

    public async setUserPassword(id: string, data: any): Promise<User> {
        return await this.http.request(HttpMethod.POST, `${this.options.host}/api/invipo/users/${id}/password`, {
            data: data
        });
    }

    public async createUser(user: User): Promise<User> {
        return await this.http.request(HttpMethod.POST, `${this.options.host}/api/invipo/users`, {
            data: user
        });
    }

    public async deleteUser(id: string): Promise<User> {
        return await this.http.request(HttpMethod.DELETE, `${this.options.host}/api/invipo/users/${id}`);
    }

    public async getTemplate(key: string): Promise<Template> {
        return await this.http.request(HttpMethod.GET, `${this.options.host}/api/invipo/templates/${key}`);
    }

    public async updateTemplate(key: string, data: any): Promise<Template> {
        return await this.http.request(HttpMethod.PUT, `${this.options.host}/api/invipo/templates/${key}`, {
            data: data
        });
    }

    public async createTemplate(template: Template): Promise<Template> {
        return await this.http.request(HttpMethod.POST, `${this.options.host}/api/invipo/templates`, {
            data: template
        });
    }

    public async deleteTemplate(key: string): Promise<Template> {
        return await this.http.request(HttpMethod.DELETE, `${this.options.host}/api/invipo/templates/${key}`);
    }

    public async createEvent(event: InvipoEvent): Promise<InvipoEvent> {
        return await this.http.request(HttpMethod.POST, `${this.options.host}/api/invipo/events`, {
            data: event
        });
    }

    public async getNotification(id: string): Promise<Notification> {
        return await this.http.request(HttpMethod.GET, `${this.options.host}/api/invipo/notifications/${id}`);
    }

    public async createNotification(notification: Notification): Promise<Notification> {
        return await this.http.request(HttpMethod.POST, `${this.options.host}/api/invipo/notifications`, {
            data: notification
        });
    }

    public async updateNotification(id: string, data: Notification): Promise<Notification> {
        return await this.http.request(HttpMethod.PUT, `${this.options.host}/api/invipo/notifications/${id}`, {
            data: data
        });
    }

    public async deleteNotification(id: string): Promise<Notification> {
        return await this.http.request(HttpMethod.DELETE, `${this.options.host}/api/invipo/notifications/${id}`);
    }

    public async updateInboxRead(id: string): Promise<User> {
        return await this.http.request(HttpMethod.PUT, `${this.options.host}/api/invipo/inbox/${id}/read`);
    }

    public async getConfig(key?: string): Promise<any> {
        let url = `${this.options.host}/api/invipo/config`;

        // Custom query
        if (key) {
            url += `?key=${key}`;
        }

        return await this.http.request(HttpMethod.GET, url);
    }

    public async getItems(query?: string): Promise<InvipoItem[]> {
        let url = `${this.options.host}/api/invipo/items`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request(HttpMethod.GET, url);
    }

    public async getItem(id: string, query?: string): Promise<InvipoItem> {
        let url = `${this.options.host}/api/invipo/items/${id}`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request(HttpMethod.GET, url);
    }

    public async updateItem(id: string, data: any): Promise<InvipoItem> {
        return await this.http.request(HttpMethod.PUT, `${this.options.host}/api/invipo/items/${id}`, {
            data: data
        });
    }

    public async updateItemGeometry(id: string, data: any): Promise<void> {
        return await this.http.request(HttpMethod.PUT, `${this.options.host}/api/invipo/items/${id}/geometry`, {
            data: data
        });
    }

    public async updateItemSchema(id: string, data: any): Promise<void> {
        return await this.http.request(HttpMethod.PUT, `${this.options.host}/api/invipo/items/${id}/schema`, {
            data: data
        });
    }

    public async updateItemMeta(id: string, data: any): Promise<User> {
        return await this.http.request(HttpMethod.PUT, `${this.options.host}/api/invipo/items/${id}/meta`, {
            data: data
        });
    }

    public async deleteItem(id: string): Promise<InvipoItem> {
        return await this.http.request(HttpMethod.DELETE, `${this.options.host}/api/invipo/items/${id}`);
    }

    public async getItemsHistory(timestamp: string, query?: string): Promise<InvipoItem[]> {
        let url = `${this.options.host}/api/invipo/items/history?timestamp=${timestamp}`;

        // Custom query
        if (query) {
            url += `&${query}`;
        }

        return await this.http.request(HttpMethod.GET, url);
    }

    public async getItemsAvailability(query?: string): Promise<AvailabilityItem[]> {
        let url = `${this.options.host}/api/invipo/items/availability`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request(HttpMethod.GET, url);
    }

    public async getItemDocuments(id: string): Promise<InvipoItemDocument[]> {
        let url = `${this.options.host}/api/invipo/items/${id}/documents`;

        return await this.http.request(HttpMethod.GET, url);
    }

    public async createItemDocument(id: string, name: string, file: File): Promise<InvipoItemDocument[]> {
        let url = `${this.options.host}/api/invipo/items/${id}/documents`;

        const formData = new FormData();
        formData.append('file', file, file.name)
        formData.append('name', name)

        // Http class does not support request with FormData
        const response = await fetch(url, {
            method: 'POST',
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Authorization": this.http.options.authorization,
                "User-Uuid": this.http.options.uuid
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: formData
        });

        return await response.json();
    }

    public async deleteItemDocument(itemId: string, documentId: string): Promise<InvipoItemDocument> {
        let url = `${this.options.host}/api/invipo/items/${itemId}/documents/${documentId}`;

        return await this.http.request(HttpMethod.DELETE, url);

    }

    public async getItemPassports(id: string): Promise<InvipoItemPassport[]> {
        let url = `${this.options.host}/api/invipo/items/${id}/passports`;

        return await this.http.request(HttpMethod.GET, url);

    }

    public async createItemPassport(id: string, property: string, value: string): Promise<InvipoItemPassport> {
        let url = `${this.options.host}/api/invipo/items/${id}/passports`;

        return await this.http.request(HttpMethod.POST, url, { data: { property, value }, cache: false });

    }

    public async deleteItemPassport(itemId: string, passportId: string): Promise<InvipoItemPassport> {
        let url = `${this.options.host}/api/invipo/items/${itemId}/passports/${passportId}`;

        return await this.http.request(HttpMethod.DELETE, url, { cache: false });
    }

    public async getItemStream(id: string): Promise<InvipoItemStream[]> {
        let url = `${this.options.host}/api/invipo/items/${id}/stream`;

        return await this.http.request(HttpMethod.GET, url, { cache: false });
    }

    public async crateItemStream(id: string, comment: string): Promise<InvipoItemStream> {
        let url = `${this.options.host}/api/invipo/items/${id}/stream`;

        return await this.http.request(HttpMethod.POST, url, { data: { comment }, cache: false });
    }

    public async getItemScheduler(id: string): Promise<InvipoItemSchedule[]> {
        let url = `${this.options.host}/api/invipo/items/${id}/scheduler`;

        return await this.http.request(HttpMethod.GET, url, { cache: false });
    }

    public async createItemSchedule(id: string, timestamp: string, task: string): Promise<InvipoItemSchedule> {
        let url = `${this.options.host}/api/invipo/items/${id}/scheduler`;

        return await this.http.request(HttpMethod.POST, url, { data: { timestamp, task }, cache: false });
    }

    public async deleteItemSchedule(itemId: string, scheduleId: string): Promise<InvipoItemSchedule> {
        let url = `${this.options.host}/api/invipo/items/${itemId}/scheduler/${scheduleId}`;

        return await this.http.request(HttpMethod.DELETE, url);

    }

    public async getAreas(query?: string): Promise<InvipoArea[]> {
        let url = `${this.options.host}/api/invipo/areas`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request(HttpMethod.GET, url);
    }

    public async getArea(id: string, query?: string): Promise<InvipoArea> {
        let url = `${this.options.host}/api/invipo/areas/${id}`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request(HttpMethod.GET, url);
    }

    public async getAreaItems(id: string, query?: string): Promise<InvipoItem[]> {
        let url = `${this.options.host}/api/invipo/areas/${id}/items`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request(HttpMethod.GET, url);
    }

    public async updateArea(id: string, data: any): Promise<InvipoItem> {
        return await this.http.request(HttpMethod.PUT, `${this.options.host}/api/invipo/areas/${id}`, {
            data: data
        });
    }

    public async createArea(area: InvipoArea): Promise<any> {
        return await this.http.request(HttpMethod.POST, `${this.options.host}/api/invipo/areas`, {
            data: area
        });
    }

    public async deleteArea(id: string): Promise<InvipoArea> {
        return await this.http.request(HttpMethod.DELETE, `${this.options.host}/api/invipo/areas/${id}`);
    }

    public async registerAction(command: string, data: any): Promise<any> {
        let action = {
            command: command,
            data: data
        };

        return await this.http.request(HttpMethod.POST, `${this.options.host}/api/invipo/actions`, { data: action });
    }

    public async postItemCommand(itemId: string, command: string, extras?: any): Promise<any> {
        await this.http.request(HttpMethod.POST, `${this.options.host}/api/invipo/items/${itemId}/command`, {
            data: {
                type: command,
                extras: extras
            }
        });

        // UI feeling
        await Helpers.sleep(2000);
    }

    public async getDataset(dataset: string, query?: string): Promise<DatasetResult> {
        let url = `${this.options.host}/api/data/datasets/${dataset}`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request(HttpMethod.GET, url);
    }

    public async getQuery<T = any[]>(name: string, query?: string): Promise<T> {
        let url = `${this.options.host}/api/data/queries/${name}?timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

        // Custom query
        if (query) {
            url += `&${query}`;
        }

        return await this.http.request(HttpMethod.GET, url);
    }

    public async getSamples<T = any[]>(sample: string, size: number, query?: string): Promise<T> {
        let url = `${this.options.host}/api/data/samples/${sample}?size=${size}`;

        // Custom query
        if (query) {
            url += `&${query}`;
        }

        return await this.http.request(HttpMethod.GET, url);
    }

    public async getAutocomplete(name: string, query?: string): Promise<any> {
        let url = `${this.options.host}/api/invipo/autocomplete/${name}`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request(HttpMethod.GET, url);
    }

    public async getDistinct(): Promise<any> {
        let url = `${this.options.host}/api/data/distinct`;

        return await this.http.request(HttpMethod.GET, url);
    }

    public async getExport(name: string, query?: string): Promise<any> {
        let url = `${this.options.host}/api/data/exports/${name}`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request(HttpMethod.GET, url);
    }

    public async getManagement(resource: string, query?: string): Promise<any> {
        let url = `${this.options.host}/api/management/${resource}`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request(HttpMethod.GET, url);
    }

    public async postManagement(resource: string, data: any, query?: string): Promise<any> {
        let url = `${this.options.host}/api/management/${resource}`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request(HttpMethod.POST, url, { data: data });
    }

    public async putManagement(resource: string, data: any, query?: string): Promise<any> {
        let url = `${this.options.host}/api/management/${resource}`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request(HttpMethod.PUT, url, { data: data });
    }

    public async deleteManagement(resource: string, query?: string): Promise<any> {
        let url = `${this.options.host}/api/management/${resource}`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request(HttpMethod.DELETE, url);
    }

    public async postExternal(resource: string, data: any, query?: string): Promise<any> {
        let url = `${this.options.host}/api/externals/${resource}`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request(HttpMethod.POST, url, { data: data });
    }


    public async getCameras(): Promise<any> {
        return await this.http.request(HttpMethod.GET, `${this.options.host}/api/video/cameras`);
    }

    public async getCameraSnapshot(itemId: string, reload?: boolean): Promise<any> {
        let url = `${this.options.host}/api/video/cameras/${itemId}/snapshot`;

        // Custom query
        if (reload) {
            url += "?reload=true";
        }

        return await this.http.request(HttpMethod.GET, url);
    }

    public async getCameraStream(itemId: string): Promise<any> {
        return await this.http.request(HttpMethod.GET, `${this.options.host}/api/video/cameras/${itemId}/stream`);
    }

    public async doCameraControl(itemId: string, control: any): Promise<any> {
        return await this.http.request(HttpMethod.PUT, `${this.options.host}/api/video/cameras/${itemId}/control`, { data: control });
    }

    public async getContainer(containerId: string): Promise<any> {
        return await this.http.request(HttpMethod.GET, `${this.options.host}/api/docker/containers/${containerId}`);
    }

    public async getContainerLogs(containerId: string, tail?: number): Promise<string[]> {
        return await this.http.request(HttpMethod.GET, `${this.options.host}/api/docker/containers/${containerId}/logs?tail=${tail || 100}`);
    }

    public async getContainerRaw(containerId: string): Promise<any> {
        return await this.http.request(HttpMethod.GET, `${this.options.host}/api/docker/containers/${containerId}/raw`);
    }

    public async postContainerCommand(containerId: string, command: "Start" | "Stop" | "Restart"): Promise<any> {
        return await this.http.request(HttpMethod.POST, `${this.options.host}/api/docker/containers/${containerId}/command`, { data: { command: command} });
    }

    public async postContainerDebug(containerId: string): Promise<any> {
        return await this.http.request(HttpMethod.POST, `${this.options.host}/api/docker/containers/${containerId}/debug`);
    }

    public async getTickets(query?: string): Promise<Ticket[]> {
        let url = `${this.options.host}/api/invipo/tickets`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request(HttpMethod.GET, url);
    }

    public async getTicket(id: string): Promise<Ticket> {
        return await this.http.request(HttpMethod.GET, `${this.options.host}/api/invipo/tickets/${id}`);
    }

    public async createTicket(ticket: Ticket): Promise<Notification> {
        return await this.http.request(HttpMethod.POST, `${this.options.host}/api/invipo/tickets`, {
            data: ticket
        });
    }

    public async deleteTicket(id: string): Promise<Ticket> {
        return await this.http.request(HttpMethod.DELETE, `${this.options.host}/api/invipo/tickets/${id}`);
    }

    public async updateTicketCompleted(id: string, data: any): Promise<Ticket> {
        return await this.http.request(HttpMethod.PUT, `${this.options.host}/api/invipo/tickets/${id}/completed`, {
            data: data
        });
    }

    public async updateTicketColumn(id: string, data: any): Promise<Ticket> {
        return await this.http.request(HttpMethod.PUT, `${this.options.host}/api/invipo/tickets/${id}/column`, {
            data: data
        });
    }

    public async updateTicketAssignee(id: string, data: any): Promise<Ticket> {
        return await this.http.request(HttpMethod.PUT, `${this.options.host}/api/invipo/tickets/${id}/assignee`, {
            data: data
        });
    }

    public async createTicketComment(id: string, data: any): Promise<Ticket> {
        return await this.http.request(HttpMethod.POST, `${this.options.host}/api/invipo/tickets/${id}/comments`, {
            data: data
        });
    }

    public async createCatalogSignup(registration: any): Promise<any> {
        let url = `${this.options.host}/api/catalog/signup`;

        return await this.http.request(HttpMethod.POST, url, {
            data: registration
        });
    }

    public async getCatalogDatasets(query?: string): Promise<DatasetResult> {
        let url = `${this.options.host}/api/catalog/datasets`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request(HttpMethod.GET, url);
    }

    public async getCatalogDataset(id: string): Promise<any> {
        return await this.http.request(HttpMethod.GET, `${this.options.host}/api/catalog/datasets/${id}`);
    }

    public async getSequence(name?: string): Promise<any> {
        let url = `${this.options.host}/api/invipo/sequences/${name}`;

        return await this.http.request(HttpMethod.GET, url);
    }

}
