import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterAction, FilterItem } from "muklit/components/filter/types";
import { RangeInput } from "muklit/components/range-input/range-input";
import { HanoiViolationProcessBrowserOptions } from "./types";
import { Templates } from "../../../../hiyo/templates";
import { Select } from "../../../../muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { COLUMN_VIOLATION_IN_PROCESS, COLUMN_VIOLATION_NOTICE, HanoiViolationBrowser } from "../hanoi-violation-browser/hanoi-violation-browser";
import { ItemSelect } from "../../common/item-select/item-select";
import { TextInput } from "../../../../muklit/components/text-input/text-input";
import { HanoiViolationReportForm } from "../hanoi-violation-report-form/hanoi-violation-report-form";

export class HanoiViolationProcessBrowser extends HanoiViolationBrowser<HanoiViolationProcessBrowserOptions> {

    public createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/violations-data?workflow.column=${COLUMN_VIOLATION_IN_PROCESS}`,
            http: this.context.invipo.http,
            filter: {
                title: `components.${this.name}.title`,
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ],
                actions: [
                    {
                        name: "ViolationNotice",
                        label: "components.HanoiViolationProcessBrowser.actions.violationNotice",
                        multiselect: true,
                        disabled: true
                    },
                    {
                        name: "CreateReport",
                        label: "components.HanoiViolationProcessBrowser.actions.createReport",
                        multiselect: true,
                        disabled: true
                    },
                    {
                        name: "InsufficientGrounds",
                        label: "components.HanoiViolationBrowser.actions.insufficientGroundsForPenalty",
                        multiselect: true,
                        disabled: true
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "type",
                                label: "forms.fields.type",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("ViolationsData", "type"), "enums.ViolationType."),
                                multiselect: true
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                distinct: "ViolationsData",
                                items: [],
                                multiselect: true
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "data.incident.extras.plate.number",
                                label: "forms.fields.plateNumber",
                                placeholderText: "forms.placeholders.plateNumber"
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "data.incident.extras.class",
                                label: "forms.fields.category",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toCategoryItems(this.context.config.categories),
                                multiselect: true
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "data.incident.extras.color",
                                label: "forms.fields.color",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.VehicleColor")),
                                multiselect: true
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "data.incident.extras.measurement.speed.min",
                                label: "forms.fields.speedMin",
                                placeholderText: "forms.placeholders.speedMin"
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "data.incident.extras.measurement.speed.max",
                                label: "forms.fields.speedMax",
                                placeholderText: "forms.placeholders.speedMax"
                            })
                        ]
                    }
                ]
            },
            table: {
                type: "MultiSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id",
                    decorator: (data: any): string => {
                        let result = null;

                        // Archived?
                        if (data.workflow?.archived) {
                            result = "disabled"
                        }

                        // Completed?
                        if (data.workflow?.completed) {
                            result = "disabled"
                        }

                        return result;
                    }
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "type",
                        type: "String",
                        property: (data: any): any => {
                            return this.context.locale.getMessage(`enums.ViolationType.${data.type}`);
                        },
                        label: "tables.columns.type",
                        width: 160,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "data.incident.extras.plate.number",
                        type: "String",
                        property: "data.incident.extras.plate.number",
                        label: "tables.columns.plate",
                        formatter: (value: any, data: any): string => {
                            if (!value) {
                                return;
                            }
                            return `<div class="cell">${Templates.renderPartial("plate-number", data.data.incident.extras.plate)}</div>`;
                        },
                        width: 150,
                        sortable: true
                    },
                    {
                        name: "data.incident.extras.class",
                        type: "Number",
                        property: "data.incident.extras.class",
                        formatter: (value: any, data: any): string => {
                            if (value == null) {
                                return null;
                            }
                            return `<div class="cell cell-ellipsis">${this.context.config.categories.find(x => x.id == value)?.name || value}</div>`;
                        },
                        label: "tables.columns.category",
                        width: 140,
                        sortable: true
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        minWidth: 260,
                        ellipsis: true,
                        sortable: true
                    }
                ]
            }
        });

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Handle action  selection
        this.table.onActionSelect = async (item: FilterAction) => {
            // Violation notice?
            if (item.name == "ViolationNotice") {
                // Open violation dialog
                await this.violationNotice();
            }
            // Insufficient grounds for penalty?
            if (item.name == "InsufficientGrounds") {
                // Open violation dialog
                this.insufficientGrounds();
            }
            // Create report?
            if (item.name == "CreateReport") {
                // Open violation dialog
                this.openReport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    public async violationNotice(): Promise<any> {
        // Show loader
        this.showLoader();

        // Itterate all selected rows
        for (let id in this.table.table.selectedRows) {
            // Update column
            await this.context.invipo.putManagement(`violations/${id}/workflow/column`, {
                column: COLUMN_VIOLATION_NOTICE
            });
        }

        // Hide loader and redraw
        this.hideLoader();

        // Close detal
        this.detail?.close();

        // Unselect all checkboxes
        this.table.unselectCheckboxes();

        // Relaod
        await this.table.load();
    }
}
