import { DrawerMenu } from "../components/common/drawer-menu/drawer-menu";
import { MenuView } from "./menu-view";

export class Monitoring extends MenuView {

    public createMenu(): void {
        // Create component
        this.menu = new DrawerMenu(this.context, {
            style: "Dark",
            groups: [
                {
                    name: "General",
                    items: [
                        {
                            name: "MonitoringMap",
                            label: "components.MonitoringMap.title"
                        },
                        {
                            name: "ItemBrowser",
                            label: "components.ItemBrowser.title"
                        },
                        {
                            name: "ConnectorBrowser",
                            label: "components.ConnectorBrowser.title"
                        },
                        {
                            name: "EventBrowser",
                            label: "components.EventBrowser.title"
                        },
                        {
                            name: "RawDataBrowser",
                            label: "components.RawDataBrowser.title"
                        },
                        {
                            name: "RegisteredDataBrowser",
                            label: "components.RegisteredDataBrowser.title"
                        },
                        {
                            name: "LogBrowser",
                            label: "components.LogBrowser.title"
                        },
                        {
                            name: "DockerBrowser",
                            label: "components.DockerBrowser.title",
                            disabled: !this.context.user.boss && !this.context.user.permissions?.includes("Administrator")
                        }
                    ]
                }
            ]
        });
    }

}
