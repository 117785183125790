import "./parking-occupancy-form.scss";
import * as template from "./parking-occupancy-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { ParkingOccupancyFormOptions } from "./types";
import { TextInput } from "muklit/components/text-input/text-input";

export class ParkingOccupancyForm extends Detail<InvipoContext, ParkingOccupancyFormOptions> {

    // Components
    public form: Form;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: ParkingOccupancyFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
    }

    private createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            format: "Number",
                            name: "free",
                            label: "forms.fields.free",
                            value: this.options.item?.data?.occupancy?.overall?.free,
                            width: 420,
                            autofocus: true,
                            required: true,
                            bright: true
                        })
                    ]
                }
            ]
        });
    }

    public async submit(): Promise<void> {
        // Basic form validation?
        if (!this.form.validate()) {
            return;
        }

        // Get form data
        let data = this.form.getData(true);

        // Show loader
        this.showLoader();

        // Send command request to cahnge parking occupancy
        try {
            await this.context.invipo.postItemCommand(this.options.item.id, "SetParkingOccupancy", {
                overall: {
                    free: data.free
                }
            });
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnSubmit handler
        this.onSubmit();
    }

}
