import "./cis-parking-wall.scss";
import * as template from "./cis-parking-wall.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { WallMap } from "../wall-map/wall-map";
import { CisParkingWallOptions } from "./types";
import { MuklitComponent } from "../../../../muklit/components/muklit-component/muklit-component";
import { WallItems } from "../wall-items/wall-items";
import { InvipoItem } from "../../../clients/invipo-client/types";

export class CisParkingWall extends MuklitComponent<InvipoContext, CisParkingWallOptions> {

    // Properties
    private resizeListener: (event: Event) => void;

    // Components
    public map: WallMap;
    public items: WallItems;

    constructor(context: InvipoContext, options?: CisParkingWallOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
        this.createItems();
    }

    public onAttach(): void {
        // Define fullscreen listener
        this.resizeListener = (event: Event) => {
            // Resize all maps
            this.map.map.resize();
        }

        // Add listeners
        window.addEventListener("resize", this.resizeListener);
    }

    public onDetach(): void {
        // Remove listeners
        window.removeEventListener("resize", this.resizeListener);
    }

    public createMap(): void {
        // Create Zlin region map component
        this.map = new WallMap(this.context, {
            title: "Parking SLA &mdash; Czechia",
            itemClass: "ParkingLot",
            itemTag: "sla",
            scale: 2.4,
            mapOptions: {
                style: "Czechia",
                center: [15.475952, 49.503385],
                zoom: 8.25
            }
        });

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
    }

    public createItems(): void {
        // Create Zlin region map component
        this.items = new WallItems(this.context, {
            itemClass: "ParkingLot",
            itemTag: "sla"
        });

        // Open management url
        this.items.onSelect = (item: InvipoItem) => {
            window.open(item.managementUrl, "_blank");
        }

        // Register components that will be automatically attached
        this.registerComponent(this.items, "items");
    }
}
