import "./catalog-user-detail.scss";
import * as template from "./catalog-user-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Dialog } from "muklit/components/dialog/dialog";
import { CatalogUserDetailOptions } from "./types";
import { Toggle } from "../../../../muklit/components/toggle/toggle";

export class CatalogUserDetail extends Detail<InvipoContext, CatalogUserDetailOptions> {

    // Properties
    public user: any;

    // Components
    public disabled: Toggle;

    // Event handling methods
    public onDetailUpdate(): void {}

    constructor(context: InvipoContext, options: CatalogUserDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createDisabled();
    }

    private createDisabled(): void {
        // Crate component
        this.disabled = new Toggle(this.context, {
            style: "Light",
            size: "Small",
            name: "disabled",
            label: "components.CatalogUserDetail.disabled"
        })

        // Disable user
        this.disabled.onToggle = async (disabled: boolean) => {
            await this.disable(disabled);
        }

        // Register component
        this.registerComponent(this.disabled, "disabled");
    }

    public async disable(disabled: boolean): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        await this.context.invipo.postManagement(`catalog/users/${this.options.userId}/disable`, {
            disabled: disabled
        });

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // OnDetailUpdate handler
        this.onDetailUpdate();
    }

    public delete(): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.CatalogUserDetail.userDelete",
            text: this.context.locale.getMessage("components.CatalogUserDetail.reallyDelete", this.template.name),
            labelCancel: "labels.cancel",
            labelConfirm: "labels.delete",
            escalated: true
        })

        // OnTemplateLogout handler
        dialog.onConfirm = async () => {
            // Delete call
            await this.context.invipo.deleteManagement(`catalog/users/${this.options.userId}`);

            // Close dialog
            dialog.close();

            // Close detail
            this.close();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show dialog
        dialog.attach();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        this.user = await this.context.invipo.getManagement(`catalog/users/${this.options.userId}`);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Redraw with all components
        this.invalidate(true);

        // Manage child components
        this.disabled.setValue(this.user.disabled);
    }

}
