import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterAction, FilterItem } from "muklit/components/filter/types";
import { RangeInput } from "muklit/components/range-input/range-input";
import { Templates } from "../../../../hiyo/templates";
import { Select } from "../../../../muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { COLUMN_REPORT_MANAGEMENT, COLUMN_VIOLATION_IN_PROCESS, HanoiViolationBrowser } from "../hanoi-violation-browser/hanoi-violation-browser";
import { HanoiViolationReportBrowserOptions } from "./types";
import { ItemSelect } from "../../common/item-select/item-select";
import { TextInput } from "../../../../muklit/components/text-input/text-input";
import { Dialog } from "../../../../muklit/components/dialog/dialog";
import { Log } from "../../../../hiyo/log";
import { HanoiViolationDecisionForm } from "../hanoi-violation-decision-form/hanoi-violation-decision-form";

export class HanoiViolationReportBrowser extends HanoiViolationBrowser<HanoiViolationReportBrowserOptions> {

    public createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/violations-data?workflow.column=${COLUMN_REPORT_MANAGEMENT}`,
            http: this.context.invipo.http,
            filter: {
                title: `components.${this.name}.title`,
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ],
                actions: [
                    {
                        name: "CreateDecision",
                        label: "components.HanoiViolationReportBrowser.actions.createDecision",
                        multiselect: true,
                        disabled: true
                    },
                    {
                        name: "DeleteReport",
                        label: "components.HanoiViolationReportBrowser.actions.deleteReport",
                        escalated: true,
                        multiselect: true,
                        disabled: true
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "type",
                                label: "forms.fields.type",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("ViolationsData", "type"), "enums.ViolationType."),
                                multiselect: true
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                distinct: "ViolationsData",
                                items: [],
                                multiselect: true
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "data.incident.extras.plate.number",
                                label: "forms.fields.plateNumber",
                                placeholderText: "forms.placeholders.plateNumber"
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "data.incident.extras.class",
                                label: "forms.fields.category",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toCategoryItems(this.context.config.categories),
                                multiselect: true
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "data.incident.extras.color",
                                label: "forms.fields.color",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.VehicleColor")),
                                multiselect: true
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "data.incident.extras.measurement.speed.min",
                                label: "forms.fields.speedMin",
                                placeholderText: "forms.placeholders.speedMin"
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "data.incident.extras.measurement.speed.max",
                                label: "forms.fields.speedMax",
                                placeholderText: "forms.placeholders.speedMax"
                            }),
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "extras.report.timestamp",
                                type: "Range",
                                time: true,
                                label: "forms.fields.reportTime",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "extras.report.reportType",
                                label: "forms.fields.reportType",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.ViolationReportType")),
                                multiselect: true
                            }),

                        ]
                    }
                ]
            },
            table: {
                type: "MultiSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id",
                    decorator: (data: any): string => {
                        let result = null;

                        // Archived?
                        if (data.workflow?.archived) {
                            result = "disabled"
                        }

                        // Completed?
                        if (data.workflow?.completed) {
                            result = "disabled"
                        }

                        return result;
                    }
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "type",
                        type: "String",
                        property: (data: any): any => {
                            return this.context.locale.getMessage(`enums.ViolationType.${data.type}`);
                        },
                        label: "tables.columns.type",
                        width: 160,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "data.incident.extras.plate.number",
                        type: "String",
                        property: "data.incident.extras.plate.number",
                        label: "tables.columns.plate",
                        formatter: (value: any, data: any): string => {
                            if (!value) {
                                return;
                            }
                            return `<div class="cell">${Templates.renderPartial("plate-number", data.data.incident.extras.plate)}</div>`;
                        },
                        width: 150,
                        sortable: true
                    },
                    {
                        name: "extras.report.number",
                        type: "String",
                        property: "extras.report.reportNumber",
                        label: "tables.columns.number",
                        width: 160,
                        ellipsis: true,
                        sortable: true
                    },
                    {
                        name: "extras.report.timestamp",
                        type: "DateTime",
                        property: "extras.report.timestamp",
                        label: "tables.columns.reportTime",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "extras.report.type",
                        type: "String",
                        property: "extras.report.type",
                        label: "tables.columns.reportType",
                        formatter: (value: any, data: any): string => {
                            return `<div class="cell">${this.context.locale.getMessage("enums.ViolationReportType.OnSpot")}</div>`;
                        },
                        width: 160,
                        ellipsis: true,
                        sortable: true
                    },
                    {
                        name: "extras.report.status",
                        type: "String",
                        property: "extras.report.status",
                        label: "tables.columns.status",
                        formatter: (value: any, data: any): string => {
                            return `<div class="cell">${this.context.locale.getMessage(data.extras?.legal ? "components.HanoiViolationReportBrowser.legalDecisionIssued" : "components.HanoiViolationReportBrowser.legalDecisionNotIssued")}</div>`;
                        },
                        minWidth: 260,
                        ellipsis: true,
                        sortable: true
                    }
                ]
            }
        });

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Handle action  selection
        this.table.onActionSelect = async (item: FilterAction) => {
            // Confirm violation?
            if (item.name == "CreateDecision") {
                // Open violation dialog
                this.openDecision();
            }
            // Delete?
            if (item.name == "DeleteReport") {
                // Open violation dialog
                this.deleteReport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    public deleteReport(): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.HanoiViolationReportBrowser.deleteReport",
            text: "components.HanoiViolationReportBrowser.reallyDeleteReport",
            labelCancel: "labels.cancel",
            labelConfirm: "labels.delete",
            escalated: true
        })

        // OnUserLogout handler
        dialog.onConfirm = async () => {
            // Close dialog
            dialog.close();

            // Show loader
            this.showLoader();

            // Itterate all selected rows
            for (let id in this.table.table.selectedRows) {
                // Get violation data
                let violation = this.table.table.selectedRows[id].data;

                // Could not be deleted when legal decission is made
                if (violation.extras?.legal) {
                    Log.w(`Violation ${violation.id}: Could not delete report, legal decision exists`);
                    continue;
                }

                // Delete report
                delete violation.extras?.report;

                // Remove report
                await this.context.invipo.putManagement(`violations/${id}/extras`, {
                    extras: violation.extras
                });

                // Update column
                await this.context.invipo.putManagement(`violations/${id}/workflow/column`, {
                    column: COLUMN_VIOLATION_IN_PROCESS
                });
            }

            // Hide loader and redraw
            this.hideLoader();

            // Close detail
            this.detail?.close();

            // Unselect all checkboxes
            this.table.unselectCheckboxes();

            // Relaod
            await this.table.load();
        }

        // Show dialog
        dialog.attach();
    }

    public openDecision(): void {
        // Get first selected violaiton
        let violation = Object.values(this.table.table.selectedRows)[0].data;

        // Dialog to confirm
        let form = new HanoiViolationDecisionForm(this.context, {
            style: "Light",
            title: null,
            overlay: true,
            violation: violation
        })

        // OnSubmit handler
        form.onSubmit = async () => {
            // Unselect all checkboxes
            this.table.unselectCheckboxes();

            // Close detail
            this.detail?.close();

            // Delete call
            await this.table.load();
        }

        // Show dialog
        form.attach();
    }
}
