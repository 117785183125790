import "./travel-time-route-report.scss";
import * as template from "./travel-time-route-report.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TravelTimeRouteReportOptions } from "./types";
import { Form } from "muklit/components/form/form";
import { PanelKpis, PanelTable } from "../../common/panel/types";
import { Select } from "muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { Panel } from "../../common/panel/panel";
import { RangeInput } from "muklit/components/range-input/range-input";
import { WazeRouteLineLayer } from "../../../layers/traffic/waze-route-line-layer";
import { Helpers } from "../../../../hiyo/helpers";
import { TravelTimeFeedRouteLineLayer } from "../../../layers/traffic/travel-time-feed-route-line-layer";

export class TravelTimeRouteReport extends Panel<TravelTimeRouteReportOptions> {

    // Properties
    public kpis: PanelKpis;
    public table: PanelTable;
    public layer: TravelTimeFeedRouteLineLayer;
    public routes: any[];
    public route: any;

    public constructor(context: InvipoContext, options?: TravelTimeRouteReportOptions) {
        super(context, template, options);
    }

    protected createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new RangeInput(this.context, {
                            style: "Light",
                            name: "interval",
                            type: "Day",
                            time: true,
                            limit: 24 * 60,
                            label: "forms.fields.interval",
                            value: {
                                from: new Date(new Date().setHours(-24, 0, 0, 0)).toISOString(),
                                to: new Date(new Date().setHours(0, 0, 0, 0) - 1).toISOString(),
                                range: "Yesterday"
                            },
                            placeholderText: "forms.placeholders.anytime",
                            width: 320,
                            bright: true,
                            required: true
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "routeName",
                            label: "forms.fields.route",
                            placeholderText: "forms.placeholders.all",
                            items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("TravelTimeFeedsRoutes", "route.name")),
                            width: 320,
                            bright: true
                        })
                    ]
                }
            ]
        });

        // Register component
        this.registerComponent(this.form, "form");
    }

    protected createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.overview?.center || this.context.options.home.center,
            zoom: this.context.options.overview?.zoom || this.context.options.home.zoom,
            minZoom: 2,
            maxZoom: 20
        });

        // Create layer that will be attached later
        this.layer = new TravelTimeFeedRouteLineLayer(this.context);

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
    }

    public onAttach() {
        if (this.routes && this.map.isAttached()) {
            // Remove all layers
            this.map.removeLayers();

            // Set first route to layer
            this.layer.route = this.route;

            // Add route layer
            this.map.addLayer(this.layer);

            // Fit to line
            this.map.fitGeometry(this.route.route.geometry);
        }
    }

    public async selectRoute(i: number): Promise<void> {
        // Add route to layer
        this.layer.route = this.routes[i];

        // Refresh layer
        await this.layer.reload();
    }

    public async extraLoad(): Promise<void> {
        // Get simplified form data
        let form = this.form.getData(true);

        // Assign form data to panel search options
        this.options.search = this.form.getData();

        // Timestamp or item not selected?
        if (!form.from || !form.to || !form.routeName) {
            return;
        }

        // Get whole day interval
        let from = new Date(new Date(form.from));
        let to = new Date(new Date(form.to));

        // Get waze routes
        this.routes = (await this.context.invipo.getDataset("travel-time-feeds-routes", `route.name=${form.routeName}&from=${from.toISOString()}&to=${to.toISOString()}&sort=interval.from:asc`)).data;

        // Get reference route for map line
        this.route = await this.context.invipo.getDataset("travel-time-feeds-routes", `route.name=${form.routeName}&from=${from.toISOString()}&pageSize=1&page=1&sort=timestamp:desc`);

        // Assign first route
        this.route = this.route.data[0];

        // Calculate data extrems
        let average = this.routes.map(x => (x.currentTime - x.historicTime)).reduce((r, n) => { return r + n}, 0) / (this.routes?.length || 1);
        let longest = Math.max(...this.routes.map(x => (x.currentTime - x.historicTime)));

        // Build delay KPIs
        this.kpis = {
            size: "Half",
            data: [
                {
                    label: "components.WazeReport.averageDelay",
                    value: Number.isNaN(average) ? this.context.locale.getMessage("common.noData") : Helpers.toDuration(average),
                    description: Number.isNaN(average) ? "" : `${Helpers.toShortDateTimeString(from)} &ndash; ${Helpers.toShortDateTimeString(to)}`
                },
                {
                    label: "components.WazeReport.longestDelay",
                    value: Number.isNaN(longest) ? this.context.locale.getMessage("common.noData") : Helpers.toDuration(longest),
                    description: Number.isNaN(longest) ? "" : Helpers.toShortDateTimeString(this.routes.find(x => longest == (x.currentTime - x.historicTime))?.timestamp)
                }
            ]
        }

        // Build count table
        this.table = {
            name: "Table",
            label: "components.WazeReport.table",
            columns: [
                {
                    style: "Label",
                    label: "tables.columns.date",
                    width: "99%"
                },
                {
                    label: "tables.columns.delay",
                    style: "Bold",
                    align: "Center",
                    width: "80px"
                }
            ],
            rows: []
        };

        for (let d of this.routes) {
            // Add table row
            this.table.rows.push({
                cells: [
                    Helpers.toShortDateTimeString(d.interval.from),
                    Helpers.toDuration(d.currentTime - d.historicTime)
                ]
            });
        }
    }

}
