import "./data-table.scss";
import * as template from "./data-table.hbs";
import { Component } from "hiyo/component";
import { TableColumn, DataTableOptions, TableRow, TableColumnType, TableGroup } from "./types";
import { Context } from "hiyo/context";
import { Log } from "hiyo/log";
import { Helpers, PropertyResolver } from "hiyo/helpers";
import { Dom } from "../../../hiyo/dom";

export class DataTable extends Component<Context, DataTableOptions> {

    // Properties
    public selectedRow: TableRow;
    public selectedRows: { [id: string]: TableRow };
    public groups: TableGroup[];

    // Private handlers
    private readonly onDownUpHandler: (e: KeyboardEvent) => void;

    // Event handling methods
    public onColumnSelect(column: TableColumn): void {}
    public onRowSelect(row: TableRow): void {}
    public onRowMultiselect(rows: TableRow[]): void {}
    public onMenuSelect(row: TableRow, trigger: HTMLElement): void {}

    constructor(context: Context, options: DataTableOptions) {
        super(context, template, options);

        // Validation of empty columns
        if (!this.options.columns || this.options.columns.length == 0) {
            Log.w(`${this.id} has no columns defined, will be displayed as empty. Ensure you created component options correctly.`);
        }

        // Menu works only with normal table size
        if (this.options.menu) {
            this.options.size = "Normal";
        }

        // Empty selected rows
        this.selectedRows = {};

        // Recreate rows if we have data
        if (this.options.data) {
            this.createRows();
        }

        // Key handler to add and remove by single instance
        this.onDownUpHandler = (e: KeyboardEvent) => {

            // Arrow up?
            if (e.key == "ArrowUp") {
                if (this.selectedRow && this.options.data && this.selectedRow.index > 0) {
                    // Move to next row
                    this.selectRow(this.options.data[this.selectedRow.index - 1][this.options.rows.id]);

                    // Prevent to not scroll up
                    e.preventDefault();
                }
            }

            // Arrow down?
            if (e.key == "ArrowDown") {
                if (this.selectedRow && this.options.data && this.selectedRow.index < this.options.data.length - 1) {
                    // Move to next row
                    this.selectRow(this.options.data[this.selectedRow.index + 1][this.options.rows.id]);

                    // Prevent to not scroll down
                    e.preventDefault();
                }
            }
        }
    }

    public attach(target?: string | HTMLElement, before?: boolean, skipLoad?: boolean) {
        // FIXME: tohle dela problemy, protoze vytvari ihned prazdne pole, ale z nejakeho neznameho duvodu to tu bylo doplneno
        // Recreate rows
        //this.createRows();

        // Attach base
        super.attach(target, before, skipLoad);

        // Register key listener
        document.addEventListener("keydown", this.onDownUpHandler);
    }

    public detach() {
        // Unregister key listener
        document.removeEventListener("keydown", this.onDownUpHandler);

        // Do detach
        super.detach();
    }

    public update(): void {
        // Generic update
        super.update();

        // Perform selection (if row exists after update)
        if (this.selectedRow) {
            this.query(`table tbody tr[id='${this.selectedRow.id}']`)?.classList.add("row-selected");
        }
    }

    public selectColumn(i: number): void {
        if (i < 0 || i >= this.options.columns.length) {
            Log.w(`Column index of ${this.id} out of range`);
            return;
        }

        const column = this.options.columns[i];

        // Same column, will change sorting order
        if (column.selected) {
            column.descendent = !column.descendent;
        }
        // Another column
        else {
            // Deselect all
            this.options.columns.forEach(x => x.selected = false);

            // Select current
            column.selected = true;
        }

        // We have to deselect row
        // FIXME: This should not happening, we have to store get new index after rows are sorted
        //this.selectedRow = undefined;

        // Sort in client?
        if (this.options.autosort) {
            // Recreate rows because of sorting rules
            this.createRows();

            // Redraw
            this.update();
        }

        // OnColumnSelect handler
        this.onColumnSelect(column);
    }

    public unselectRow(id: string): void {
        // Not selected
        if (this.selectedRow?.id != id) {
            return;
        }

        // Unselect
        this.query(`table tbody tr[id='${this.selectedRow.id}']`)?.classList.remove("row-selected");

        // Remove from selected
        this.selectedRow = null;
    }

    public selectRow(id: string): void {
        // Already selected?
        if (this.selectedRow?.id == id) {
            return;
        }

        // New row to select
        let row: TableRow = null;

        for (let group of this.groups) {
            row = group.rows.find(x => x.id == id);
            if (row) {
                break;
            }
        }

        // Not found?
        if (!row) {
            Log.w(`Could not select row ${id || "with empty id"} in ${this.id}`);
            return;
        }

        if (this.selectedRow) {
            this.unselectRow(this.selectedRow.id);
        }

        // Single select type
        if (this.options.type == "SingleSelect") {
            // Select current row
            this.query(`table tbody tr[id='${id}']`)?.classList.add("row-selected");

            // Store index
            this.selectedRow = row;
        }

        // OnRowSelect handler
        this.onRowSelect(row);
    }

    public selectCheckbox(rowId: string, e: MouseEvent): void {
        // Row not checked?
        if (!this.selectedRows[rowId]) {
            // First find row data
            let row: TableRow = null;

            for (let group of this.groups) {
                row = group.rows.find(x => x.id == rowId);
                if (row) {
                    break;
                }
            }

            // Select current checkbox and row
            this.query(`table tbody tr[id='${rowId}'] div.checkbox`)?.classList.replace("checkbox-off", "checkbox-on");
            this.query(`table tbody tr[id='${rowId}']`)?.classList.add("row-selected");

            // Add row to selection
            this.selectedRows[rowId] = row;
        }
        // Checked already?
        else {
            // Unselect current checkbox and row
            this.query(`table tbody tr[id='${rowId}'] div.checkbox`)?.classList.replace("checkbox-on", "checkbox-off");
            this.query(`table tbody tr[id='${rowId}']`)?.classList.remove("row-selected");

            // Remove row from selection
            delete this.selectedRows[rowId];
        }

        // Stop event propagation to prevent row click
        e.stopPropagation();

        // OnMultiRowSelect handler
        this.onRowMultiselect(Object.values(this.selectedRows));

    }

    public unselectCheckboxes(): void {
        // Uneselect all
        for (let rowId in this.selectedRows) {
            // Unselect current checkbox and row
            this.query(`table tbody tr[id='${rowId}'] div.checkbox`)?.classList.replace("checkbox-on", "checkbox-off");
            this.query(`table tbody tr[id='${rowId}']`)?.classList.remove("row-selected");

            // Remove row from selection
            delete this.selectedRows[rowId];
        }
    }

    public selectMenu(rowId: string, e: MouseEvent, trigger: HTMLElement): void {
        // New row to select
        let row: TableRow = null;

        for (let group of this.groups) {
            row = group.rows.find(x => x.id == rowId);
            if (row) {
                break;
            }
        }

        // Not found?
        if (!row) {
            Log.w(`Could not select row ${rowId || "with empty id"} in ${this.id}`);
            return;
        }

        // Stop event propagation to prevent row click
        e.stopPropagation();

        // OnRowSelect handler
        this.onMenuSelect(row, trigger);
    }

    public setData(data: any[]): void {
        this.options.data = data;

        // Recreate rows
        this.createRows();

        // Update component
        this.update();
    }

    private sortData(type: TableColumnType, property: string | PropertyResolver, descendent?: boolean): void {
        // Sort via custom compare function
        this.options.data.sort((a: any, b: any): number => {
            // Null combinations
            if (!a && !b) return 0;
            if (a && !b) return 1;
            if (!a && b) return -1;

            // Get values
            let valueA = Helpers.getProperty(a, property);
            let valueB = Helpers.getProperty(b, property);

            // Result
            let result = 0;

            switch (type) {
                case "String":
                    result = String(valueA).localeCompare(String(valueB));
                    break;
                case "Date":
                case "Time":
                case "DateTime":
                    result = new Date(valueA).getTime() - new Date(valueB).getTime();
                    break;
                case "Duration":
                case "Number":
                    result = Number(valueA) - Number(valueB);
                    break;
            }

            if (descendent) {
                result *= -1;
            }

            return result;
        });
    }

    private createRows(): void {
        // Empty groups
        this.groups = [];

        // Empty rows
        let rows = [];

        // No data?
        if (!this.options.data) {
            return;
        }

        // Sort in client?
        if (this.options.autosort) {
            // Sort based on selected column
            let column = this.options.columns.find(x => x.selected);
            if (column) {
                this.sortData(column.type, column.property, column.descendent);
            }
        }

        // Create rows based on the data and column definitions
        for (let i in this.options.data) {
            let d = this.options.data[i];

            // Get row id, if no row id found, index will be used instead
            let id = Helpers.getProperty(d, this.options.rows.id) ?? i;

            let decorator = null;

            // Row has own decorator?
            if (this.options.rows.decorator) {
                decorator = this.options.rows.decorator(d);
            }

            let disabler = null;

            // Row has own disabler?
            if (this.options.rows.disabler) {
                disabler = this.options.rows.disabler(d);
            }

            // New row with unique id and empty cells
            let row: TableRow = {
                id: id,
                index: Number(i),
                data: d,
                cells: [],
                decorator: decorator,
                disabler: disabler
            };

            for (let c of this.options.columns) {
                // Get data value
                let value = Helpers.getProperty(d, c.property);

                // Undefined or null value?
                if (value == null && !c.formatter) {
                    // We are putting empty string as we need to render <td/> tag regardless there is content or no
                    row.cells.push("");
                }
                // Custom formatter that is also able to handle null values
                else if (c.formatter) {
                    row.cells.push(c.formatter(value, d) || "");
                }
                // Format based on type
                else {
                    switch (c.type) {
                        case "String":
                            row.cells.push(String(value));
                            break;
                        case "Date":
                            row.cells.push(Helpers.toDateString(value));
                            break;
                        case "Time":
                            row.cells.push(Helpers.toTimeString(value));
                            break;
                        case "DateTime":
                            row.cells.push(Helpers.toShortDateTimeString(value));
                            break;
                        case "Duration":
                            row.cells.push(Helpers.toDuration(value));
                            break;
                        case "Number":
                            row.cells.push(Helpers.toNumber(value, 9));
                            break;
                        default:
                            row.cells.push(value);
                            break;
                    }
                }
            }

            rows.push(row);
        }

        // Grouping available?
        if (this.options.groups) {
            // Group rows
            for (let row of rows) {
                let value = Helpers.getProperty(row.data, this.options.groups.property);

                // No value
                if (!value) {
                    continue;
                }

                // Has formatter?
                if (this.options.groups.formatter) {
                    value = this.options.groups.formatter(value, row.data) || "";
                }

                // Find relevant group
                let group = this.groups.find(x => x.value == value);

                // Exists?
                if (group) {
                    group.rows.push(row);
                }
                else {
                    // Create new group
                    this.groups.push({
                        value: value,
                        rows: Array.of(row)
                    });
                }
            }

            // Group sorting enabled?
            if (this.options.groups.sort) {
                this.groups.sort(this.options.groups.sort);
            }
        }
        else {
            // Single group with all rows and only if we have data
            if (rows.length > 0) {
                this.groups.push({
                    value: null,
                    rows: rows
                });
            }
        }
    }

    public exportCsv(name: string): void {
        // No data
        if (!this.groups) {
            Log.w("No data to export");
        }

        // Export file
        let csv = "";

        // Columns first
        for (let i = 0; i < this.options.columns.length; i++) {
            // Fake column?
            if (this.options.columns[i].property == null) {
                continue;
            }

            // Add column name
            csv += this.options.columns[i].name;

            // Separator?
            if (i < this.options.columns.length - 1) {
                csv += ";";
            }
        }

        // New line
        csv += "\n";

        for (let d of this.options.data) {
            for (let i = 0; i < this.options.columns.length; i++) {
                // Fake column?
                if (this.options.columns[i].property == null) {
                    continue;
                }

                // Get value from data
                let value = Helpers.getProperty(d, this.options.columns[i].property);

                // Add to export
                csv += value ?? "";

                // Separator?
                if (i < this.options.columns.length - 1) {
                    csv += ";";
                }
            }

            // New line
            csv += "\n";
        }

        // Create link to download CVS on background
        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        let url = URL.createObjectURL(blob);

        // Download
        Dom.openLink(url, `export-${Date.now()}.csv`)
    }

}