import "./ashghal-violation-detail.scss";
import * as template from "./ashghal-violation-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Tabs } from "muklit/components/tabs/tabs";
import { Detail } from "muklit/components/detail/detail";
import { TabsItem } from "muklit/components/tabs/types";
import { AshghalViolationDetailOptions } from "./types";
import { OverflowMenu } from "muklit/components/overflow-menu/overflow-menu";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { AccessDetail } from "../../safety/access-detail/access-detail";
import { ViolationCommentForm } from "../violation-comment-form/violation-comment-form";
import { OffensePreview } from "../../traffic/offense-preview/offense-preview";
import { VehicleRegistryDetail } from "../../traffic/vehicle-registry-detail/vehicle-registry-detail";
import { VideoDetail } from "../../common/video-detail/video-detail";
import { Point } from "geojson";
import { Dialog } from "../../../../muklit/components/dialog/dialog";

export class AshghalViolationDetail extends Detail<InvipoContext, AshghalViolationDetailOptions> {

    // Properties
    public violation: any;
    public access: AccessDetail;

    // Components
    public tabs: Tabs;

    // Event handling methods
    public onDetailUpdate(): void {}

    constructor(context: InvipoContext, options: AshghalViolationDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs();

        // Register components that will be automatically attached
        this.registerComponent(this.tabs, "tabs");
    }

    private createTabs(): void {
        // Tabs
        let tabs: TabsItem[] = [
            {
                name: "Data",
                label: "components.AshghalViolationDetail.data",
                content: "div.content-data",
                selected: true
            },
            {
                name: "Violation",
                label: "components.AshghalViolationDetail.violation",
                content: "div.content-violation"
            },
            {
                name: "Stream",
                label: "components.AshghalViolationDetail.stream",
                content: "div.content-stream"
            }
        ];

        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Light",
            tabs: tabs
        });
    }

    public openOffense(): void {
        let form = new OffensePreview(this.context, {
            style: "Light",
            title: "components.OffensePreview.title",
            key: "violation.offense",
            data: this.violation,
            overlay: true,
            printable: true,
            closable: true
        });

        // Show form
        form.attach();
    }

    public openOffenseImages(): void {
        let form = new OffensePreview(this.context, {
            style: "Light",
            title: "components.OffensePreview.title",
            key: "incident.offense.images",
            data: this.violation.data.incident,
            overlay: true,
            printable: true,
            closable: true
        });

        // Show form
        form.attach();
    }

    public openRegistry(): void {
        // Detail form
        let detail = new VehicleRegistryDetail(this.context, {
            style: "Light",
            title: null,
            overlay: true,
            closable: true,
            lpn: this.violation.data.incident.extras.plate?.number
        });

        // Show form
        detail.attach();
    }

    public async selectAssignee(e: MouseEvent): Promise<void> {
        // Create component
        let menu = new OverflowMenu(this.context, {
            style: "Light",
            start: "TopRight",
            anchor: "TopRight",
            items: []
        });

        // Get users
        let users = await this.context.invipo.getDataset("users");

        // Push languages as items
        for (let user of users.data) {
            menu.options.items.push(
                {
                    name: user.id,
                    label: user.name,
                    disabled: this.violation.workflow.assignee?.id == user.id
                }
            )
        }

        // Change language
        menu.onSelect = async (item: MenuItem): Promise<void> => {
            // Show loader
            this.showLoader();

            // Update column
            await this.context.invipo.putManagement(`violations/${this.violation.id}/workflow/assignee`, {
                assignee: item.name
            });

            // Hide loader and redraw
            this.hideLoader();
            await this.load();

            // OnUpdate handler
            this.onDetailUpdate();
        }

        // Show menu
        menu.show(<HTMLElement>e.target);
    }

    public selectImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Light",
            title: "components.ImageDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Show
        detail.attach();
    }

    public showCommentMenu(i: number, event: MouseEvent): void {
        // Create overlfow menu
        let menu = new OverflowMenu(this.context, {
            style: this.options.style,
            anchor: "TopLeft",
            start: "TopLeft",
            offset: [0, 8],
            items: [
                {
                    name: "Edit",
                    label: "labels.edit"
                },
                {
                    name: "Delete",
                    label: "labels.delete",
                    escalated: true
                }
            ]
        });

        // OnMenuSelect handler
        menu.onSelect = (item: MenuItem) => {
            // Edit comment?
            if (item.name == "Edit") {
                this.openComment(i);
            }
            // Delete comment?
            if (item.name == "Delete") {
                this.deleteComment(i);
            }
        }

        // Show over menu button
        menu.show(null, event.pageX, event.pageY);
    }

    public openComment(i: number): void {
        // Dialog to confirm
        let form = new ViolationCommentForm(this.context, {
            style: "Light",
            title: (i >= 0) ? "components.ViolationCommentForm.titleEdit" : "components.ViolationCommentForm.titleAdd",
            violationId: this.violation.id,
            commentText: (i >= 0) ? this.violation.stream[i].text : null,
            commentIndex: i,
            overlay: true
        })

        // OnSubmit handler
        form.onSubmit = async () => {
            // Delete call
            await this.load();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show dialog
        form.attach();
    }

    public deleteComment(i: number): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.HanoiViolationDetail.deleteComment",
            text: "components.HanoiViolationDetail.reallyDeleteComment",
            labelCancel: "labels.cancel",
            labelConfirm: "labels.delete",
            escalated: true
        })

        // OnUserLogout handler
        dialog.onConfirm = async () => {
            // Delete call
            await this.context.invipo.deleteManagement(`/violations/${this.violation.id}/comments/${i}`);

            // Close dialog
            dialog.close();

            // Reload
            await this.load();
        }

        // Show dialog
        dialog.attach();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        this.violation = await this.context.invipo.getManagement(`violations/${this.options.violationId}`);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Redraw with all components
        this.invalidate(true);
    }

}
