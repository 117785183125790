// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.muklit-message-form-marker div.circle {
  height: 10px;
  width: 10px;
  background-color: rgb(21, 184, 0);
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
}
div.muklit-message-form-marker.selected div.circle {
  background-color: rgb(211, 0, 0);
}`, "",{"version":3,"sources":["webpack://./src/invipo/components/management/message-form/message-form-marker.scss"],"names":[],"mappings":"AAIE;EACE,YAAA;EACA,WAAA;EACA,iCAAA;EACA,sBAAA;EACA,kBAAA;EACA,eAAA;AAHJ;AAME;EACE,gCAAA;AAJJ","sourcesContent":["@import \"~muklit/styles/mixins\";\r\n@import \"~muklit/styles/colors\";\r\n\r\ndiv.muklit-message-form-marker {\r\n  div.circle {\r\n    height: 10px;\r\n    width: 10px;\r\n    background-color: rgb(21, 184, 0);\r\n    border: 2px solid #fff;\r\n    border-radius: 50%;\r\n    cursor: pointer;\r\n  }\r\n\r\n  &.selected div.circle {\r\n    background-color: rgb(211, 0, 0);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
