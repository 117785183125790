import "./hanoi-violation-browser.scss";
import * as template from "./hanoi-violation-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { HanoiViolationBrowserOptions } from "./types";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { HanoiViolationReasonForm } from "../hanoi-violation-reason-form/hanoi-violation-reason-form";
import { HanoiViolationDetail } from "../hanoi-violation-detail/hanoi-violation-detail";
import { HanoiViolationDetailOptions } from "../hanoi-violation-detail/types";
import { HanoiViolationReportForm } from "../hanoi-violation-report-form/hanoi-violation-report-form";

export const COLUMN_VIOLATION_REVIEW = "Violation review";
export const COLUMN_VIOLATION_IN_PROCESS = "Violation in process";
export const COLUMN_VIOLATION_NOTICE = "Violation notice";
export const COLUMN_REPORT_MANAGEMENT = "Report management"
export const COLUMN_LEGAL_DECISION = "Legal decision"
export const COLUMN_UNSUBSTANTIATED_VIOLATION = "Unsubstantiated violation"

export abstract class HanoiViolationBrowser<T extends HanoiViolationBrowserOptions> extends MuklitComponent<InvipoContext, T> {

    // Components
    public table: FilterTable;
    public detail: HanoiViolationDetail;

    constructor(context: InvipoContext, options?: T) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    public abstract createTable(): void;

    protected async openDetail(data: any): Promise<void> {
        // Detail options
        let options: HanoiViolationDetailOptions = {
            style: "Light",
            violationId: data.id,
            title: `enums.ViolationType.${data.type}`,
            subtitle: data.item.name,
            closable: true
        }

        // Detail already visible?
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Relaod completely
            await this.detail.load();

            // Not continue
            return;
        }

        // New detail
        this.detail = new HanoiViolationDetail(this.context, options);

        // Refresh table on detail update
        this.detail.onDetailUpdate = async () => {
            await this.table.load();
        }

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.violationId);
            this.detail = null;
        }

        // Show detail
        this.detail.attach();
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Light",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: true,
            items: [
                {
                    name: "ViolationsCsv",
                    label: "components.ServerExportForm.types.Csv",
                    checked: true
                }
            ],
            query: this.table.getQuery(),
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }

    public async confirmViolation(): Promise<any> {
        // Show loader
        this.showLoader();

        // Itterate all selected rows
        for (let id in this.table.table.selectedRows) {
            // Update column
            await this.context.invipo.putManagement(`violations/${id}/workflow/column`, {
                column: COLUMN_VIOLATION_IN_PROCESS
            });
        }

        // Hide loader and redraw
        this.hideLoader();

        // Close detal
        this.detail?.close();

        // Unselect all checkboxes
        this.table.unselectCheckboxes();

        // Relaod
        await this.table.load();
    }

    public insufficientGrounds(): any {
        // Dialog to confirm
        let form = new HanoiViolationReasonForm(this.context, {
            style: "Light",
            title: null,
            violations: Object.keys(this.table.table.selectedRows),
            overlay: true
        })

        // OnSubmit handler
        form.onSubmit = async () => {
            // Unselect all checkboxes
            this.table.unselectCheckboxes();

            // Close detal
            this.detail?.close();

            // Reload
            await this.table.load();
        }

        // Show dialog
        form.attach();
    }

    public openReport(): void {
        // Get first selected violaiton
        let violation = Object.values(this.table.table.selectedRows)[0].data;

        // Dialog to confirm
        let form = new HanoiViolationReportForm(this.context, {
            style: "Light",
            title: null,
            overlay: true,
            violation: violation
        })

        // OnSubmit handler
        form.onSubmit = async () => {
            // Unselect all checkboxes
            this.table.unselectCheckboxes();

            // Close self if opened
            if (this.detail?.options.violationId == violation.id) {
                this.detail.close();
            }

            // Delete call
            await this.table.load();
        }

        // Show dialog
        form.attach();
    }
}
