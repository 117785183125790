import * as template from "./item-properties-panel.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { Panel } from "../../common/panel/panel";
import { PanelTable } from "../../common/panel/types";
import { ItemPropertiesPanelOptions } from "./types";
import { Point } from "geojson";

export class ItemPropertiesPanel extends Panel<ItemPropertiesPanelOptions> {

    // Properties
    public table: PanelTable;

    constructor(context: InvipoContext, options?: ItemPropertiesPanelOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Build properties table
        this.table = {
            columns: [
                {
                    style: "Label",
                    label: "tables.columns.property",
                    width: "160px"
                },
                {
                    label: "tables.columns.value",
                    style: "Bold",
                    align: "Right",
                    width: "99%"
                }
            ],
            rows: []
        };

        // ID
        this.table.rows.push({
            cells: [
                "item.id",
                this.item.id
            ]
        });

        // Name
        this.table.rows.push({
            cells: [
                "item.name",
                this.item.name
            ]
        });

        // ID
        this.table.rows.push({
            cells: [
                "item.class",
                `classes.${this.item.class}`
            ]
        });

        // Has model?
        if (this.item.model) {
            this.table.rows.push({
                cells: [
                    "item.model",
                    this.item.model
                ]
            });
        }

        // Has producer?
        if (this.item.producer) {
            this.table.rows.push({
                cells: [
                    "item.producer",
                    this.item.producer
                ]
            });
        }

        // Has status?
        if (this.item.monitoringStatus) {
            this.table.rows.push({
                cells: [
                    "item.monitoringStatus",
                    `monitoringStatus.${this.item.monitoringStatus.status}`
                ]
            });
        }

        // Has availability?
        if (this.item.availability) {
            this.table.rows.push({
                cells: [
                    "item.availability",
                    `${this.item.availability.value} %`
                ]
            });
        }

        // Has position?
        if (this.item.geometry.location) {
            this.table.rows.push({
                cells: [
                    "item.gps",
                    `${(<Point>this.item.geometry.location).coordinates[0]}, ${(<Point>this.item.geometry.location).coordinates[1]}`
                ]
            });
        }

        // Has location?
        if (this.item.location) {
            this.table.rows.push({
                cells: [
                    "item.location",
                    this.item.location
                ]
            });
        }

        // Has location?
        if (this.item.managementUrl) {
            this.table.rows.push({
                cells: [
                    "item.managementUrl",
                    `<a href="${this.item.managementUrl}" target="_blank">${this.item.managementUrl}</a>`
                ]
            });
        }

        // Has parent?
        if (this.item.parent) {
            this.table.rows.push({
                cells: [
                    "item.parent",
                    this.item.parent.name
                ]
            });
        }
    }
}
