// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.invipo-area-tooltip {
  max-width: 240px;
}`, "",{"version":3,"sources":["webpack://./src/invipo/components/settings/area-tooltip/area-tooltip.scss"],"names":[],"mappings":"AAIA;EACE,gBAAA;AAHF","sourcesContent":["@import \"../../../styles/colors\";\r\n@import \"~muklit/styles/_mixins.scss\";\r\n@import \"~muklit/styles/_colors.scss\";\r\n\r\ndiv.invipo-area-tooltip {\r\n  max-width: 240px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
