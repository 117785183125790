import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { METRICS } from "../../components/city/city-subdomain/types";

export class TravelTimeFeedLineLayer extends MapLayer<InvipoContext> {

    // Properties
    public feed: any;

    public constructor(context: InvipoContext, feed?: any) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            before: "road-label",
            card: "TravelTimeFeedRouteCard",
            layer: {
                id: "travel-time-feed-line",
                type: "line",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "line-color": ["get", "color"],
                    "line-opacity": 1,
                    "line-width": 4,
                    "line-offset": 3.5,
                    "line-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "line-opacity": 1
            }
        });

        // Set message
        this.feed = feed;
    }

    public async load(): Promise<any> {
        // Empty data?
        if (!this.feed) {
            this.feed = (await this.context.invipo.getDataset("travel-time-feeds", "sort=interval.to:desc&pageSize=1"))?.data[0];
        }

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let route of this.feed.routes) {
            // Set unknown if undefined
            let level = route.level ?? 5;

            json.features.push({
                type: "Feature",
                properties: {
                    card: this.options.card,
                    cardId: route.route.name,
                    color: METRICS.traffic.traveltime.level.colors[level],
                    level: METRICS.traffic.traveltime.level.range[level],
                    name: route.route.name,
                    origin: route.route.origin.name,
                    destination: route.route.destination.name,
                    length: route.route.length,
                    currentTime: route.currentTime,
                    historicTime: route.historicTime
                },
                geometry: route.route.geometry
            })
        }

        // Sort by level
        json.features.sort((a, b) => {
            return Number(a.properties.level) - Number(b.properties.level);
        })

        return json;
    }
}
