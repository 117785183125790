import "./face-search-report.scss";
import * as template from "./face-search-report.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { FaceSearchReportOptions } from "./types";
import { Form } from "muklit/components/form/form";
import { PanelKpis, PanelTable } from "../../common/panel/types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { Panel } from "../../common/panel/panel";
import { RangeInput } from "muklit/components/range-input/range-input";
import { Helpers } from "hiyo/helpers";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { GoogleOrthophotoLayer } from "../../../layers/custom/google-orthophoto-layer";
import { ItemSelect } from "../../common/item-select/item-select";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { ImageInput } from "muklit/components/image-input/image-input";
import { CameraLocationCircleLayer } from "../../../layers/safety/camera-location-circle-layer";
import { CameraLocationLabelLayer } from "../../../layers/safety/camera-location-label-layer";
import { VideoDetail } from "../../common/video-detail/video-detail";

export const FACE_LIMIT = 50;

export class FaceSearchReport extends Panel<FaceSearchReportOptions> {

    // Properties
    public faces: any[];
    public kpis: PanelKpis;
    public table: PanelTable;
    public items: InvipoItem[];

    public constructor(context: InvipoContext, options?: FaceSearchReportOptions) {
        super(context, template, options);
    }

    protected createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new RangeInput(this.context, {
                            style: "Light",
                            name: "interval",
                            type: "Range",
                            time: true,
                            label: "forms.fields.date",
                            placeholderText: "forms.placeholders.anytime",
                            bright: true,
                            required: true
                        }),
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "itemId",
                            label: "forms.fields.item",
                            value: this.options.itemId,
                            placeholderText: "forms.placeholders.all",
                            itemClass: "HumanCounter",
                            items: [],
                            multiselect: true,
                            bright: true
                        }),
                        new ImageInput(this.context, {
                            style: "Light",
                            name: "image",
                            label: "forms.fields.image",
                            type: "image/jpeg",
                            height: 160,
                            required: true
                        }),
                    ]
                }
            ]
        });

        // Register component
        this.registerComponent(this.form, "form");
    }

    protected createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "GoogleOrthophoto",
            center: this.context.options.home.center,
            zoom: this.context.options.home.zoom,
            minZoom: 2,
            maxZoom: 18
        });

        // Add Google custom layer
        this.map.onMapLoad = () => {
            this.map.mapboxMap.addLayer(new GoogleOrthophotoLayer(this.context).options.layer);
        }

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
    }

    public selectImage(i: number): void {
        // Stop propagation
        event.stopPropagation();
        event.preventDefault();

        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: this.faces[i].images[0].content,
            urls: (<any[]>this.faces[i].images).map(x => x.content),
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }

    public async selectRow(row: number): Promise<void> {
        // Get item
        let item = this.items.find(x => x.name == this.table.rows[row].cells[2])

        // Flight to place
        this.map.flyTo({
            center: (<any>item.geometry.location).coordinates,
            zoom: 20
        })
    }

    public openVideo(i: number): void {
        // Stop propagation
        event.stopPropagation();
        event.preventDefault();

        // Get item location
        let item = this.items.find(x => x.id == this.faces[i].item.id);

        // Construct stream, start 1s before
        let stream = item.meta.stream.iframe.replace("{{timestamp}}", new Date(new Date(this.faces[i].timestamp).getTime() - 1000).toISOString());

        // New image detail
        let detail = new VideoDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            stream: stream,
            overlay: true,
            closable: true
        });

        // Show
        detail.attach();
    }

    public async extraLoad(): Promise<void> {
        // Load all items
        this.items = this.context.data.getItems();

        // Assign form data to panel search options
        this.options.search = this.form.getData();

        // Get simplified form data
        let form = this.form.getData(true);

        // Item not selected?
        if (!form.from || !form.to || !form.image) {
            return;
        }

        // Speed data per hour
        this.faces = await this.context.invipo.postExternal("luna/face-search", {
            from: form.from,
            to: form.to,
            items: this.options.search.itemId ? Object.values(this.options.search.itemId) : null,
            face: form.image,
            threshold: 0.95,
            limit: FACE_LIMIT
        });

        // Sort by timestamp
        this.faces.sort((a, b) => {
            return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
        })

        // Build up locations
        let locations: any[] = [];

        for (let d of this.faces) {
            // Find corresponding item
            let item = this.items.find(x => x.id == d.item?.id);

            // Not found?
            if (!item) {
                continue;
            }

            // Get item location
            let location = locations.find(x => x.name == item.name);

            // Already exists?
            if (location) {
                location.count += 1;
            }
            // New location
            else {
                locations.push({
                    name: item.name,
                    geometry: item.geometry?.location,
                    direction: item.meta?.direction || 0,
                    count: 1
                });
            }
        }

        // Reinitialize layers
        this.map.removeLayers();
        this.map.addLayer(new CameraLocationCircleLayer(this.context, locations));
        this.map.addLayer(new CameraLocationLabelLayer(this.context, locations));
        //this.map.addLayer(new CameraLocationSymbolLayer(this.context, locations));

        // Clear KPIs
        this.kpis = null;

        // Build categories table
        this.table = {
            name: "Table",
            label: "components.VehicleAppearanceReport.table",
            selectable: true,
            columns: [
                {
                    label: "#",
                    width: "20px"
                },
                {
                    style: "Label",
                    label: "tables.columns.date",
                    width: "99%"
                },
                {
                    label: "tables.columns.location",
                    align: "Left",
                    width: "160px"
                },
                {
                    label: "tables.columns.match",
                    align: "Center",
                    width: "100px"
                },
                {
                    label: "tables.columns.video",
                    align: "Left",
                    width: "120px"
                },
                {
                    label: "tables.columns.image",
                    align: "Right",
                    width: "80px"
                }
            ],
            rows: []
        };

        for (let i = 0; i < this.faces.length; i++) {
            // Data
            let d = this.faces[i];

            // Add table row
            this.table.rows.push({
                cells: [
                    Helpers.toNumber(i + 1),
                    Helpers.toDateTimeString(d.timestamp),
                    d.item?.name || this.context.locale.getMessage("common.unknown"),
                    `${Helpers.toNumber(Math.round(d.similarity * 100))}%`,
                    `<div class="partial-action partial-action-single" onclick="component.openVideo(${i})">${this.context.locale.getMessage("labels.play")}</div>`,
                    `<img class="snapshot" width="80" src="${d.images[0].content}" onclick="component.selectImage(${i})" />`
                ]
            });
        }
    }

}
