import "./travel-time-route-detail.scss";
import * as template from "./travel-time-route-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Dialog } from "muklit/components/dialog/dialog";
import { TravelTimeRouteDetailOptions } from "./types";
import { BasicMap } from "../../../../muklit/components/basic-map/basic-map";
import { TravelTimeRouteLineLayer } from "../../../layers/management/travel-time-route-line-layer";

export class TravelTimeRouteDetail extends Detail<InvipoContext, TravelTimeRouteDetailOptions> {

    // Properties
    public route: any;

    // Components
    public map: BasicMap;

    // Event handling methods
    public onDetailUpdate(): void {}

    constructor(context: InvipoContext, options: TravelTimeRouteDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.overview.center,
            zoom: this.context.options.overview.zoom - 0.5,
            minZoom: 5,
            maxZoom: 20
        });

        // Register component
        this.registerComponent(this.map, "map");
    }

    public onAttach() {
        // Remove layers we have added previously
        this.map.removeLayers();

        // Recreate new layers to fetch new data
        this.map.addLayer(new TravelTimeRouteLineLayer(this.context, <any>this.route));
    }

    public delete(): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.TravelTimeRouteDetail.routeDelete",
            text: this.context.locale.getMessage("components.TravelTimeRouteDetail.reallyDelete", this.template.name),
            labelCancel: "labels.cancel",
            labelConfirm: "labels.delete",
            escalated: true
        })

        // OnTemplateLogout handler
        dialog.onConfirm = async () => {
            // Delete call
            await this.context.invipo.deleteManagement(`catalog/users/${this.options.routeId}`);

            // Close dialog
            dialog.close();

            // Close detail
            this.close();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show dialog
        dialog.attach();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        this.route = await this.context.invipo.getManagement(`traveltime/routes/${this.options.routeId}`);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Redraw with all components
        this.invalidate(true);
    }

}
