import { FeatureCollection, Point } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { InvipoItem } from "../../clients/invipo-client/types";
import { CLASS_COLORS } from "../city/item-circle-layer";
import { InvipoHelpers } from "../../invipo-helpers";

export class ItemClassCircleLayer extends MapLayer<InvipoContext> {

    // Properties
    public items: InvipoItem[];

    public constructor(context: InvipoContext, items?: InvipoItem[]) {
        super(context, {
            dynamic: true,
            tooltip: true,
            layer: {
                id: "item-class-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 4,
                        12, 5,
                        16, 8,
                        19, 10,
                        22, 12
                    ],
                    "circle-color": [
                        "case",
                        ["==", ["get", "cluster"], ["boolean", true]], "#ffffff",
                        ["get", "color"]
                    ],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        10, 2,
                        22, 7
                    ],
                    "circle-stroke-color": [
                        "case",
                        ["==", ["get", "cluster"], ["boolean", true]], ["get", "color"],
                        "#ffffff"
                    ],
                    "circle-stroke-opacity": 0, // 0 -> 1
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": [
                    "case",
                    ["==", ["get", "cluster"], ["boolean", true]], 0,
                    1
                ],
                "circle-stroke-opacity": [
                    "case",
                    ["==", ["get", "cluster"], ["boolean", true]], 1,
                    0.8
                ]
            }
        });

        // Assign properties
        this.items = items;
    }

    public async load(): Promise<any> {
        // Load or reuse items
        let items = this.items || this.context.data.getItems();

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Build unique position list
        let cluster = InvipoHelpers.toItemCluster(items);

        // Push features from items
        for (let items of Object.values(cluster)) {
            // Domain color
            let color = "#dddddd";

            // Is collor in class color map?
            for (let c in CLASS_COLORS) {
                if (CLASS_COLORS[c].includes(items[0].class)) {
                    color = c;
                }
            }

            json.features.push({
                type: "Feature",
                properties: {
                    card: "MonitoringCard",
                    content: "MonitoringPanel",
                    detail: items.length == 1 ? "CityDetail" : null,
                    tooltip: items.length > 1 ? items.map(x => { return x.name }).join("\n") : `${items[0].name}\n${this.context.locale.getMessage(`classes.${items[0].class}`)}`,
                    cluster: items.length > 1,
                    color: color,
                    cardId: items.map(x => { return x.id }).join(","),
                    itemId: items.map(x => { return x.id }).join(","),
                    itemName: items[0].name,
                    itemClass: items[0].class,
                    itemStatus: items[0].monitoringStatus?.status,
                },
                geometry: items[0].geometry["location"]
            })
        }

        return json;
    }
}
