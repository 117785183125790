import "./access-detail.scss";
import * as template from "./access-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { AccessDetailOptions } from "./types";
import { Detail } from "muklit/components/detail/detail";
import { ViolationDetail } from "../../enforcement/violation-detail/violation-detail";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { ImageDetail } from "../../common/image-detail/image-detail";

export class AccessDetail extends Detail<InvipoContext, AccessDetailOptions> {

    // Components
    public violation: ViolationDetail;

    constructor(context: InvipoContext, options: AccessDetailOptions) {
        super(context, template, options);
    }

    public openImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }

    protected openViolation(): void {
        // New detail
        this.violation = new ViolationDetail(this.context, {
            style: "Light",
            violationId: this.options.access.violation.id,
            title: "components.ViolationDetail.title",
            returnable: true,
            menu: [
                {
                    name: "Delete",
                    label: "labels.delete",
                    escalated: true
                }
            ]
        });

        // Menu select
        this.violation.onMenuSelect = async (item: MenuItem)=> {
            // Delete action?
            if (item.name == "Delete") {
                this.violation.delete();
            }
        }

        // Refresh table on detail update
        this.violation.onDetailUpdate = async () => {
            await this.violation.load();
        }

        // Show detail
        this.violation.attach(this.query("div.overlay"));
    }
}
