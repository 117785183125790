// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.invipo-toast-manager {
  position: absolute;
  left: 64px;
  bottom: 8px;
  z-index: 300;
}`, "",{"version":3,"sources":["webpack://./src/invipo/components/common/toast-manager/toast-manager.scss","webpack://./src/muklit/styles/_positions.scss"],"names":[],"mappings":"AAIA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,YCJc;ADChB","sourcesContent":["@import \"~muklit/styles/positions\";\n@import \"~muklit/styles/mixins\";\n@import \"~muklit/styles/colors\";\n\ndiv.invipo-toast-manager {\n  position: absolute;\n  left: 56px + 8px;\n  bottom: 8px;\n  z-index: $z-index-toast;\n}\n","// Z-indexes\n$z-index-card: 10;\n$z-index-invipo: 20;\n$z-index-menu: 120;\n$z-index-toast: 300;\n$z-index-tooltip: 400;\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
