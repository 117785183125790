import { MenuView } from "./menu-view";
import { DrawerMenu } from "../components/common/drawer-menu/drawer-menu";

export class Wall extends MenuView {

    public onCreate(): void {
        // Multiple video walls, we need to display menu
        if (this.context.options.wall?.length > 1) {
            super.onCreate();
        }
        // Single video wall, we will just set content
        else {
            this.setContent(this.context.options.wall[0]);
        }
    }

    public createMenu(): void {
        // Create component
        this.menu = new DrawerMenu(this.context, {
            style: "Dark",
            groups: [
                {
                    name: "Walls",
                    items: []
                }
            ]
        });

        // Iterate all vide walls
        for (let w of this.context.options.wall) {
            // Add to menu
            this.menu.options.groups[0].items.push({
                name: w,
                label: `components.${w}.title`
            })
        }
    }
}
