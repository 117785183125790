import './waze-feed-tile.scss';
import * as template from "./waze-feed-tile.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Tile } from "../../common/tile/tile";
import { TileList } from "../../common/tile/types";
import { WazeFeedTileOptions } from "./types";
import { METRICS } from "../../city/city-subdomain/types";
import { Helpers } from "../../../../hiyo/helpers";

export class WazeFeedTile extends Tile<WazeFeedTileOptions> {

    // Properties
    public list: TileList;

    constructor(context: InvipoContext, options?: WazeFeedTileOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Feed data
        let feed = (await this.context.invipo.getDataset("waze-feeds", "sort=timestamp:desc&pageSize=1"))?.data[0];

        console.info(feed);

        // Builld items list
        this.list = {
            name: "ss",
            items: []
        };

        // Create feed level
        for (let level of METRICS.traffic.waze.density.range) {
            // Length in of km
            let length = (<any[]>feed.feed.routes).filter(x => x.jamLevel == METRICS.traffic.waze.density.range.indexOf(level)).map(x => x.length).reduce((x, y) => x + y, 0)

            this.list.items.push({
                name: `enums.TrafficDensity.${level}`,
                color: METRICS.traffic.waze.density.colors[METRICS.traffic.waze.density.range.indexOf(level)],
                label: `${Helpers.toNumber(length / 1000)} km`
            })
        }

    }
}
