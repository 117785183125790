import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { METRICS } from "../../components/city/city-subdomain/types";

export class TravelTimeRouteLineLayer extends MapLayer<InvipoContext> {

    // Properties
    public route: any;

    public constructor(context: InvipoContext, route?: any) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            before: "road-label",
            card: "TravelTimeRouteCard",
            layer: {
                id: "travel-time-route-line",
                type: "line",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "line-width": 4,
                    "line-color": "#008efa",
                    "line-opacity": 1,
                    "line-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "line-opacity": 1
            }
        });

        // Set message
        this.route = route;
    }

    public async load(): Promise<any> {
        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Set unknown if undefined
        let level = this.route.level ?? 5;

        // Just one feature for route
        json.features.push({
            type: "Feature",
            properties: {
                card: this.options.card,
                cardId: this.route.id,
                name: this.route.name,
                origin: this.route.origin.name,
                destination: this.route.destination.name
            },
            geometry: this.route.geometry
        })

        return json;
    }
}
