import "./cis-sla-wall.scss";
import * as template from "./cis-sla-wall.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { WallMap } from "../wall-map/wall-map";
import { MuklitComponent } from "../../../../muklit/components/muklit-component/muklit-component";
import { WallItems } from "../wall-items/wall-items";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { CisSlaWallOptions } from "./types";

export class CisSlaWall extends MuklitComponent<InvipoContext, CisSlaWallOptions> {

    // Properties
    private resizeListener: (event: Event) => void;

    // Components
    public map: WallMap;
    public items: WallItems;

    constructor(context: InvipoContext, options?: CisSlaWallOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createItems();
    }

    public createItems(): void {
        // Create Zlin region map component
        this.items = new WallItems(this.context, {
            itemTag: "sla",
            issues: true
        });

        // Open management url
        this.items.onSelect = (item: InvipoItem) => {
            window.open(item.managementUrl, "_blank");
        }

        // Register components that will be automatically attached
        this.registerComponent(this.items, "items");
    }
}
