import * as title from "./panel.title.hbs";
import * as map from "./panel.map.hbs";
import * as form from "./panel.form.hbs";
import * as chart from "./panel.chart.hbs";
import * as properties from "./panel.properties.hbs";
import * as kpis from "./panel.kpis.hbs";
import * as table from "./panel.table.hbs";
import * as item from "./panel.item.hbs";
import * as alerts from "./panel.alerts.hbs";
import * as malfunctions from "./panel.malfunctions.hbs";
import * as empty from "./panel.empty.hbs";
import "./panel.scss";
import { InvipoContext } from "invipo/context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoArea, InvipoItem } from "../../../clients/invipo-client/types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { Templates } from "hiyo/templates";
import { PanelOptions } from "./types";
import { InvipoHelpers } from "../../../invipo-helpers";
import { Dom } from "hiyo/dom";
import { ItemSingleCircleLayer } from "../../../layers/infrastructure/item-single-circle-layer";
import { Feature, Point } from "geojson";
import { Form } from "muklit/components/form/form";

export abstract class Panel<T extends PanelOptions = PanelOptions> extends MuklitComponent<InvipoContext, T> {

    // Properties
    public item: InvipoItem;
    public area: InvipoArea;

    // Components
    public form: Form;
    public map: BasicMap;

    protected constructor(context: InvipoContext, template: any, options?: T) {
        super(context, template, options);

        // Register partials
        Templates.registerPartial("panel-title", title);
        Templates.registerPartial("panel-map", map);
        Templates.registerPartial("panel-form", form);
        Templates.registerPartial("panel-chart", chart);
        Templates.registerPartial("panel-properties", properties);
        Templates.registerPartial("panel-kpis", kpis);
        Templates.registerPartial("panel-table", table);
        Templates.registerPartial("panel-item", item);
        Templates.registerPartial("panel-alerts", alerts);
        Templates.registerPartial("panel-malfunctions", malfunctions);
        Templates.registerPartial("panel-empty", empty);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
        this.createMap();
    }

    protected createForm(): void {
    }

    protected createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.overview?.center || this.context.options.home.center,
            zoom: this.context.options.overview?.zoom || this.context.options.home.zoom,
            minZoom: 2,
            maxZoom: 20
        });

        // Zoom on click
        this.map.onFeatureClick = async (layer: string, feature: Feature, event?: MouseEvent) => {
            this.map.flyTo({
                center: (<Point>feature.geometry).coordinates,
                zoom: 15
            });
        }

        // Register map layers, but only when itemId is passed
        if (this.options.itemId) {
            this.map.addLayer(new ItemSingleCircleLayer(this.context, this.options.itemId));
        }

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
    }

    public exportTable(selector: string): void {
        // Create link to download CVS on background
        let blob = new Blob([InvipoHelpers.toCsv(this.query(selector))], { type: "text/csv;charset=utf-8;" });
        let url = URL.createObjectURL(blob);

        // Download
        Dom.openLink(url, `export-table-${Date.now()}.csv`)
    }

    public toggleForm(): void {
        let icon = this.query("div.partial-icon-filter");
        let form = this.query("div.form");
        let hidden = form.classList.contains("form-hidden");

        // Toggle form and icon
        form.classList.toggle("form-hidden", !hidden);
        icon.classList.toggle("partial-icon-selected", hidden);

        // Form is shown?
        if (hidden) {
            // Add overlay element
            let overlay = Dom.createElement(this.element, `<div class="overlay"></div>`);

            // Hide click
            overlay.addEventListener("mousedown", () => {
                this.toggleForm();
            })

            // Animate
            this.animate({
                "div.form": "keyframes-panel-slide-in 150ms ease-in-out"
            });
        }
        else {
            // Remove first overlay under element (other overlays might appear in form)
            this.query(":scope > div.overlay")?.remove();
        }
    }

    public clear(): void {
        this.form.clear();
    }

    public async submit(): Promise<void> {
        // Invalid form data?
        if (!this.form.validate(true)) {
            return;
        }

        // Hide form
        this.toggleForm();

        // Relaod
        await this.load();
    }

    public async save(selector?: string): Promise<void> {
        // Convert element to canvas
        let canvas = await InvipoHelpers.toCanvas(selector ? this.query(selector) : this.element);

        // Download
        Dom.openLink(canvas.toDataURL("image/png"), `export-${Date.now()}.png`)
    }

    public async selectRow(row: number, table: string): Promise<void> {
    };

    public async extraLoad(): Promise<void> {
        // To overload in subclass to manage extra loading
    };

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load item data
        if (this.options.itemId) {
            this.item = this.context.data.getItem(this.options.itemId);
        }

        // Load area data
        if (this.options.areaId) {
            this.area = this.context.data.getArea(this.options.areaId);
        }

        // Extra loading
        await this.extraLoad();

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Update
        this.invalidate(true);
    }
}
