import "./image-input.scss";
import * as template from "./image-input.hbs";
import { Context } from "hiyo/context";
import { ImageInputOptions } from "./types";
import { Input } from "../input/input";
import { Log } from "../../../hiyo/log";

export const IMAGE_TYPES = ["image/jpeg", "image/png", "iamge/svg+xml"];

export class ImageInput extends Input<Context, ImageInputOptions> {

    // Properties
    public name: string;
    public size: number;
    public isImage: boolean;

    constructor(context: Context, options: ImageInputOptions) {
        super(context, template, options);
    }

    public dragOver(e: DragEvent): void {
        // Stop propagation
        e.stopPropagation();
        e.preventDefault();
    }

    public dragEnter(e: DragEvent): void {
        // Add active class
        this.query("div.upload").classList.add("upload-active");
    }

    public dragLeave(e: DragEvent): void {
        // Remove active class
        this.query("div.upload").classList.remove("upload-active");
    }

    public drop(e: DragEvent): void {
        // prevent default action (dont open new tab)
        e.stopPropagation();
        e.preventDefault();

        // First file
        let file = e.dataTransfer.files[0];

        // No file?
        if (!file) {
            Log.w(`ImageInput: No file dropped (empty data)`);
            return;
        }

        // Unsupported type?
        if (this.options.type && !this.options.type.split(",").includes(file.type)) {
            Log.w(`ImageInput: Unsupported image type (${file.type})`);
            return;
        }

        // Upload image
        this.upload(file);
    }

    public upload(file: File): void {
        // Assign file options
        this.name = file.name;
        this.size = file.size;

        // Is file image
        this.isImage = IMAGE_TYPES.includes(file.type);

        console.info(file);
        console.info(this.isImage);

        // We nead reader to read a file
        let  reader = new FileReader();
        reader.addEventListener("load", (e: ProgressEvent<FileReader>): void => {
            // Add Base64 image data as input value
            this.options.value = e.target.result.toString();

            // Redraw
            this.update();
        });

        // Read file
        reader.readAsDataURL(file);
    }

    public select(): void {
        // Get input
        let input = this.query("input");

        // Force dialog to open
        input.click();
    }

    public clear(e: MouseEvent): void {
        // Stop propagation
        e.stopPropagation();
        e.preventDefault();

        // Reset options
        this.options.value = null;

        // Reset properties
        this.name = null;
        this.size = null;

        // Redraw
        this.update();
    }


    public setValue(value: any): void {
        // Sets value to options
        this.options.value = value;

        // Reattach select to render it properly
        if (this.isAttached()) {
            this.update();
        }
    }

}
