import "./hanoi-violation-data-form.scss";
import * as template from "./hanoi-violation-data-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { HanoiViolationDataFormOptions } from "./types";
import { TextInput } from "../../../../muklit/components/text-input/text-input";
import { Select } from "../../../../muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { VehicleRegistryDetail } from "../../traffic/vehicle-registry-detail/vehicle-registry-detail";
import { VehicleRegistry } from "../../traffic/vehicle-registry-detail/types";

export class HanoiViolationDataForm extends Detail<InvipoContext, HanoiViolationDataFormOptions> {

    // Components
    public form: Form;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: HanoiViolationDataFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
    }

    private createForm(): void {
        // Municipal level select
        let municipalLevel = new Select(this.context, {
            style: "Light",
            name: "municipalLevel",
            label: "forms.fields.municipalLevel",
            value: this.options.violation.extras?.municipalLevel,
            width: 184,
            items: (<any[]>(<any>this.context.config).hanoi.municipalities).map(x => {
                return {
                    name: x.code,
                    label: x.name
                }
            }),
            bright: true
        });

        // District level select
        let districtLevel = new Select(this.context, {
            style: "Light",
            name: "districtLevel",
            label: "forms.fields.districtLevel",
            value: this.options.violation.extras?.districtLevel,
            width: 184,
            items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("components.HanoiViolationDataForm.DistrictLevel")),
            bright: true
        });

        // District level select
        let communeLevel = new Select(this.context, {
            style: "Light",
            name: "communeLevel",
            label: "forms.fields.communeLevel",
            value: this.options.violation.extras?.communeLevel,
            width: 184,
            items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("components.HanoiViolationDataForm.DistrictLevel")),
            bright: true
        });

        // Notice input
        let notice = new TextInput(this.context, {
            style: "Light",
            name: "notice",
            label: "forms.fields.notice",
            value: this.options.violation.extras?.notice?.recipient,
            width: 584,
            bright: true
        });

        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "Row",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "lane",
                            label: "forms.fields.lane",
                            value: this.options.violation.data.incident.extras.lane,
                            width: 284,
                            required: true,
                            bright: true
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "color",
                            label: "forms.fields.color",
                            width: 284,
                            value: this.options.violation.data.incident.extras.color,
                            placeholderText: "forms.placeholders.all",
                            items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.VehicleColor")),
                            required: true,
                            bright: true
                        })
                    ]
                },
                {
                    name: "Row",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "plateNumber",
                            label: "forms.fields.plateNumber",
                            value: this.options.violation.data.incident.extras.plate.number,
                            width: 284,
                            placeholderText: "forms.placeholders.plateNumber",
                            bright: true
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "class",
                            label: "forms.fields.category",
                            value: String(this.options.violation.data.incident.extras.class),
                            width: 284,
                            placeholderText: "forms.placeholders.all",
                            items: InvipoHelpers.toCategoryItems(this.context.config.categories),
                            required: true,
                            bright: true
                        })
                    ]
                },
                {
                    name: "Row",
                    label: "components.HanoiViolationDataForm.sendNoticeTo",
                    fields: [
                        municipalLevel,
                        districtLevel,
                        communeLevel
                    ]
                },
                {
                    name: "Row",
                    fields: [
                        notice
                    ]
                },
            ]
        });

        // Set district level to choose
        municipalLevel.onSubmit = () => {
            // Get municipalities
            let municipalities = (<any[]>(<any>this.context.config).hanoi.municipalities);

            // Find muncinipality
            let municipality = municipalities.find(x => x.code == Object.keys(municipalLevel.options.value)[0]);

            // Assign items
            districtLevel.options.items = (<any[]>municipality.districts).map(x => {
                return {
                    name: x.code,
                    label: x.name
                }
            });

            // Redraw distirct
            districtLevel.setValue(null);
            districtLevel.invalidate();

            // Celar commune
            communeLevel.options.items = [];
            communeLevel.setValue(null);
            communeLevel.invalidate();

            // Set notice
            notice.setValue(`Phòng Cảnh sát giao thông Công an ${Object.values(municipalLevel.options.value)[0]}`);
        }

        // Set commune level to choose
        districtLevel.onSubmit = () => {
            // Get municipalities
            let municipalities = (<any[]>(<any>this.context.config).hanoi.municipalities);

            // Find muncinipality
            let municipality = municipalities.find(x => x.code == Object.keys(municipalLevel.options.value)[0]);

            // Find district
            let district = (<any[]>municipality.districts).find(x => x.code == Object.keys(districtLevel.options.value)[0]);

            // Assign items
            communeLevel.options.items = (<any[]>district.communes).map(x => {
                return {
                    name: x.code,
                    label: x.name
                }
            });

            // Redraw commune
            communeLevel.setValue(null);
            communeLevel.invalidate();

            // Set notice
            notice.setValue(`Đội Cảnh sát giao thông, Trật tự công an ${Object.values(municipalLevel.options.value)[0]}, ${Object.values(districtLevel.options.value)[0]}`);
        }

        // Set notice
        communeLevel.onSubmit = () => {
            // Set notice
            notice.setValue(`Công an ${Object.values(municipalLevel.options.value)[0]}, ${Object.values(districtLevel.options.value)[0]}, ${Object.values(communeLevel.options.value)[0]}`);
        }
    }

    public openRegistry(): void {
        // Detail form
        let detail = new VehicleRegistryDetail(this.context, {
            style: "Light",
            title: null,
            overlay: true,
            closable: true,
            copiable: true,
            lpn: this.options.violation.data.incident.extras.plate.number
        });

        // Copy registry info
        detail.onCopy = (registry: VehicleRegistry) => {
            this.form.setValue("color", registry.vehicle?.color);
        }

        // Show form
        detail.attach();
    }

    public async submit(): Promise<void> {
        // Basic form validation?
        if (!this.form.validate()) {
            return;
        }

        // Get form data
        let data = this.form.getData(true);

        // Show loader
        this.showLoader();

        // Create new user with two form merged together
        try {
            // Update incident properties
            this.options.violation.data.incident.extras.lane = data.lane;
            this.options.violation.data.incident.extras.color = data.color;
            this.options.violation.data.incident.extras.plate.number = data.plateNumber;
            this.options.violation.data.incident.extras.class = Number(data.class);

            // Update extras properties
            this.options.violation.extras = this.options.violation.extras || {};
            this.options.violation.extras.notice = this.options.violation.extras.notice || {};
            this.options.violation.extras.notice.recipient = data.notice;

            // Update data
            await this.context.invipo.putManagement(`violations/${this.options.violation.id}/data`, {
                data: this.options.violation.data
            });

            // Update extras
            await this.context.invipo.putManagement(`violations/${this.options.violation.id}/extras`, {
                extras: this.options.violation.extras
            });
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnNotificationSubmit handler
        this.onSubmit();
    }

}
