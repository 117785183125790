import "./ashghal-violation-reason-form.scss";
import * as template from "./ashghal-violation-reason-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { TextArea } from "muklit/components/text-area/text-area";
import { AshghalViolationReasonFormOptions } from "./types";
import { COLUMN_UNSUBSTANTIATED } from "../ashghal-violation-browser/ashghal-violation-browser";

export class AshghalViolationReasonForm extends Detail<InvipoContext, AshghalViolationReasonFormOptions> {

    // Components
    public form: Form;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: AshghalViolationReasonFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
    }

    private createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new TextArea(this.context, {
                            style: "Light",
                            name: "reason",
                            label: "forms.fields.reason",
                            width: 540,
                            rows: 12,
                            autofocus: true,
                            required: true,
                            bright: true
                        })
                    ]
                }
            ]
        });
    }

    public async submit(): Promise<void> {
        // Basic form validation?
        if (!this.form.validate()) {
            return;
        }

        // Get form data
        let data = this.form.getData(true);

        // Show loader
        this.showLoader();

        // Create new user with two form merged together
        try {
            // Itterate all selected violations
            for (let id of this.options.violations) {
                // Get violation
                let vioaltion = await this.context.invipo.getManagement(`violations/${id}`);

                // Set reason
                await this.context.invipo.putManagement(`violations/${id}/extras`, {
                    extras: {
                        ...vioaltion.extras,
                        reason: data.reason
                    }
                });

                // Update column
                await this.context.invipo.putManagement(`violations/${id}/workflow/column`, {
                    column: COLUMN_UNSUBSTANTIATED
                });
            }
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnNotificationSubmit handler
        this.onSubmit();
    }

}
