// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.invipo-item-tooltip {
  max-width: 240px;
}`, "",{"version":3,"sources":["webpack://./src/invipo/components/devices/item-tooltip/item-tooltip.scss"],"names":[],"mappings":"AAIA;EACE,gBAAA;AAHF","sourcesContent":["@import \"../../../styles/colors\";\r\n@import \"~muklit/styles/mixins\";\r\n@import \"~muklit/styles/colors\";\r\n\r\ndiv.invipo-item-tooltip {\r\n  max-width: 240px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
