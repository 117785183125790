import { Http, HttpMethod } from "./http";
import { Log } from "./log";
import { Language, STORAGE_LANG_ID } from "./locale-manager";
import Convert = require("ansi-to-html");

export const LOCALE = <Language>localStorage.getItem(STORAGE_LANG_ID) || navigator.language;

export type PropertyResolver = (data: any) => any;

export class Helpers {

    public static id = 0;

    public static reloadWindow(): void {
        let url = new URL(window.location.href);
        url.searchParams.set("reload", new Date().toISOString());

        window.location.reload();
    }

    public static getProperty(data: any, name: string | PropertyResolver): any {
        if (name == null) {
            return null;
        }
        else if (typeof name == "function") {
            return name(data);
        }
        else {
            let keys = name.split(".");

            // Search nested properties
            for (let key of keys) {
                data = data[key];

                // Null means data has no property with key name
                if (data == null) {
                    return null
                }
            }

            return data;
        }
    }

    public static removeEmpty(data: any): any {
        // Result object
        const result: any = {};

        // Go through all values
        Object.keys(data).forEach(key => {
            // If value is object and not array then do recursion
            if (data[key] && !data[key]?.length && typeof data[key] == "object") {
                console.info(`${key}=`);
                result[key] = Helpers.removeEmpty(data[key]);
            }
            // Assign flat value
            else if (data[key] != null) {
                result[key] = data[key];
            }
        });

        return result;
    }

    public static async sleep(milliseconds: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    public static newUid(prefix: object | string): string {
        return  (typeof prefix == "string" ? prefix : prefix.constructor.name) + "#" + ++Helpers.id;
    }

    public static createUuid(): string {
        let dt = Date.now();

        // Custom replacer
        let replacer = (c: string) => {
            let r = (dt + Math.random() * 16) %16 | 0;
            dt = Math.floor(dt / 16);
            return (c == "x" ? r : (r&0x3 | 0x8)).toString(16);
        }

        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, replacer);
    }

    // Remove all null properties
    public static removeNulls(o: any): void {
        for (let key of Object.keys(o)) {
            // Null value?
            if (o[key] == null) {
                delete o[key];
            }
        }
    }

    // KebabCase -> kebab-case
    public static toKebabCase(s: String) {
        if (!s) {
            return null;
        }
        return s.replace(/([a-zA-Z])(?=[A-Z])/g, "$1-").toLowerCase();
    }

    public static toCamelCase(s: string) {
        return s.replace(/(?:^\w|[A-Z]|\b\w)/g,(word: string, index: any) => {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    public static toDateString(t: string | Date | number): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleDateString(LOCALE, { day: "numeric", month: "numeric", year: "numeric" });
    }

    public static toShortDateString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleDateString(LOCALE, { day: "numeric", month: "numeric" });
    }

    public static toShortIsoDateString(t: string | Date): string {
        if (!t) {
            return null;
        }

        return new Date(t).toISOString().substring(0, 10);
    }

    public static toHour(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).getHours().toString();
    }

    public static toMinute(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).getMinutes().toString();
    }

    public static toYear(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleDateString(LOCALE, { year: "numeric" });
    }

    public static toMonthString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleDateString(LOCALE, { month: "long" });
    }

    public static toShortMonthString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleDateString(LOCALE, { month: "short" });
    }

    public static toWeekDayString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleDateString(LOCALE, { weekday: "long" });
    }

    public static toShortWeekDayString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleDateString(LOCALE, { weekday: "short" });
    }

    public static toTimeString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleTimeString(LOCALE, { hour: "2-digit", minute: "2-digit", second: "2-digit" });
    }

    public static toShortTimeString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleTimeString(LOCALE, { hour: "2-digit", minute: "2-digit" });
    }

    public static toDateTimeString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return Helpers.toDateString(t) + " " + Helpers.toTimeString(t);
    }

    public static toShortDateTimeString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return Helpers.toShortDateString(t) + " " + Helpers.toShortTimeString(t);
    }

    public static toLocalIsoDate(t: string | Date): string {
        if (!t) {
            return null;
        }

        let date = new Date(t);

        // Shift to local time
        date = new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));

        return date.toISOString().substring(0, 16);
    }

    public static toLocalIsoTime(t: string | Date): string {
        if (!t) {
            return null;
        }

        let date = new Date(t);

        // Shift to local time
        date = new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));

        return date.toISOString().substring(11, 16);
    }

    public static toNumber(t: any, maxFractionDigits: number = 0, minFractionDigits: number = 0): string {
        if (!t && t != 0) {
            return null;
        }
        return Number(t).toLocaleString(LOCALE, {
            minimumFractionDigits: minFractionDigits,
            maximumFractionDigits: maxFractionDigits
        });
    }

    public static toMb(t: any): string {
        if (t == null) {
            return null;
        }
        return Number(parseInt(t) / 1024 / 1024).toLocaleString(LOCALE, { maximumFractionDigits: 1 }) + " MB";
    }

    public static toGb(t: any): string {
        if (t == null) {
            return null;
        }
        return Number(parseInt(t) / 1024 / 1024 / 1024).toLocaleString(LOCALE, { maximumFractionDigits: 1 }) + " GB";
    }

    public static toVph(value: number, interval: number = 60): string {
        if (value == null) {
            return null;
        }
        return Number(Math.round(value * (60 / interval))).toLocaleString(LOCALE);
    }

    public static toKwh(value: number, interval: number = 60): string {
        if (value == null) {
            return null;
        }
        return Number(value * (60 / interval)).toLocaleString(LOCALE);
    }

    public static toKm(value: number): string {
        if (value == null) {
            return null;
        }
        return Number(value / 1000).toLocaleString(LOCALE, { maximumFractionDigits: 1 }) + " km";
    }

    public static toDuration(t: number): string {
        if (t == null) {
            return null;
        }

        // Duration string
        let duration = "";

        // Miliseconds
        if (t < 1) {
            duration += "<1s";
        }

        // Seconds
        if (Math.floor(t % 60) > 0) {
            duration += Math.floor(t % 60) + "s "
        }

        // Minutes
        if (t >= 60 && Math.floor((t / 60) % 60) > 0) {
            duration = Math.floor((t / 60) % 60) + "m " + duration;
        }

        // Hours
        if (t >= 3600 && Math.floor((t / 3600) % 24) > 0) {
            duration = Math.floor((t / 3600) % 24) + "h " + duration;
        }

        // Days
        if (t >= 86400) {
            duration = Math.floor(t / 86400) + "d " + duration;
        }

        return duration.trim();
    }

    public static toShortDuration(t: number): string {
        if (t == null) {
            return null;
        }

        let duration = "";

        // Hours
        if (t >= 3600 && Math.floor((t / 3600) % 24) > 0) {
            duration = Math.floor((t / 3600) % 24) + "h " + duration;
        }

        // Days
        if (t >= 86400) {
            duration = Math.floor(t / 86400) + "d " + duration;
        }

        return duration.trim();
    }

    public static toAge(t: string | Date): number {
        // Rounding makes older
        // return Math.ceil((new Date().getTime() - new Date(t).getTime()) / 1000);
        return (new Date().getTime() - new Date(t).getTime()) / 1000;
    }

    public static toCommaKeys(o: any): string {
        let result = "";

        // Join values into inline string
        for (let key of Object.keys(o)) {

            // Key1,Key2, ...
            if (result) {
                result += ",";
            }

            // Add string
            result += key;
        }

        return result;
    }

    public static toLogLine(l: string): string {
        // Timestamp and message to be displayed in different colors
        let timestamp = l.substr(0, 30);
        let message = l.substr(31);

        return `<span class="timestamp">${timestamp}</span> <span class="message">${new Convert().toHtml(message)}</span><br />`;
    }

    public static toString(o: any): string {
        let result = "";

        for (let key of Object.keys(o)) {
            if (o[key] != undefined) {
                result += `${key}=${o[key]}, `;
            }
        }

        // Remove last ", " characters
        return result.slice(0, -2);
    }

    public static async loadJson<T>(path: string): Promise<T> {
        let http = new Http();

        Log.i(`Loading JSON from ${path}`);

        // Load JSON via HTTP
        return await http.request(HttpMethod.GET, path);
    }

    public static format(s: string, ...args: any[]): string {
        if (typeof s != "string") {
            // To prevent send object as argument instead of string
            Log.w(`String expected in format() instead of ${typeof s}`);
            return null;
        }

        return s.replace(/{(\d+)}/g, (match: string, number) => {
            return typeof args[number] != "undefined"
                ? args[number]
                : match
                ;
        });
    }

    public static format2(s: string, args: any): string {
        return s.replace(/{(\w+)}/g,(match: string, name: string) => {
            return args && (args[name] ?? match);
        });
    }

    public static trim(values: string[]): string[] {
        for (let i = 0; i < values.length; i++) {
            values[i] = values[i].trim();
        }

        return values;
    }

    public static contains(text: string, term: string): boolean {
        // Empty text?
        if (!text) {
            return false;
        }

        // Remove accents/diacritics
        text = text.normalize("NFD").replace(/\p{Diacritic}/gu, "");
        term = term.normalize("NFD").replace(/\p{Diacritic}/gu, "");

        // Case insensitive result
        return text.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1;
    }

    public static lerp(value1: number, value2: number, amount: number): number {
        return value1 * (1 - amount) + value2 * amount;
    }

    public static invlerp(value1: number, value2: number, amount: number): number {
        return this.clamp((amount - value1) / (value2 - value1));
    }

    public static clamp(value: number, min: number = 0, max: number = 1): number {
        return Math.min(max, Math.max(min, value));
    }

    public static range(x1: number, y1: number, x2: number, y2: number, amount: number) {
        return this.lerp(x1, y1, this.invlerp(x2, y2, amount));
    }

}
