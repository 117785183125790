import "./environmental-sensor-overview.scss";
import * as template from "./environmental-sensor-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { EnvironmentalMetrics, EnvironmentalSensorOverviewOptions } from "./types";
import { Helpers } from "hiyo/helpers";
import { METRICS } from "../../city/city-subdomain/types";
import { InvipoHelpers } from "../../../invipo-helpers";
import { Log } from "../../../../hiyo/log";
import { Panel } from "../../common/panel/panel";

export class EnvironmentalSensorOverview extends Panel<EnvironmentalSensorOverviewOptions> {

    // Properties
    public metrics: EnvironmentalMetrics[];

    constructor(context: InvipoContext, options?: EnvironmentalSensorOverviewOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load item history data
        let from = new Date(new Date(Date.now() - 48 * 60 * 60 * 1000).setMinutes(0, 0, 0));
        let to = new Date(new Date().setMinutes(0, 0, 0));
        let history = await this.context.invipo.getDataset("items-history", `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Clear metrics
        this.metrics = [];

        // Build all chart data from all envi blocks and properties into one history object
        for (let m of ["quality", "air", "pollution", "noise"]) {
            for (let d of (Object.keys(METRICS.envi[m]))) {
                // Metrics data is not enabled in config
                if (!InvipoHelpers.hasData(this.context.config.data, `${m}.${d}`)) {
                    Log.w(`EnvironmentalSenosrOverivew: ${m}.${d} not configured`);
                    continue;
                }

                // Calculate extrems
                let highest = Math.max(...history.data.map(x => x.item?.data?.envi[m] && x.item.data.envi[m][d]));
                let lowest = Math.min(...history.data.map(x => x.item?.data?.envi[m] && x.item.data.envi[m][d]));

                // Current metrics
                let metrics: EnvironmentalMetrics = {
                    kpis: {
                        label: `components.EnvironmentalSensorCard.labels.${m}.${d}`,
                        size: "Third",
                        tight: true,
                        data: [
                            {
                                label: "components.EnvironmentalSensorOverview.current",
                                value: (this.item.data?.envi[m] && this.item.data?.envi[m][d]) ? `${Helpers.toNumber(this.item.data?.envi[m][d], 1)} ${METRICS.envi[m][d].unit}` : "common.unknown",
                                description: (this.item.data?.envi[m] && this.item.data?.envi[m][d]) ? Helpers.toDateTimeString(this.item.data?.envi?.timestamp) : ""
                            },
                            {
                                label: "components.EnvironmentalSensorOverview.lowest",
                                value: lowest ? `${Helpers.toNumber(lowest, 1)} ${METRICS.envi[m][d].unit}` : "common.unknown",
                                description: lowest ? Helpers.toDateTimeString(history.data.find(x => (x.item?.data?.envi[m] && x.item.data.envi[m][d] && x.item.data.envi[m][d]) == lowest)?.timestamp) : ""
                            },
                            {
                                label: "components.EnvironmentalSensorOverview.highest",
                                value: highest ? `${Helpers.toNumber(highest, 1)} ${METRICS.envi[m][d].unit}` : "common.unknown",
                                description: highest ? Helpers.toDateTimeString(history.data.find(x => (x.item?.data?.envi[m] && x.item.data.envi[m][d] && x.item.data.envi[m][d]) == highest)?.timestamp) : ""
                            }
                        ]
                    },
                    chart: {
                        type: "Bar",
                        size: "Small",
                        length: 48,
                        series: []
                    }
                }

                // Add hourly data to chart series
                let timestamp = new Date(from);
                do {
                    // Find hour in data
                    let data = history.data.find(x => x.timestamp == timestamp.toISOString());

                    // Has data?
                    if (data?.item?.data?.envi && data.item.data.envi[m] && data.item.data.envi[m][d]) {
                        metrics.chart.series.push(
                            [
                                {
                                    timestamp: timestamp.toISOString(),
                                    valueX: Helpers.toShortDateTimeString(timestamp.toISOString()),
                                    label: `${Helpers.toNumber(data.item.data.envi[m][d], 1)} ${METRICS.envi[m][d].unit}`,
                                    percent: Helpers.range(0, 100, METRICS.envi[m][d].range[0], METRICS.envi[m][d].range[1], data.item.data.envi[m][d]),
                                    color: METRICS.envi[m][d].colors[Math.round(Helpers.range(0,METRICS.envi[m][d].colors.length - 1, METRICS.envi[m][d].range[0], METRICS.envi[m][d].range[1], data.item.data.envi[m][d]))],
                                }
                            ]
                        );
                    }
                    // No data
                    else {
                        metrics.chart.series.push(
                            [
                                {
                                    timestamp: timestamp.toISOString(),
                                }
                            ]
                        );
                    }

                    // Move to next hour
                    timestamp.setTime(timestamp.getTime() + 3600000);
                }
                while (timestamp.getTime() < to.getTime())

                // Custom range
                metrics.chart.range = [
                    {
                        label: Helpers.toShortDateTimeString(from)
                    },
                    {
                        label: Helpers.toShortDateTimeString(new Date(from.getTime() + (to.getTime() - from.getTime()) / 2))
                    },
                    {
                        label: Helpers.toShortDateTimeString(to)
                    }
                ];

                // Push metrics to array
                this.metrics.push(metrics);
            }
        }
    }
}
