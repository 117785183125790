import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION, ZOOM_AREA } from "muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { Helpers } from "hiyo/helpers";
import { METRICS } from "../../components/city/city-subdomain/types";
import { DEFAULT_LANE_CAPACITY } from "../../components/traffic/traffic-counting-subdomain/traffic-counting-subdomain";

export class TrafficVolumeSegmentLineLayer extends MapLayer<InvipoContext> {

    // Properties
    public itemId: string;

    public constructor(context: InvipoContext, itemId?: string) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            before: "road-label",
            card: "TrafficVolumeCard",
            detail: "CityDetail",
            layer: {
                id: "traffic-volume-segment-line",
                type: "line",
                minzoom: ZOOM_AREA,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "line-width": ["get", "width"],
                    "line-offset": ["get", "offset"],
                    "line-color": ["get", "color"],
                    "line-opacity": 0,
                    "line-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "line-opacity": 1
            }
        });

        // Assign properties
        this.itemId = itemId;
    }

    public async load(): Promise<any> {
        // Load items
        let items = this.context.data.getItems({
            id: this.itemId,
            class: "TrafficCounter,WimStation",
            features: "TrafficCounting"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // No segments defined
            if (!item.meta?.segments?.length) {
                continue;
            }

            // Iterate all segments
            for (let segment of item.meta.segments) {
                // Total volume, null means no data
                let volume = null;

                // Volume color, default is no data color
                let color = "#aaaaaa";

                // Find data for segment
                let data = (<any[]>item.data?.traffic)?.find(x => x.segment == segment.name);

                // Segment has data?
                if (data) {
                    // Calcualte segment volume as percentage of capacity
                    volume = (data.count || 0) * (60 / this.context.config.aggregations.traffic.interval) / (segment?.capacity || DEFAULT_LANE_CAPACITY) * 100;

                    // Color calculation from range
                    let range = Helpers.range(0, METRICS.traffic.counting.volume.colors.length - 1, METRICS.traffic.counting.volume.range[0], METRICS.traffic.counting.volume.range[1], volume);
                    color = METRICS.traffic.counting.volume.colors[Math.round(range)];
                }

                json.features.push({
                    type: "Feature",
                    properties: {
                        //tooltip: `${item.name}\n${Helpers.toNumber(volume)} ${this.context.locale.getMessage(segment.type == "Bicycle" ? "units.bph" : "units.vph")}`,
                        card: this.options.card,
                        detail: this.options.detail,
                        cardId: `${item.id}-${segment.name}`,
                        itemId: item.id,
                        itemName: item.name,
                        itemClass: item.class,
                        itemModel: item.model,
                        segment: segment.name,
                        width: (volume != null && segment.type != "Bicycle" && segment.type != "Pedestrian") ? 8 : 4,
                        offset: segment.offset || 0,
                        color: color
                    },
                    geometry: segment.segment
                });
            }
        }

        return json;
    }


}
