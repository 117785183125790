// Localization import
import * as messagesEn from "./assets/messages/messages.en.json";
import * as messagesDe from "./assets/messages/messages.de.json";
import * as messagesCs from "./assets/messages/messages.cs.json";
import * as messagesDa from "./assets/messages/messages.da.json";
import * as messagesEl from "./assets/messages/messages.el.json";
import * as messagesVi from "./assets/messages/messages.vi.json";
import * as messagesEs from "./assets/messages/messages.es.json";

// Module imports
import { InvipoApplication } from "./invipo-application";
import { InvipoContext } from "./context/invipo-context";
import { Helpers } from "hiyo/helpers";
import { Log } from "../hiyo/log";
import { InvipoContextOptions } from "./context/types";

// Async wrapper
(async () => {

    // Get frontend version from package.json
    let pjson = require("../../package.json");
    Log.i(`Frontend version=${pjson.version}`);

    // Self url
    let url = new URL(window.location.href);
    let conf = url.searchParams.get("conf");

    // Project config;
    let config: InvipoContextOptions = null;

    // Custom project config requested? (dev)
    if (conf) {
        config = await Helpers.loadJson(`./conf/${conf}/invipo.json`);
    }
    // Sefaul invipo.json in root (production)
    else {
        // Load config from local invipo.json
        config = await Helpers.loadJson("./invipo.json");
    }

    // Invipo context
    const context = new InvipoContext({
        // Frontend version
        version: pjson.version,
        // Merge from config
        ...config,
        // Imported messages
        messages: {
            en: messagesEn,
            de: messagesDe,
            cs: messagesCs,
            da: messagesDa,
            el: messagesEl,
            vi: messagesVi,
            es: messagesEs
        }
    });

    // Run application
    await new InvipoApplication(context).preauthorize();

})();