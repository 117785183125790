// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.invipo-message-sign-card div.screens {
  padding: 0 24px 16px 24px;
}
div.invipo-message-sign-card div.screens div.screen {
  margin-bottom: 8px;
  border: 4px solid #161616;
}
div.invipo-message-sign-card div.screens div.screen img {
  display: block;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/invipo/components/traffic/message-sign-card/message-sign-card.scss"],"names":[],"mappings":"AAKE;EACE,yBAAA;AAJJ;AAMI;EACE,kBAAA;EACA,yBAAA;AAJN;AAMM;EACE,cAAA;EACA,WAAA;AAJR","sourcesContent":["@import \"~muklit/styles/colors\";\r\n@import \"~muklit/styles/mixins\";\r\n\r\ndiv.invipo-message-sign-card {\r\n\r\n  div.screens {\r\n    padding: 0 24px 16px 24px;\r\n\r\n    div.screen {\r\n      margin-bottom: 8px;\r\n      border: 4px solid $color-icon-01;\r\n\r\n      img {\r\n        display: block;\r\n        width: 100%;\r\n      }\r\n    }\r\n  }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
