import "./traffic-hourly-volume-report.scss";
import * as template from "./traffic-hourly-volume-report.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficHourlyVolumeReportOptions } from "./types";
import { Form } from "muklit/components/form/form";
import { ItemSelect } from "../../common/item-select/item-select";
import { Select } from "muklit/components/select/select";
import { Panel } from "../../common/panel/panel";
import { PanelChart, PanelChartData, PanelKpis, PanelProperties, PanelTable, PanelTableRow } from "../../common/panel/types";
import { Helpers } from "hiyo/helpers";
import { InvipoHelpers } from "../../../invipo-helpers";
import { ItemMap } from "../../items/item-map/item-map";
import { TrafficVolumeSegmentLineLayer } from "../../../layers/traffic/traffic-volume-segment-line-layer";
import { TrafficVolumeSegmentSymbolLayer } from "../../../layers/traffic/traffic-volume-segment-symbol-layer";
import { TrafficVolumeSegmentLabelLayer } from "../../../layers/traffic/traffic-volume-segment-label-layer";
import { RangeInput } from "muklit/components/range-input/range-input";
import { Log } from "hiyo/log";

export class TrafficHourlyVolumeReport extends Panel<TrafficHourlyVolumeReportOptions> {

    // Properties
    public volumes: PanelChart;
    public speeds: PanelChart;
    public properties: PanelProperties;
    public kpis: PanelKpis;
    public categories1: PanelChart;
    public categories2: PanelChart;
    public table: PanelTable;

    public constructor(context: InvipoContext, options?: TrafficHourlyVolumeReportOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
    }

    protected createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new RangeInput(this.context, {
                            style: "Light",
                            name: "interval",
                            type: "Range",
                            label: "forms.fields.date",
                            value: {
                                from: new Date(new Date().setHours(-24 * ((new Date().getDay() + 6) % 7), 0, 0, 0) - 604800000).toISOString(),
                                to: new Date(new Date().setHours(-24 * ((new Date().getDay() + 6) % 7), 0, 0, 0) - 1).toISOString(),
                                range: "LastWeek"
                            },
                            placeholderText: "forms.placeholders.anytime",
                            width: 320,
                            bright: true,
                            required: true
                        }),
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "itemId",
                            label: "forms.fields.item",
                            value: this.options.itemId,
                            placeholderText: "forms.placeholders.all",
                            distinct: "TrafficData",
                            items: [],
                            width: 320,
                            multiselect: true,
                            bright: true
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "segment",
                            label: "forms.fields.segment",
                            value: this.options.segment,
                            placeholderText: "forms.placeholders.all",
                            items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("TrafficData", "segment")),
                            width: 320,
                            multiselect: true,
                            bright: true
                        })
                    ]
                }
            ]
        });

        // Register component
        this.registerComponent(this.form, "form");
    }

    public openMap(): void {
        // Get form data
        let data = this.form.getData(true);

        // New image detail
        let form = new ItemMap(this.context, {
            style: "Light",
            title: "components.ItemGeometryForm.title",
            itemId: data.itemId,
            layers: [
                new TrafficVolumeSegmentLineLayer(this.context, data.itemId),
                new TrafficVolumeSegmentSymbolLayer(this.context, data.itemId),
                new TrafficVolumeSegmentLabelLayer(this.context, data.itemId)
            ],
            overlay: true,
            closable: true
        });

        // Show
        form.attach();
    }

    public async extraLoad(): Promise<void> {
        // Get simplified form data
        let form = this.form.getData(true);

        // Assign form data to panel search options
        this.options.search = this.form.getData();

        // Query string
        let query = "";

        if (form.itemId) {
            query += `&item.id=${form.itemId}`;
        }
        if (form.segment) {
            query += `&segment=${encodeURIComponent(form.segment)}`;
        }

        // Interval
        let from = new Date(new Date(form.from));
        let to = new Date(new Date(form.to));

        // Traffic data per hour
        let traffic = await this.context.invipo.getQuery("traffic-by-hour", `${query}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Build volume chart
        this.volumes = {
            type: "Bar",
            size: "Tall",
            length: 24,
            name: "Volume",
            label: "components.TrafficHourlyVolumeReport.volumeHours",
            series: []
        }

        // Get max count to adjust chart optimal height
        let maxVolume = Math.max(...traffic.map(x => x.count));
        let morningPeak = Math.max(...traffic.filter(x => new Date(x.timestamp).getHours() < 12).map(x => x.count));
        let afternoonPeak = Math.max(...traffic.filter(x => new Date(x.timestamp).getHours() >= 12).map(x => x.count));

        // Add hours chart data
        for (let h = 0; h < 24; h++) {
            // Find hour in data
            let d = traffic.find(x => new Date(x.timestamp).getHours() == h);

            // Has data?
            if (d) {
                this.volumes.series.push(
                    [
                        {
                            timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                            valueY: Helpers.toNumber(d.count),
                            valueX: h.toString().padStart(2, "0"),
                            percent: Helpers.range(0, 100, 0, maxVolume * 1.1, d.count),
                            label: `${Helpers.toNumber(d.count)} ${this.context.locale.getMessage("units.vehicles")}`,
                            color: InvipoHelpers.toChartColor(1)
                        }
                    ]
                );
            }
            // No data
            else {
                this.volumes.series.push(
                    [
                        {
                            timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                            valueX: h.toString().padStart(2, "0")
                        }
                    ]
                );
            }
        }

        // Volume KPIs
        this.kpis = {
            size: "Third",
            data: [
                {
                    label: "components.TrafficHourlyVolumeReport.total",
                    value: `${Helpers.toNumber(traffic.map(x => x.count).reduce((a, b) => { return a + b }, 0))} ${this.context.locale.getMessage(`units.vehicles`)}`,
                    description: Helpers.toDateString(from)
                },
                {
                    label: "components.TrafficHourlyVolumeReport.morningPeak",
                    value: Number.isInteger(morningPeak) ? `${Helpers.toNumber(morningPeak)} ${this.context.locale.getMessage(`units.vehicles`)}` : this.context.locale.getMessage("common.noData"),
                    description: Helpers.toShortTimeString(traffic.find(x => x.count == morningPeak)?.timestamp)
                },
                {
                    label: "components.TrafficHourlyVolumeReport.afternoonPeak",
                    value: Number.isInteger(afternoonPeak) ? `${Helpers.toNumber(afternoonPeak)} ${this.context.locale.getMessage(`units.vehicles`)}` : this.context.locale.getMessage("common.noData"),
                    description: Helpers.toShortTimeString(traffic.find(x => x.count == afternoonPeak)?.timestamp)
                }
            ]
        }

        // Build speed chart if speed data available
        this.speeds = {
            type: "Bar",
            size: "Medium",
            length: 24,
            name: "Speed",
            label: "components.TrafficHourlyVolumeReport.speedHours",
            series: []
        }

        // Add hourly data to chart series
        for (let h = 0; h < 24; h++) {
            // Find hour in data
            let d = traffic.find(x => new Date(x.timestamp).getHours() == h);

            // Has data?
            if (d) {
                this.speeds.series.push(
                    [
                        {
                            timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                            valueY: d.speed,
                            valueX: h.toString().padStart(2, "0"),
                            percent: Helpers.range(0, 100, 0, 130, d.speed),
                            label: `${Helpers.toNumber(d.speed)} ${this.context.locale.getMessage("units.kmph")}`,
                            color: InvipoHelpers.toChartColor(1),
                        }
                    ]
                );
            }
            // No data
            else {
                this.speeds.series.push(
                    [
                        {
                            timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                            valueX: h.toString().padStart(2, "0")
                        }
                    ]
                );
            }
        }

        // Build category charts only if we have classification enabled
        if (this.context.config.categories?.length) {
            // Build categories stacked chart
            this.categories1 = {
                type: "Bar",
                size: "Medium",
                length: 24,
                series: [],
                legend: []
            }

            // Add categories chart data
            for (let h = 0; h < 24; h++) {
                // Find hour in data
                let d = traffic.find(x => new Date(x.timestamp).getHours() == h);

                // Has data and has categories defined?
                if (d && this.context.config.categories?.length) {
                    // Define series
                    let series: PanelChartData[] = [];

                    // Get series for all categories
                    for (let c of d.categories) {
                        // Find category defintion
                        let category = this.context.config.categories.find(x => x.id == c.id);

                        // No category found?
                        if (!category) {
                            Log.w(`No vehicle category found (${c.id})`);
                            continue;
                        }

                        // Push series data
                        series.push({
                            timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                            valueX: h.toString().padStart(2, "0"),
                            percent: c.count / d.count * 100,
                            label: `${category.name}<br />${Helpers.toNumber(d.count)} ${this.context.locale.getMessage("units.vehicles")}`,
                            color: category.color
                        });
                    }

                    // Add all series
                    this.categories1.series.push(series);
                }
                // No data
                else {
                    this.categories1.series.push(
                        [
                            {
                                timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                                valueX: h.toString().padStart(2, "0")
                            }
                        ]
                    );
                }
            }

            // Add categories legend
            for (let category of this.context.config.categories) {
                this.categories1.legend.push({
                    label: category.name,
                    color: category.color
                });
            }

            // Build categories volume chart
            this.categories2 = {
                type: "Bar",
                size: "Medium",
                name: "Category",
                label: "components.TrafficHourlyVolumeReport.categories",
                series: [],
                legend: []
            }

            // Flatten category counts
            let data = [];

            for (let category of this.context.config.categories) {
                data.push({
                    id: category.id,
                    count: traffic.map(x => (<any[]>x.categories).find(y => y.id == category.id)?.count || 0).reduce((a, b) => {
                        return a + b
                    }, 0)
                });
            }

            // Find maximum count
            let maxCount = Math.max(...data.map(x => x.count));

            // Add categories chart data
            for (let category of this.context.config.categories) {
                // Get total count per each category
                let count = data.find(x => x.id == category.id)?.count || 0

                // Add series to chart
                this.categories2.series.push(
                    [
                        {
                            timestamp: new Date().toISOString(),
                            valueY: Helpers.toNumber(data.find(x => x.id == category.id)?.count || 0),
                            percent: Helpers.range(0, 100, 0, maxCount * 1.2, count),
                            label: `${Helpers.toNumber(count)} ${this.context.locale.getMessage("units.vehicles")}`,
                            valueX: category.name,
                            color: InvipoHelpers.toChartColor(1)//category.color
                        }
                    ]
                );
            }

            // Sort categories
            this.categories2.series.sort((a, b) => {
                return b[0].percent - a[0].percent;
            })
        }

        // Build categories table
        this.table = {
            name: "Table",
            label: "components.TrafficHourlyVolumeReport.table",
            columns: [],
            rows: []
        };

        // Calcualte column width
        let width = Math.min(76, Math.round(720 / (this.context.config.categories.length + 2) * 100) / 100);

        // Create lane column
        this.table.columns.push({
            style: "Label",
            label: "tables.columns.hour",
            width: `99%`
        });

        // Create category columns
        for (let c of this.context.config.categories) {
            this.table.columns.push({
                label: c.name,
                align: "Center",
                width: `${width}px`
            });
        }

        // Create count column
        this.table.columns.push({
            label: "tables.columns.total",
            style: "Bold",
            align: "Center",
            width: `${width}px`
        });

        // Add volume values
        for (let h = 0; h < 24; h++) {
            // Table row
            let row: PanelTableRow = {
                cells: []
            };

            // Put arm name
            row.cells.push(`${h.toString().padStart(2, "0")}:00`);

            // volume
            let volume = null;

            // Hour data
            volume = traffic.find(x => new Date(x.timestamp).getHours() == h);

            // No data?
            if (!volume) {
                continue;
            }

            // Get categories count
            for (let c of this.context.config.categories) {
                // Get count
                let count = (<any[]>volume?.categories)?.find(x => x.id == c.id)?.count;

                // Push number or empty value
                row.cells.push((count != null) ? Helpers.toNumber(count) : "");
            }

            // Add total count
            row.cells.push(Helpers.toNumber(volume?.count));

            // Add table row
            this.table.rows.push(row);
        }

    }
}
