import "./travel-time-route-manager.scss";
import * as template from "./travel-time-route-manager.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterItem } from "muklit/components/filter/types";
import { TravelTimeRouteManagerOptions } from "./types";
import { TemplateManagerOptions } from "../../settings/template-manager/types";
import { Search } from "../../../../muklit/components/search/search";
import { TemplateForm } from "../../settings/template-form/template-form";
import { TravelTimeRouteDetailOptions } from "../travel-time-route-detail/types";
import { TravelTimeRouteDetail } from "../travel-time-route-detail/travel-time-route-detail";
import { ItemSelect } from "../../common/item-select/item-select";
import { Helpers } from "../../../../hiyo/helpers";

export class TravelTimeRouteManager extends MuklitComponent<InvipoContext, TravelTimeRouteManagerOptions> {

    // Components
    public table: FilterTable;
    public detail: TravelTimeRouteDetail;

    constructor(context: InvipoContext, options?: TemplateManagerOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    private createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/travel-time-routes`,
            http: this.context.invipo.http,
            filter: {
                title: "components.TravelTimeRouteManager.title",
                items: [
                    {
                        name: "Map",
                        label: "labels.map"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ],
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "route.origin.id",
                                label: "forms.fields.origin",
                                placeholderText: "forms.placeholders.all",
                                distinct: "TravelTimeFeedsRoutes.origins",
                                items: [],
                                multiselect: true
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "route.destination.id",
                                label: "forms.fields.destination",
                                placeholderText: "forms.placeholders.all",
                                distinct: "TravelTimeFeedsRoutes.destinations",
                                items: [],
                                multiselect: true
                            })
                        ]
                    },
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                style: "Light",
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id",
                    decorator: (data: any): string => {
                        return data.disabled ? "disabled" : null;
                    }
                },
                columns: [
                    {
                        name: "origin.name",
                        type: "String",
                        property: "origin.name",
                        label: "tables.columns.origin",
                        width: 160,
                        ellipsis: true,
                        sortable: true
                    },
                    {
                        name: "destination.name",
                        type: "String",
                        property: "destination.name",
                        label: "tables.columns.destination",
                        width: 160,
                        ellipsis: true,
                        sortable: true
                    },
                    {
                        name: "length",
                        type: "Number",
                        property: "length",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toKm(value)}</div>`
                        },

                        label: "tables.columns.length",
                        align: "Center",
                        width: 100,
                        sortable: true
                    },
                    {
                        name: "name",
                        type: "String",
                        property: "name",
                        label: "tables.columns.name",
                        minWidth: 160,
                        sortable: true,
                        selected: true
                    }
                ]
            }
        });

        // Handle menu
        this.table.onItemSelect = async (item: FilterItem) => {
            // Add new user?
            if (item.name == "Add") {
                await this.openNew();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    private async openDetail(data: any): Promise<void> {
        // Detail options
        let options: TravelTimeRouteDetailOptions = {
            style: "Light",
            title: data.name,
            subtitle: data.email,
            routeId: data.id,
            closable: true,
            menu: [
                {
                    name: "Delete",
                    label: "labels.delete",
                    escalated: true
                }
            ]
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = {
                ...this.detail.options,
                ...options
            };

            // Force reload
            await this.detail.load();

            // Not continue
            return;
        }

        // New detail
        this.detail = new TravelTimeRouteDetail(this.context, options);

        // Menu select
        this.detail.onMenuSelect = (item: MenuItem)=> {
            // Delete action?
            if (item.name == "Delete") {
                this.detail.delete();
            }
        }

        // Refresh table on detail update
        this.detail.onDetailUpdate = async () => {
            await this.table.load();
        }

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.routeId);
            this.detail = null;
        }

        // Attach detail and redraw map because of viewport changed
        this.detail.attach();
    }

    public openNew(): void {
        // Details form
        let form = new TemplateForm(this.context, {
            style: "Light",
            title: "components.TemplateForm.title",
            template: null,
            overlay: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload data
            await this.table.load();
        }

        // Show form
        form.attach();
    }

}
