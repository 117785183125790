import "./draw-map.scss";
import * as template from "./draw-map.hbs";
import "mapbox-gl/dist/mapbox-gl.css";
import * as mapbox from "mapbox-gl";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { Context } from "hiyo/context";
import { Component } from "hiyo/component";
import { LineString, Position, Point, Feature, GeometryObject, Polygon } from "geojson";
import MapboxDraw = require("@mapbox/mapbox-gl-draw");
import { Log } from "hiyo/log";
import { DrawMapOptions } from "./types";
import { MapFlyToOptions } from "../basic-map/types";
import { ItemClassCircleLayer } from "../../../invipo/layers/infrastructure/item-class-circle-layer";
import { GeoJSONSource } from "mapbox-gl";

const MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoiaW5jaW5pdHkiLCJhIjoiY2thbDZ0N3g4MG5xcTJybzZnMHNxcm51NiJ9.AIdB5NT1kyQmlur8EKEm0Q";

const MAP_STYLES: { [style: string]: string } = {
    "Light": "mapbox://styles/incinity/ckov73uh701id17qns6etcljs?fresh=true",
    "Dark": "mapbox://styles/incinity/ckovf52b809bi17mh21tdc0d2?fresh=true",
    "Satellite": "mapbox://styles/incinity/ckwcb0kwp0tsp14l3yzzrq0p9?fresh=true",
    "Czechia": "mapbox://styles/incinity/cjzo45jtw0v9o1doa2soxgdg2?fresh=true",
    "HradecKraloveOrthophoto": "mapbox://styles/incinity/ckpcd3bt646nm17tpzsnhqa3y?fresh=true",
    "GoogleOrthophoto": "mapbox://styles/incinity/clgyvtatv00gs01pna2o5glix?fresh=true"
}

export class DrawMap extends Component<Context, DrawMapOptions> {

    // Properties
    //public features: Feature[] = [];
    public mapboxMap: mapbox.Map;
    public mapboxDraw: MapboxDraw;
    public mapboxLoaded: boolean;

    // Event handling methods
    public onMapLoad(): void {};
    public onMapZoom(zoom: number): void {};
    public onMapMove(center: Position): void {};
    public onFeatureSelect(feature: Feature): void {};
    public onFeatureCreate(feature: Feature): void {};
    public onFeatureDelete(feature: Feature): void {};
    public onFeatureUpdate(feature: Feature): void {};
    public onFeaturesUpdate(features: Feature[]): void {};

    constructor(context: Context, options: DrawMapOptions) {
        super(context, template, options);
    }

    public onAttach(): void {
        // Need to put valid token here
        (<any>mapbox).accessToken = MAPBOX_ACCESS_TOKEN;

        // New map instance
        this.mapboxMap = new mapbox.Map({
            container: this.element,
            style: MAP_STYLES[this.options.style],
            center: this.options.center ? [this.options.center[0], this.options.center[1]] : [0, 0],
            zoom: this.options.zoom || 10,
            minZoom: this.options.minZoom || 0,
            maxZoom: this.options.maxZoom || 20,
            maxBounds: this.options.maxBounds || null,
            pitch: this.options.pitch || 0
        });

        // Mapbox draw plugin
        this.mapboxDraw = new MapboxDraw({
            controls: {
                point: this.options.pointControl,
                line_string: this.options.lineControl,
                polygon: this.options.areaControl,
                trash: true,
                combine_features: false,
                uncombine_features: false,
            },
            styles: [
                {
                    "id": "draw-polygon-inactive-line",
                    "type": "line",
                    "filter": [
                        "all",
                        ["==", "$type", "Polygon"],
                        ["!=", "mode", "static"]
                    ],
                    "paint": {
                        "line-width": 4,
                        "line-color": "#008efa",
                    }
                },
                {
                    "id": "draw-line-inactive-line",
                    "type": "line",
                    "filter": [
                        "all",
                        ["==", "$type", "LineString"],
                        ["==", "active", "false"],
                        ["!=", "mode", "static"]
                    ],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#008efa",
                        "line-width": 4
                    }
                },
                {
                    "id": "draw-line-inactive-symbol",
                    "type": "symbol",
                    "filter": [
                        "all",
                        ["==", "$type", "LineString"],
                        ["==", "active", "false"],
                        ["!=", "mode", "static"]
                    ],
                    "layout": {
                        "symbol-placement": "line",
                        "symbol-avoid-edges": true,
                        "icon-image": "24-tools-arrow_up",
                        "icon-rotate": 90,
                        "icon-size": 0.8,
                        "icon-anchor": "bottom",
                        "icon-allow-overlap": true
                    }
                },
                {
                    "id": "draw-line-active-line",
                    "type": "line",
                    "filter": [
                        "all",
                        ["!=", "$type", "Point"],
                        ["==", "active", "true"],
                        ["!=", "mode", "static"]
                    ],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#fcb836",
                        "line-width": 5
                    }
                },
                {
                    "id": "draw-vertext-inactive-circle",
                    "type": "circle",
                    "filter": [
                        "all",
                        ["==", "$type", "Point"],
                        ["==", "active", "false"],
                        ["==", "meta", "vertex"],
                        ["!=", "mode", "static"]
                    ],
                    "paint": {
                        "circle-radius": 6,
                        "circle-color": "#008efa",
                        "circle-stroke-width": 2,
                        "circle-stroke-color": "#ffffff",
                        "circle-stroke-opacity": 0.8
                    }
                },
                {
                    "id": "draw-vertext-active-circle",
                    "type": "circle",
                    "filter": [
                        "all",
                        ["==", "$type", "Point"],
                        ["==", "active", "true"],
                        ["==", "meta", "vertex"],
                        ["!=", "mode", "static"]
                    ],
                    "paint": {
                        "circle-radius": 6,
                        "circle-color": "#fcb836",
                        "circle-stroke-width": 2,
                        "circle-stroke-color": "#ffffff",
                        "circle-stroke-opacity": 0.8
                    }
                },
                {
                    "id": "draw-midpoint-inactive-circle",
                    "type": "circle",
                    "filter": [
                        "all",
                        ["==", "$type", "Point"],
                        ["==", "meta", "midpoint"],
                        ["!=", "mode", "static"]
                    ],
                    "paint": {
                        "circle-radius": 6,
                        "circle-color": "#fcb836",
                        //"circle-stroke-width": 2,
                        //"circle-stroke-color": "#ffffff",
                        //"circle-stroke-opacity": 0.8
                    }
                },
                {
                    "id": "draw-point-inactive-circle",
                    "type": "circle",
                    "filter": [
                        "all",
                        ["==", "$type", "Point"],
                        ["==", "meta", "feature"],
                        ["==", "active", "false"]
                    ],
                    "paint": {
                        "circle-radius": 8,
                        "circle-color": "#008efa",
                        "circle-stroke-width": 4,
                        "circle-stroke-color": "#ffffff",
                        "circle-stroke-opacity": 0.8
                    }
                },
                {
                    "id": "draw-point-active-circle",
                    "type": "circle",
                    "filter": [
                        "all",
                        ["==", "$type", "Point"],
                        ["==", "meta", "feature"],
                        ["==", "active", "true"]
                    ],
                    "paint": {
                        "circle-radius": 8,
                        "circle-color": "#fcb836",
                        "circle-stroke-width": 4,
                        "circle-stroke-color": "#ffffff",
                        "circle-stroke-opacity": 0.8
                    }
                }
            ]
        });

        // Add draw contriol to Mapbox
        this.mapboxMap.addControl(this.mapboxDraw, "top-left");

        // Handle create
        this.mapboxMap.on("draw.create", (e: MapboxDraw.DrawCreateEvent) => {
            // OnFeatureCreate handler
            this.onFeatureCreate(e.features.shift());
        });

        // Handle delete
        this.mapboxMap.on("draw.delete", (e: MapboxDraw.DrawDeleteEvent) => {
            // OnFeatureDelete handler
            this.onFeatureDelete(e.features.shift());
        });

        // Handle update
        this.mapboxMap.on("draw.update", (e: MapboxDraw.DrawUpdateEvent) => {
            // OnFeatureUpdate handler
            this.onFeatureUpdate(e.features.shift());
        });

        // Handle select
        this.mapboxMap.on("draw.selectionchange", (e: MapboxDraw.DrawSelectionChangeEvent) => {
            // OnFeatureSelect handler
            this.onFeatureSelect(e.features.shift());
        });

        // Handle load, we use style.load to cover loading new styles
        this.mapboxMap.on("style.load", async () => {
            // Loaded flag
            this.mapboxLoaded = true;

            // When Mapbox loads, we have to add all layers for drawing context
            for (let layer of this.options.layers || []) {
                // Add to map
                this.mapboxMap.addLayer(layer.options.layer);

                // Load data
                layer.data = await layer.load();

                // Each layer has it own data source, we don't share it
                let source = <GeoJSONSource>this.mapboxMap.getSource(layer.options.layer.id);

                // Source exists?
                if (source) {
                    // Set new data to Mapbox data soure and force Mapbox to redraw canvas and display new data
                    source.setData(layer.data);
                }

                // Transition effects
                if (layer.options.transitions) {
                    for (let property in layer.options.transitions) {
                        this.mapboxMap.setPaintProperty(layer.options.layer.id, property, layer.options.transitions[property]);
                    }
                }
            }

            // OnMapLoad handler
            this.onMapLoad();
        });

        // Handle zoom
        this.mapboxMap.on("zoom", () => {
            // Remember zoom
            this.options.zoom = this.mapboxMap.getZoom();

            // OnMapZoom handler
            this.onMapZoom(this.options.zoom);
        });

        // Store center position on move
        this.mapboxMap.on("move", () => {
            // Remember center
            let center = this.mapboxMap.getCenter();
            this.options.center = [center.lng, center.lat];
        });

        // Call move callback on when move ends
        this.mapboxMap.on("moveend", () => {
            // New center
            let center = this.mapboxMap.getCenter();

            // OnMapZoomEnd handler
            this.onMapMove([center.lng, center.lat]);
        });

        // Debug
        this.mapboxMap.on("dblclick", (e) => {
            e.preventDefault();
            Log.i(this.mapboxMap.getCenter());
            Log.i(this.mapboxMap.getZoom());
            Log.i(this.mapboxMap.getStyle().layers);
        });
    }

    public onDetach(): void {
        // Mapbox clean-up
        this.mapboxMap.remove();
        this.mapboxMap = null;
        this.mapboxLoaded = false;
    }

    public setFeatures(features: Feature[]) {
        if (this.mapboxDraw) {
            // Remove old features
            this.mapboxDraw.deleteAll();

            // Add all features
            this.mapboxDraw.add({
                type: "FeatureCollection",
                features: features
            });
        }
    }

    public getFeatures(): Feature[] {
        return this.mapboxDraw.getAll().features;
    }

    public addFeature(feature: Feature) {
        if (this.mapboxDraw) {
            // Add new or update exisitng feature
            this.mapboxDraw.add(feature);
        }
    }

    public resize(): void {
        if (this.mapboxMap) {
            this.mapboxMap.resize();
        }
    }

    public setStyle(style: any): void {
        // Quit silently when no style change
        if (this.options.style == style) {
            return;
        }

        // Write to options
        this.options.style = style;

        // Set new style in mapbox
        this.mapboxMap.setStyle(MAP_STYLES[style]);
    }

    public zoomIn(): void {
        if (this.mapboxMap) {
            this.mapboxMap.zoomIn();
        }
    }

    public zoomOut(): void {
        if (this.mapboxMap) {
            this.mapboxMap.zoomOut();
        }
    }

    public flyTo(options: MapFlyToOptions): void {
        if (this.mapboxMap) {
            this.mapboxMap.flyTo({
                center: [options.center[0], options.center[1]],
                zoom: options.zoom || this.options.zoom,
                duration: options.duration || 3000,
                bearing: options.bearing || null,
                pitch: 0
            });
        }
    }

    public fitBounds(bounds: mapbox.LngLatBounds, duration: number = 3000): void {
        if (this.mapboxMap) {
            this.mapboxMap.fitBounds(bounds, {
                padding: 64,
                duration: duration,
                maxZoom: 22,
                pitch: 0
            });
        }
    }
}
