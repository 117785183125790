import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { METRICS } from "../../components/city/city-subdomain/types";

export class TravelTimeMapLineLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext, feed?: any) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            before: "road-label",
            pointer: true,
            layer: {
                id: "travel-time-feed-line",
                type: "line",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "line-color": "#008efa",
                    "line-opacity": 1,
                    "line-width": 4,
                    "line-offset": 3.5,
                    "line-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "line-opacity": 1
            }
        });
    }

    public async load(): Promise<any> {
        // Get routes
        let routes = (await this.context.invipo.getDataset("travel-time-routes", "sort=interval.to:desc")).data;

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let route of routes) {
            json.features.push({
                type: "Feature",
                properties: {
                    id: route.id,
                    name: route.name,
                    origin: route.origin.name,
                    destination: route.destination.name,
                    length: route.length
                },
                geometry: route.geometry
            })
        }

        return json;
    }
}
