import "./hanoi-violation-report-form.scss";
import * as template from "./hanoi-violation-report-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { HanoiViolationReportFormOptions } from "./types";
import { TextInput } from "../../../../muklit/components/text-input/text-input";
import { DateInput } from "../../../../muklit/components/date-input/date-input";
import { Select } from "../../../../muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { TextArea } from "../../../../muklit/components/text-area/text-area";
import { VehicleRegistryDetail } from "../../traffic/vehicle-registry-detail/vehicle-registry-detail";
import { VehicleRegistry } from "../../traffic/vehicle-registry-detail/types";
import { COLUMN_REPORT_MANAGEMENT } from "../hanoi-violation-browser/hanoi-violation-browser";

export class HanoiViolationReportForm extends Detail<InvipoContext, HanoiViolationReportFormOptions> {

    // Components
    public form: Form;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: HanoiViolationReportFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
    }

    private createForm(): void {
        // Temporrary field
        let temporary = new Select(this.context, {
            style: "Light",
            name: "temporary",
            label: "forms.fields.temporary",
            value: "true",
            width: 80,
            items: [
                {
                    name: "true",
                    label: "common.yes",
                    selected: true
                },
                {
                    name: "false",
                    label: "common.no"
                }
            ],
            bright: true
        });

        // Owner doucment info
        let ownerDocumentInfo = new TextInput(this.context, {
            style: "Light",
            name: "ownerDocumentInfo",
            label: "forms.fields.ownerDocumentInfo",
            width: 912,
            bright: true
        });

        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "Row",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "plateNumber",
                            label: "forms.fields.plateNumber",
                            value: this.options.violation.data.incident.extras.plate.number,
                            width: 144,
                            bright: true,
                            disabled: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            format: "Number",
                            name: "reportNumber",
                            label: "forms.fields.reportNumber",
                            width: 144,
                            bright: true,
                            required: true
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            type: "DateTime",
                            name: "timestamp",
                            label: "forms.fields.reportTimestamp",
                            value: new Date().toISOString(),
                            width: 192,
                            bright: true,
                            required: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "location",
                            label: "forms.fields.location",
                            width: 288,
                            value: this.options.violation.data.incident.item.location,
                            bright: true
                        }),
                        temporary
                    ]
                },
                {
                    name: "Row",
                    fields: [
                        new Select(this.context, {
                            style: "Light",
                            name: "violationType",
                            label: "forms.fields.violationType",
                            value: this.options.violation.type,
                            width: 288,
                            items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("ViolationsData", "type"), "enums.ViolationType."),
                            bright: true
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "staffPrimary",
                            label: "forms.fields.staffPrimary",
                            width: 304,
                            items: this.context.data.getUsers().map(x => {
                                return {
                                    name: x.id,
                                    label: `${x.name}${x.title ? ` (${x.title})` : ""}`,
                                }
                            }),
                            bright: true
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "staffSecondary",
                            label: "forms.fields.staffSecondary",
                            width: 288,
                            items: this.context.data.getUsers().map(x => {
                                return {
                                    name: x.id,
                                    label: `${x.name}${x.title ? ` (${x.title})` : ""}`,
                                }
                            }),
                            bright: true
                        })
                    ]
                },
                {
                    name: "Row",
                    label: "details.blocks.identity",
                    fields: [
                        ownerDocumentInfo
                    ]
                },
                {
                    name: "Row",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "ownerName",
                            label: "forms.fields.ownerName",
                            width: 288,
                            required: true,
                            bright: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "ownerAddress",
                            label: "forms.fields.ownerAddress",
                            width: 608,
                            bright: true,
                            required: true
                        })
                    ]
                },
                {
                    name: "Row",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "ownerNationality",
                            label: "forms.fields.ownerNationality",
                            width: 288,
                            bright: true
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            type: "Date",
                            name: "ownerBirthdate",
                            label: "forms.fields.ownerBirthdate",
                            width: 192,
                            bright: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "ownerOccupation",
                            label: "forms.fields.ownerOccupation",
                            width: 400,
                            bright: true
                        }),
                    ]
                },
                {
                    name: "Row",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "ownerDocumentNumber",
                            label: "forms.fields.ownerDocumentNumber",
                            width: 288,
                            bright: true
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            type: "Date",
                            name: "ownerDocumentIssued",
                            label: "forms.fields.ownerDocumentIssued",
                            width: 192,
                            bright: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "ownerDocumentOrigin",
                            label: "forms.fields.ownerDocumentOrigin",
                            width: 400,
                            bright: true
                        })
                    ]
                },
                {
                    name: "Row",
                    fields: [
                        new TextArea(this.context, {
                            style: "Light",
                            name: "violationDetails",
                            label: "forms.fields.violationDetails",
                            width: 916,
                            rows: 4,
                            bright: true
                        })
                    ]
                },
                {
                    name: "Row",
                    label: "details.blocks.statements",
                    fields: [
                        new TextArea(this.context, {
                            style: "Light",
                            name: "viloatorStatement",
                            label: "forms.fields.viloatorStatement",
                            width: 288,
                            rows: 3,
                            bright: true
                        }),
                        new TextArea(this.context, {
                            style: "Light",
                            name: "witnessStatement",
                            label: "forms.fields.witnessStatement",
                            width: 304,
                            rows: 3,
                            bright: true
                        }),
                        new TextArea(this.context, {
                            style: "Light",
                            name: "penaltyApplied",
                            label: "forms.fields.penaltyApplied",
                            width: 288,
                            rows: 3,
                            bright: true
                        })
                    ]
                },
                {
                    name: "Row",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "handlingAddress",
                            label: "forms.fields.handlingAddress",
                            width: 704,
                            bright: true
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            type: "DateTime",
                            name: "handligTimestamp",
                            label: "forms.fields.handligTimestamp",
                            value: new Date().toISOString(),
                            width: 192,
                            bright: true
                        })
                    ]
                }
            ]
        });

        // Disable info on temporary
        temporary.onSubmit = () => {
            // Owner document info visibility
            ownerDocumentInfo.setDisabled(Object.keys(temporary.options.value)[0] == "false");
        }

        // Set report data to form if not empty
        if (this.options.violation.extras?.report) {
            // Set data
            this.form.setData(this.options.violation.extras.report);

            // Disable/enable temporary input
            ownerDocumentInfo.options.disabled = (this.options.violation.extras.report.temporary == "false");
        }
    }

    public openRegistry(): void {
        // Detail form
        let detail = new VehicleRegistryDetail(this.context, {
            style: "Light",
            title: null,
            overlay: true,
            closable: true,
            copiable: true,
            lpn: this.options.violation.data.incident.extras.plate.number
        });

        // Copy regisrtry data
        detail.onCopy = (registry: VehicleRegistry) => {
            this.form.setValue("ownerName", registry.owner?.name);
            this.form.setValue("ownerAddress", registry.owner?.address);
            this.form.setValue("ownerDocumentNumber", registry.owner?.id);
        }

        // Show form
        detail.attach();
    }

    public async submit(): Promise<void> {
        // Basic form validation?
        if (!this.form.validate()) {
            return;
        }

        // Get form data
        let data = this.form.getData(true);

        // Get extras reference
        let extras = this.options.violation.extras || {};

        // Set report data to extras
        extras.report = {
            ...data
        }

        // Show loader
        this.showLoader();

        // Create new user with two form merged together
        try {
            // Update data
            await this.context.invipo.putManagement(`violations/${this.options.violation.id}/extras`, {
                extras: extras
            });

            // Update column
            await this.context.invipo.putManagement(`violations/${this.options.violation.id}/workflow/column`, {
                column: COLUMN_REPORT_MANAGEMENT
            });
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnNotificationSubmit handler
        this.onSubmit();
    }

    public async load(): Promise<void> {
        // Get next report number
        let sequence = await this.context.invipo.getSequence("violation-report");

        // Set repotr number
        this.form.setValue("reportNumber", sequence.number);
    }

}
