import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterAction, FilterItem } from "muklit/components/filter/types";
import { RangeInput } from "muklit/components/range-input/range-input";
import { HanoiViolationNoticeBrowserOptions } from "./types";
import { Templates } from "../../../../hiyo/templates";
import { Select } from "../../../../muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { COLUMN_VIOLATION_NOTICE, HanoiViolationBrowser } from "../hanoi-violation-browser/hanoi-violation-browser";
import { ItemSelect } from "../../common/item-select/item-select";
import { TextInput } from "../../../../muklit/components/text-input/text-input";
import { OverflowMenu } from "../../../../muklit/components/overflow-menu/overflow-menu";
import { MenuItem } from "../../../../muklit/components/overflow-menu/types";
import { OffensePreview } from "../../traffic/offense-preview/offense-preview";
import { Helpers } from "../../../../hiyo/helpers";
import { HanoiViolationDataForm } from "../hanoi-violation-data-form/hanoi-violation-data-form";

export class HanoiViolationNoticeBrowser extends HanoiViolationBrowser<HanoiViolationNoticeBrowserOptions> {

    public createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/violations-data?workflow.column=${COLUMN_VIOLATION_NOTICE}`,
            http: this.context.invipo.http,
            filter: {
                title: `components.${this.name}.title`,
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ],
                actions: [
                    {
                        name: "PrintNotice",
                        label: "components.HanoiViolationNoticeBrowser.actions.printNotice",
                        multiselect: true,
                        disabled: true
                    },
                    {
                        name: "CreateReport",
                        label: "components.HanoiViolationProcessBrowser.actions.createReport",
                        multiselect: true,
                        disabled: true
                    },
                    {
                        name: "InsufficientGrounds",
                        label: "components.HanoiViolationBrowser.actions.insufficientGroundsForPenalty",
                        multiselect: true,
                        disabled: true
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "type",
                                label: "forms.fields.type",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("ViolationsData", "type"), "enums.ViolationType."),
                                multiselect: true
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                distinct: "ViolationsData",
                                items: [],
                                multiselect: true
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "data.incident.extras.plate.number",
                                label: "forms.fields.plateNumber",
                                placeholderText: "forms.placeholders.plateNumber"
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "data.incident.extras.class",
                                label: "forms.fields.category",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toCategoryItems(this.context.config.categories),
                                multiselect: true
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "data.incident.extras.color",
                                label: "forms.fields.color",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.VehicleColor")),
                                multiselect: true
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "data.incident.extras.measurement.speed.min",
                                label: "forms.fields.speedMin",
                                placeholderText: "forms.placeholders.speedMin"
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "data.incident.extras.measurement.speed.max",
                                label: "forms.fields.speedMax",
                                placeholderText: "forms.placeholders.speedMax"
                            })
                        ]
                    }
                ]
            },
            table: {
                type: "MultiSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id",
                    decorator: (data: any): string => {
                        let result = null;

                        // Archived?
                        if (data.workflow?.archived) {
                            result = "disabled"
                        }

                        // Completed?
                        if (data.workflow?.completed) {
                            result = "disabled"
                        }

                        return result;
                    }
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "type",
                        type: "String",
                        property: (data: any): any => {
                            return this.context.locale.getMessage(`enums.ViolationType.${data.type}`);
                        },
                        label: "tables.columns.type",
                        width: 160,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "data.incident.extras.plate.number",
                        type: "String",
                        property: "data.incident.extras.plate.number",
                        label: "tables.columns.plate",
                        formatter: (value: any, data: any): string => {
                            if (!value) {
                                return;
                            }
                            return `<div class="cell">${Templates.renderPartial("plate-number", data.data.incident.extras.plate)}</div>`;
                        },
                        width: 150,
                        sortable: true
                    },
                    {
                        name: "extras.notice.sentPolice",
                        type: "DateTime",
                        property: "extras.notice.sentPolice",
                        label: "tables.columns.noticeDate",
                        width: 160,
                        sortable: true
                    },
                    {
                        name: "extras.notice.sentPolice",
                        type: "String",
                        property: "extras.notice.sentPolice",
                        label: "tables.columns.sentPolice",
                        formatter: (value: any, data: any): string => {
                            if (!value) {
                                return;
                            }
                            return `<div class="partial-icon partial-icon-style-light partial-icon-align-center partial-icon-size-16 partial-icon-check" data-tooltip="${Helpers.toDateTimeString(value)}"></div>`;
                        },
                        tooltip: "components.HanoiViolationNoticeBrowser.tooltips.sentPolice",
                        width: 70,
                        sortable: true
                    },
                    {
                        name: "extras.notice.sentViolator",
                        type: "String",
                        property: "extras.notice.sentViolator",
                        label: "tables.columns.sentViolator",
                        formatter: (value: any, data: any): string => {
                            if (!value) {
                                return;
                            }
                            return `<div class="partial-icon partial-icon-style-light partial-icon-align-center partial-icon-size-16 partial-icon-check" data-tooltip="${Helpers.toDateTimeString(value)}"></div>`;
                        },
                        tooltip: "components.HanoiViolationNoticeBrowser.tooltips.sentViolator",
                        width: 70,
                        sortable: true
                    },
                    {
                        name: "extras.notice.sentAgency",
                        type: "String",
                        property: "extras.notice.sentAgency",
                        label: "tables.columns.sentAgency",
                        formatter: (value: any, data: any): string => {
                            if (!value) {
                                return;
                            }
                            return `<div class="partial-icon partial-icon-style-light partial-icon-align-center partial-icon-size-16 partial-icon-check" data-tooltip="${Helpers.toDateTimeString(value)}"></div>`;
                        },
                        tooltip: "components.HanoiViolationNoticeBrowser.tooltips.sentAgency",
                        width: 70,
                        sortable: true
                    },
                    {
                        name: "extras.notice.liftWarningAgency",
                        type: "String",
                        property: "extras.notice.liftWarningAgency",
                        label: "tables.columns.liftWarningAgency",
                        formatter: (value: any, data: any): string => {
                            if (!value) {
                                return;
                            }
                            return `<div class="partial-icon partial-icon-style-light partial-icon-align-center partial-icon-size-16 partial-icon-check" data-tooltip="${Helpers.toDateTimeString(value)}"></div>`;
                        },
                        tooltip: "components.HanoiViolationNoticeBrowser.tooltips.liftWarningAgency",
                        width: 70,
                        sortable: true
                    },
                    {
                        name: "extras.notice.liftWarningViolator",
                        type: "String",
                        property: "extras.notice.liftWarningViolator",
                        label: "tables.columns.liftWarningViolator",
                        formatter: (value: any, data: any): string => {
                            if (!value) {
                                return;
                            }
                            return `<div class="partial-icon partial-icon-style-light partial-icon-align-center partial-icon-size-16 partial-icon-check" data-tooltip="${Helpers.toDateTimeString(value)}"></div>`;
                        },
                        tooltip: "components.HanoiViolationNoticeBrowser.tooltips.liftWarningViolator",
                        width: 70,
                        sortable: true
                    },
                    {
                        name: "extras.notice.processingStatus",
                        type: "String",
                        property: "extras.notice.processingStatus",
                        label: "tables.columns.processingStatus",
                        formatter: (value: any, data: any): string => {
                            // Set status
                            let status = "";

                            // Sent to police?
                            if (data.extras?.notice?.sentPolice) {
                                status = "Within violation notice period";
                            }
                            else {
                                status = "Violation notice has not been sent";
                            }

                            return `<div class="cell cell-ellipsis">${status}</div>`;
                        },
                        minWidth: 260,
                        sortable: true
                    }
                ]
            }
        });

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Handle action  selection
        this.table.onActionSelect = async (item: FilterAction) => {
            // Print report?
            if (item.name == "PrintNotice") {
                // Open violation dialog
                this.printNotice();
            }
            // Insufficient grounds for penalty?
            if (item.name == "InsufficientGrounds") {
                // Open violation dialog
                this.insufficientGrounds();
            }
            // Create report?
            if (item.name == "CreateReport") {
                // Open violation dialog
                this.openReport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    public printNotice(): void {
        // Get first selected violaiton
        let violation = Object.values(this.table.table.selectedRows)[0].data;

        // Create menu
        let menu = new OverflowMenu(this.context, {
            style: "Light",
            start: "BottomRight",
            anchor: "TopRight",
            offset: [0, 4],
            items: [
                {
                    name: "SendPolice",
                    label: "components.HanoiViolationNoticeBrowser.actions.sendPolice",
                    data: "sentPolice"
                },
                {
                    name: "SendViolator",
                    label: "components.HanoiViolationNoticeBrowser.actions.sendViolator",
                    data: "sentViolator"
                },
                {
                    name: "SendAgency",
                    label: "components.HanoiViolationNoticeBrowser.actions.sendAgency",
                    data: "sentAgency"
                },
                {
                    name: "LiftWarningAgency",
                    label: "components.HanoiViolationNoticeBrowser.actions.liftWarningAgency",
                    data: "liftWarningAgency"
                },
                {
                    name: "LiftWarningViolator",
                    label: "components.HanoiViolationNoticeBrowser.actions.liftWarningViolator",
                    data: "liftWarningViolator"
                }
            ]
        });

        // Open offense preview
        menu.onSelect = async (item: MenuItem): Promise<void> => {
            // Get extras reference
            violation.extras = violation.extras || {};

            // Clear extras notice
            violation.extras.notice = violation.extras.notice || {};

            // Set notice flag
            violation.extras.notice[item.data] = new Date().toISOString();

            // Open form if send to police
            if (item.name == "SendPolice") {
                // Dialog to confirm
                let form = new HanoiViolationDataForm(this.context, {
                    style: "Light",
                    title: null,
                    overlay: true,
                    violation: violation
                })

                // On submit
                form.onSubmit = async () => {
                    // Unselect all checkboxes
                    this.table.unselectCheckboxes();

                    // Close detal
                    this.detail?.close();

                    // Reload
                    await this.table.load();

                    // Reload
                    await this.table.load();
                }

                // Show dialog
                form.attach();
            }
            // Direct update
            else {
                // Show loader
                this.showLoader();

                // Update data
                await this.context.invipo.putManagement(`violations/${violation.id}/extras`, {
                    extras: violation.extras
                });

                // Hide loader
                this.hideLoader();

                // Unselect all checkboxes
                this.table.unselectCheckboxes();

                // Close detal
                this.detail?.close();

                // Reload
                await this.table.load();
            }
        }

        // Show menu
        menu.show(this.query("div.filter div.partial-action"));
    }

    public async saveExtras(id: string, extras: any): Promise<void> {

    }
}
