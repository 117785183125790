import "./cis-wall.scss";
import { InvipoContext } from "../../../context/invipo-context";
import { CisWallOptions } from "./types";
import { Log } from "../../../../hiyo/log";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";

const SCROLL_INTERVAL = 60; // in seconds

export abstract class CisWall<T extends CisWallOptions = CisWallOptions> extends MuklitComponent<InvipoContext, T> {

    // Properties
    public interval: any;
    public scroll: number;
    private keyListener: (event: KeyboardEvent) => void;
    private resizeListener: (event: Event) => void;

    // Event handling methods
    public onResize(): void {};

    public onCreate(): void {
        // Reset scroll
        this.scroll = 0;

        // Create components
        this.createMaps();
    }

    public onAttach(): void {
        // Define key listener
        this.keyListener = (event: KeyboardEvent) => {
            this.key(event.key);
        }

        // Define fullscreen listener
        this.resizeListener = (event: Event) => {
            // OnResize handler
            this.onResize();
        }

        // Add scroll interval
        this.interval = setInterval(() => {
            this.scrollBy(1);
        }, SCROLL_INTERVAL * 1000);

        // Add listeners
        document.addEventListener("keydown", this.keyListener);
        window.addEventListener("resize", this.resizeListener);
    }

    public onDetach(): void {
        // Remove listeners
        document.removeEventListener("keydown", this.keyListener);
        window.removeEventListener("resize", this.resizeListener);

        // Clear scroll interval
        clearInterval(this.interval);
    }

    public abstract createMaps(): void;

    public key(key: string): void {
        // Scroll left
        if (key == "ArrowUp") {
            this.scrollBy(-1);
        }

        // Scroll right
        if (key == "ArrowDown") {
            this.scrollBy(1);
        }
    }

    public scrollBy(d: number): void {
        // Map row height?
        let height = window.innerHeight / 2;

        this.scroll += height * d;

        // Scrolled down already?
        if (d > 0 && (this.scroll + height >= this.element.scrollHeight)) {
            this.scroll = 0;
            Log.i("CisWall: Bottom reached, going up");
        }

        // Scrolled up already?
        if (d < 0 && this.element.scrollTop <= 0) {
            this.scroll = this.element.scrollHeight;
            Log.i("CisWall: Top reached, going down");
        }

        // Scroll to offset
        this.element.scrollTo({
            top: this.scroll,
            left: 0,
            behavior: "smooth"
        });

        Log.i(`CisWall: scroll=${this.scroll} [${this.element.scrollTop}/${this.element.scrollHeight}]`);
    }
}