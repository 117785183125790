import "./travel-time-feed-route-card.scss";
import * as template from "./travel-time-feed-route-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CityCard } from "../../city/city-card/city-card";
import { TravelTimeFeedRouteCardOptions } from "./types";

export class TravelTimeFeedRouteCard extends CityCard<TravelTimeFeedRouteCardOptions> {

    constructor(context: InvipoContext, options: TravelTimeFeedRouteCardOptions) {
        super(context, template, options);
    }

}
