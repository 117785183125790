import "./watchlist-vehicle-manager.scss";
import * as template from "./watchlist-vehicle-manager.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";
import { Select } from "muklit/components/select/select";
import { WatchlistVehicleManagerOptions } from "./types";
import { InvipoHelpers } from "../../../invipo-helpers";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterItem } from "muklit/components/filter/types";
import { WatchlistVehicleDetail } from "../watchlist-vehicle-detail/watchlist-vehicle-detail";
import { WatchlistVehicleDetailOptions } from "../watchlist-vehicle-detail/types";
import { TextInput } from "../../../../muklit/components/text-input/text-input";
import { Templates } from "../../../../hiyo/templates";

export class WatchlistVehicleManager extends MuklitComponent<InvipoContext, WatchlistVehicleManagerOptions> {

    // Components
    public table: FilterTable;
    public detail: WatchlistVehicleDetail;

    constructor(context: InvipoContext, options?: WatchlistVehicleManagerOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    public createTable(): void {
        // Create component

        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/watchlists-vehicles`,
            http: this.context.invipo.http,
            filter: {
                title: "components.WatchlistVehicleManager.title",
                items: [
                    {
                        name: "Add",
                        label: "labels.add"
                    },
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ],
                actions: [
                    {
                        name: "Delete",
                        label: "labels.deleteSelected",
                        multiselect: true,
                        disabled: true
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "General",
                        fields: [
                            new Select(this.context, {
                                style: "Light",
                                name: "watchlist",
                                label: "forms.fields.watchlist",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("WatchlistsVehicles", "watchlist")),
                                multiselect: true
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "lpn",
                                label: "forms.fields.plateNumber",
                                placeholderText: "forms.placeholders.plateNumber"
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "brand",
                                label: "forms.fields.brand",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("WatchlistsVehicles", "brand")),
                                multiselect: true
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "model",
                                label: "forms.fields.model",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("WatchlistsVehicles", "model")),
                                multiselect: true
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "color",
                                label: "forms.fields.color",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.data.getDistinct("WatchlistsVehicles", "color")),
                                multiselect: true
                            }),
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                type: "MultiSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "watchlist",
                        type: "String",
                        property: "watchlist",
                        label: "tables.columns.watchlist",
                        width: 240,
                        ellipsis: true,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "lpn",
                        type: "String",
                        property: "lpn",
                        label: "tables.columns.plate",
                        formatter: (value: any, data: any): string => {
                            if (!value) {
                                return;
                            }
                            return `<div class="cell">${Templates.renderPartial("plate-number", { number: value })}</div>`;
                        },
                        width: 150,
                        sortable: true
                    },
                    {
                        name: "brand",
                        type: "String",
                        property: "brand",
                        label: "tables.columns.brand",
                        width: 140,
                        ellipsis: true,
                        sortable: true
                    },
                    {
                        name: "model",
                        type: "String",
                        property: "model",
                        label: "tables.columns.model",
                        width: 140,
                        ellipsis: true,
                        sortable: true
                    },
                    {
                        name: "color",
                        type: "String",
                        property: "color",
                        label: "tables.columns.color",
                        width: 140,
                        ellipsis: true,
                        sortable: true
                    },
                    {
                        name: "lastEvent",
                        type: "DateTime",
                        property: "lastEvent",
                        label: "tables.columns.lastEvent",
                        minWidth: 160,
                        ellipsis: true,
                        sortable: true
                    }
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            // Add new user?
            if (item.name == "Add") {
                //await this.openNew();
            }

            // Data export?
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    protected async openDetail(data: any): Promise<void> {
        // Detail options
        let options: WatchlistVehicleDetailOptions = {
            style: "Light",
            vehicle: data,
            title: data.lpn,
            printable: false,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Redraw completely
            await this.detail.load();

            // Not continue
            return;
        }

        // New detail
        this.detail = new WatchlistVehicleDetail(this.context, options);

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.vehicle.id);
            this.detail = null;
        }

        // Show detail
        this.detail.attach();
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Light",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: true,
            items: [
                {
                    name: "WatchlistsVehiclesCsv",
                    label: "components.ServerExportForm.types.Csv",
                    checked: true
                }
            ],
            query: this.table.getQuery(),
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }
}
