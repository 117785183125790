import './travel-time-subdomain.scss';
import * as template from "./travel-time-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TravelTimeSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { ItemList } from "../../city/item-list/item-list";
import { ItemLabelLayer } from "../../../layers/infrastructure/item-label-layer";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";
import { TravelTimeFeedLineLayer } from "../../../layers/traffic/travel-time-feed-line-layer";

export const TRAVEL_TIME_CLASS = "BluetoothDetector";

export class TravelTimeSubdomain extends CitySubdomain<TravelTimeSubdomainOptions> {

    constructor(context: InvipoContext, options?: TravelTimeSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Level",
                    itemClass: TRAVEL_TIME_CLASS,
                    layers: [
                        new TravelTimeFeedLineLayer(context)
                    ]
                }
            ],
            ...options
        });
    }

    public selectDevices(): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.devices",
            subtitle: "components.TravelTimeSubdomain.devices",
            layers: [
                new ItemCircleLayer(this.context, this.items),
                new ItemLabelLayer(this.context, this.items)
            ]
        });

        // Items already loaded, will use them
        list.items = this.items;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public async extraLoad(): Promise<void> {
        // Occupncy metrics?
        if (this.metrics.name == "Level") {
            // Feed data
            let feed = (await this.context.invipo.getDataset("travel-time-feeds", "sort=interval.to:desc&pageSize=1"))?.data[0];

            // Create messages legend
            this.legend = {
                range: null,
                symbols: []
            };

            // Calculate legend counts and percentage
            for (let symbol of METRICS.traffic.traveltime.level.range) {
                // Level number
                let level = METRICS.traffic.traveltime.level.range.indexOf(symbol);

                // count of level routes
                let count = (<any[]>feed.routes).filter(x => (x.level && x.level == level) || (!x.level && symbol == "Unknown")).length

                this.legend.symbols.push({
                    type: "Circle",
                    symbol: symbol,
                    count: count,
                    label: `enums.TravelTimeLevel.${symbol}`,
                    percent: count / (feed.routes.length || 1) * 100,
                    color: METRICS.traffic.traveltime.level.colors[level]
                });
            }
        }
    }
}
