// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.invipo-ticket-table {
  padding: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/invipo/components/desk/ticket-table/ticket-table.scss"],"names":[],"mappings":"AAGA;EACI,eAAA;AAFJ","sourcesContent":["@import \"~muklit/styles/colors\";\r\n@import \"~muklit/styles/mixins\";\r\n\r\ndiv.invipo-ticket-table {\r\n    padding: 0 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
