import "./ulm-dashboard.scss";
import * as template from "./ulm-dashboard.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { UlmDashboardOptions } from "./types";
import { DataStorageTile } from "../data-storage-tile/data-storage-tile";
import { TrafficVolumeTile } from "../traffic-volume-tile/traffic-volume-tile";
import { ItemInspectorTile } from "../item-inspector-tile/item-inspector-tile";
import { Dashboard } from "../dashboard/dashboard";

export class UlmDashboard extends Dashboard<UlmDashboardOptions> {

    constructor(context: InvipoContext, options?: UlmDashboardOptions) {
        super(context, template, options);
    }

    protected createColumns(): void {
        // Create columns
        this.columns = [
            {
                name: "System",
                width: "1",
                tiles: [
                    new DataStorageTile(this.context),
                    new ItemInspectorTile(this.context)
                ]
            },
            {
                name: "Traffic",
                width: "3",
                tiles: []
            }
        ];

        // Get list of traffic volume areas
        let areas = this.context.data.getAreas({
            type: "TrafficVolume",
            sort: "name:asc"
        });

        // Create traffic tiles dynamically
        for (let area of areas) {
            // Add tile
            this.columns[1].tiles.push(new TrafficVolumeTile(this.context, {
                areaId: area.id,
                capactiy: 3600
            }));
        }
    }
}
