import "./detection-report.scss";
import * as template from "./detection-report.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Panel } from "../../common/panel/panel";
import { DetectionHour, DetectionPeriod, DetectionReportOptions } from "./types";
import { Form } from "muklit/components/form/form";
import { ItemSelect } from "../../common/item-select/item-select";
import { Select } from "muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { RangeInput } from "muklit/components/range-input/range-input";

export class DetectionReport extends Panel<DetectionReportOptions> {

    // Properties
    public hours: DetectionHour[];

    public constructor(context: InvipoContext, options?: DetectionReportOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
    }

    protected createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new RangeInput(this.context, {
                            style: "Light",
                            type: "Day",
                            time: true,
                            name: "interval",
                            label: "forms.fields.date",
                            value: {
                                from: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                                to: new Date(new Date().setHours(24, 0, 0, 0) - 1).toISOString(),
                                range: "Today"
                            },
                            width: 320,
                            required: true,
                            bright: true
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "period",
                            label: "forms.fields.period",
                            value: "15",
                            items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("components.DetectionReport.period")),
                            width: 320,
                            required: true,
                            bright: true
                        }),
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "itemId",
                            label: "forms.fields.item",
                            value: this.options.itemId,
                            placeholderText: "forms.placeholders.all",
                            distinct: "DetectionData",
                            items: [],
                            width: 320,
                            required: true,
                            bright: true
                        })
                    ]
                }
            ]
        });

        console.info(this.context.locale.getMessages("components.DetectionReport.period"));

        // Register component
        this.registerComponent(this.form, "form");
    }

    public selectImage(i: number, j: number): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: `${this.context.options.host}/download${this.hours[i].periods[j].snapshots[0]}`,
            urls: this.hours[i].periods[j].snapshots.map(x => `${this.context.options.host}/download${x}`),
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }

    public async extraLoad(): Promise<void> {
        // Get simplified form data
        let form = this.form.getData(true);

        // Assign form data to panel search options
        this.options.search = this.form.getData();

        // Timestamp or item not selected?
        if (!form.from || !form.to || !form.itemId) {
            return;
        }

        // Get whole day interval
        let from = new Date(new Date(form.from));
        let to = new Date(new Date(form.to));

        // Get detection snapshots
        let detections = await this.context.invipo.getDataset("detection-data", `item.id=${form.itemId}&from=${from.toISOString()}&to=${to.toISOString()}&sort=interval.from:asc`);

        // Build hourly data
        this.hours = [];

        for (let i = 0; i < 24; i++) {
            // Date shift
            from.setHours(i, 0, 0, 0);

            // Hour record
            let hour: DetectionHour = {
                timestamp: from.toISOString(),
                periods: []
            }

            // Interval in milliseconds
            let interval = Number(form.period);

            // Period start
            let start = new Date(from);

            while (start.getTime() < from.getTime() + 60 * 60 * 1000) {
                // Period record
                let period: DetectionPeriod = {
                    timestamp: start.toISOString(),
                    interval: interval,
                    snapshots: []
                };

                // Iterate all detections
                for (let d of detections.data) {
                    // Has vehicle images?
                    if (d.vehicle?.images) {
                        // And all snapshots
                        for (let i of d.vehicle.images) {
                            // Fits period?
                            if (new Date(d.timestamp).getTime() >= start.getTime() && new Date(d.timestamp).getTime() < (start.getTime() + (interval * 60000))) {
                                period.snapshots.push(i.url);
                            }
                        }
                    }
                }

                // Add period
                hour.periods.push(period);

                // Next interval
                start.setTime(start.getTime() + (interval * 60000));
            }


            // Add hour
            this.hours.push(hour);
        }
    }

}
