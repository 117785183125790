import './parking-occupancy-tile.scss';
import * as template from "./parking-occupancy-tile.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Tile } from "../../common/tile/tile";
import { TileChart, TileKpis } from "../../common/tile/types";
import { Helpers } from "hiyo/helpers";
import { METRICS } from "../../city/city-subdomain/types";
import { ParkingOccupancyTileOptions } from "./types";

export class ParkingOccupancyTile extends Tile<ParkingOccupancyTileOptions> {

    // Properties
    public kpis: TileKpis;
    public chart: TileChart;

    constructor(context: InvipoContext, options?: ParkingOccupancyTileOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Calculate area occupancy
        let total = this.item.data?.occupancy?.overall?.total;
        let free = this.item.data?.occupancy?.overall?.free;

        // Build items kpis
        this.kpis = {
            size: "Half",
            kpis: [
                {
                    label: "components.ParkingOccupancyTile.total",
                    value: Helpers.toNumber(total) || "common.unknown"
                },
                {
                    label: "components.ParkingOccupancyTile.free",
                    value: Helpers.toNumber(free) || "common.unknown"
                }
            ]
        }

        // Load history
        let from = new Date(new Date().setHours(-24, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));
        let data = await this.context.invipo.getQuery("parking-occupancy-by-hour", `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Calculate history data (last 2 days)
        this.chart = {
            size: "48",
            data: []
        };

        // Get history from yesterday's midnight to today's midnight
        for (let h = 0; h < 48; h++) {
            // Find hour in data
            let d = data.find(x => x.timestamp == from.toISOString());
            let symbol = "Unknown";

            // Has data?
            if (d?.occupancy) {
                for (let i = 0; i < METRICS.parking.parking.occupancy.interval.length; i++) {
                    if (Math.max(d.occupancy, 0) >= METRICS.parking.parking.occupancy.interval[i][0] && Math.min(d.occupancy, 100) < METRICS.parking.parking.occupancy.interval[i][1]) {
                        symbol = METRICS.parking.parking.occupancy.range[i];
                        break;
                    }
                }
            }

            let color = METRICS.parking.parking.occupancy.colors[METRICS.parking.parking.occupancy.range.indexOf(symbol)];

            // Add history line
            if (d) {
                this.chart.data.push({
                    timestamp: from.toISOString(),
                    value: d.occupancy,
                    percent: d.occupancy,
                    color: color
                });
            }
            // No data for hour, we are in the future
            else {
                this.chart.data.push({
                    timestamp: from.toISOString(),
                    value: 0
                });
            }

            // Move to next hour
            from.setTime(from.getTime() + 3600000);
        }

        // Create tile status
        if (!this.item.data?.occupancy) {
            this.status = {
                icon: "Error",
                label: "components.ParkingOccupancyTile.statusError"
            }
        }

    }
}
