import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { METRICS } from "../../components/city/city-subdomain/types";

export class WazeRouteLineLayer extends MapLayer<InvipoContext> {

    // Properties
    public route: any;

    public constructor(context: InvipoContext, route?: any) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            before: "road-label",
            card: "WazeRouteCard",
            layer: {
                id: "waze-route-line",
                type: "line",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "line-width": 4,
                    "line-color": ["get", "color"],
                    "line-opacity": 1,
                    "line-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "line-opacity": 1
            }
        });

        // Set message
        this.route = route;
    }

    public async load(): Promise<any> {
        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Just one feature for route
        json.features.push({
            type: "Feature",
            properties: {
                card: this.options.card,
                cardId: this.route.route.id,
                color: METRICS.traffic.waze.density.colors[this.route.route.jamLevel],
                level: METRICS.traffic.waze.density.range[this.route.route.jamLevel],
                jam: this.route.route.jamLevel,
                name: this.route.route.name,
                origin: this.route.route.fromName,
                destination: this.route.route.toName,
                length: this.route.route.length,
                currentTime: this.route.route.time,
                historicTime: this.route.route.historicTime
            },
            geometry: {
                type: "LineString",
                coordinates: [
                    ...(<any[]>this.route.route.line).map(x => [x.x, x.y])
                ]
            }
        })

        return json;
    }
}
