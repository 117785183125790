import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterItem } from "muklit/components/filter/types";
import { ViolationBrowser } from "../violation-browser/violation-browser";
import { WimViolationBrowserOptions } from "./types";
import { ItemSelect } from "../../common/item-select/item-select";
import { Helpers } from "hiyo/helpers";
import { RangeInput } from "muklit/components/range-input/range-input";
import { Templates } from "../../../../hiyo/templates";
import { MenuItem } from "../../../../muklit/components/overflow-menu/types";
import { WimViolationDetailOptions } from "../wim-violation-detail/types";
import { WimViolationDetail } from "../wim-violation-detail/wim-violation-detail";

export const CLASS_NAMES = "WimStation";

export class WimViolationBrowser extends ViolationBrowser<WimViolationBrowserOptions> {

    public createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/violations-data?item.class=${CLASS_NAMES}`,
            http: this.context.invipo.http,
            filter: {
                title: "components.BasicViolationBrowser.title",
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                distinct: "ViolationsData",
                                items: [],
                                multiselect: true
                            })
                        ]
                    }
                ]
            },
            table: {
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        formatter: (value: any, data: any) => {
                            // Locked?
                            if (data.stream?.length) {
                                return `<div class="partial-label partial-label-style-light" style="padding-left: -12px">
                                    <div class="icon icon-info"></div>
                                    <div class="label">${Helpers.toShortDateTimeString(value)}</div>
                                </div>`
                            }
                            else {
                                return `<div class="cell" style="padding-left: 24px">${Helpers.toShortDateTimeString(value)}</div>`
                            }
                        },
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "extras.plate.number",
                        type: "String",
                        property: "extras.plate.number",
                        label: "tables.columns.plate",
                        formatter: (value: any, data: any): string => {
                            if (!value) {
                                return;
                            }
                            return `<div class="cell">${Templates.renderPartial("plate-number", data.extras.plate)}</div>`;
                        },
                        width: 150,
                        sortable: true
                    },
                    {
                        name: "extras.measurement.speed",
                        type: "Number",
                        property: "extras.measurement.speed",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value)} km/h</div>`
                        },
                        label: "tables.columns.speed",
                        width: 100,
                        sortable: true
                    },
                    {
                        name: "extras.measurement.length",
                        type: "Number",
                        property: "extras.measurement.length",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value, 1, 1)} m</div>`
                        },
                        label: "tables.columns.length",
                        width: 100,
                        sortable: true
                    },
                    {
                        name: "extras.measurement.weight",
                        type: "Number",
                        property: "extras.measurement.weight",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value)} kg</div>`
                        },
                        label: "tables.columns.weight",
                        width: 100,
                        sortable: true
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        minWidth: 260,
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    protected async openDetail(data: any): Promise<void> {
        // Detail options
        let options: WimViolationDetailOptions = {
            style: "Light",
            violationId: data.id,
            title: "components.WimViolationDetail.title",
            subtitle: data.item.name,
            closable: true
        }

        // Detail already visible?
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Relaod completely
            await this.detail.load();

            // Not continue
            return;
        }

        // New detail
        this.detail = new WimViolationDetail(this.context, options);

        // Refresh table on detail update
        this.detail.onDetailUpdate = async () => {
            await this.table.load();
        }

        // Menu select
        this.detail.onMenuSelect = async (item: MenuItem)=> {
            // Delete action?
            if (item.name == "Delete") {
                this.detail.delete();
            }
        }

        // Refresh table on detail update
        this.detail.onDetailUpdate = async () => {
            await this.table.load();
        }

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.violationId);
            this.detail = null;
        }

        // Show detail
        this.detail.attach();
    }

}
