import * as template from "./cis-mix-wall.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { WallMap } from "../wall-map/wall-map";
import { CisMixWallOptions } from "./types";
import { Wall2droad } from "../wall-2droad/wall-2droad";
import { WallCameras } from "../wall-cameras/wall-cameras";
import { CisWall } from "../cis-wall/cis-wall";

export class CisMixWall extends CisWall<CisMixWallOptions> {

    // Components
    public c2x: WallMap;
    public zlin: WallMap;
    public olomouc: WallMap;
    public hradec: WallMap;
    public meteo: WallMap;
    public ulm: WallMap;
    public asd3u: WallMap;
    public metsense: WallMap;
    public wim: WallMap;
    public road: Wall2droad;
    public metis: WallMap;
    public cameras: WallCameras;

    constructor(context: InvipoContext, options?: CisMixWallOptions) {
        super(context, template, options);
    }

    public createMaps(): void {
        // Create c2x map component
        this.c2x = new WallMap(this.context, {
            title: "C2X Units",
            itemClass: "V2XUnit",
            scale: 2.4,
            mapOptions: {
                style: "Czechia",
                center: [16.5959663743929, 49.19834119400471],
                zoom: 11.3
            }
        });

        // Create Zlin map component
        this.zlin = new WallMap(this.context, {
            title: "Traffic Control &mdash; Zlín",
            areaId: "61daed1c8acaa9fa2bfc5ce6",
            itemClass: "TrafficLightController",
            scale: 2.4,
            mapOptions: {
                style: "Czechia",
                center: [17.662763, 49.224437],
                zoom: 11.9,
            },
        });

        // Create Olomouc map component
        this.olomouc = new WallMap(this.context, {
            title: "Traffic Control &mdash; Olomouc",
            areaId: "61db3dcd8acaa9fa2b1aa493",
            itemClass: "TrafficLightController",
            scale: 2.4,
            mapOptions: {
                style: "Czechia",
                center: [17.276817314294135, 49.589670999265536],
                zoom: 13
            },
        });

        // Create Hradec Králové map component
        this.hradec = new WallMap(this.context, {
            title: "Traffic Control &mdash; Hradec Králové",
            areaId: "634d2f643be182b62c008780",
            itemClass: "TrafficLightController",
            scale: 2.4,
            mapOptions: {
                style: "Czechia",
                center: [15.829872928052055, 50.20625162411392],
                zoom: 13,
            },
        });

        // Create Meteo map component
        this.meteo = new WallMap(this.context, {
            title: "Meteo &mdash; ŘSD",
            itemClass: "RoadWeatherStation",
            itemModel: "CrossMet,RWS200,Lift,SHN-03m,Boschung,Logotronic",
            scale: 2.4,
            mapOptions: {
                style: "Czechia",
                center: [15.456977938319937, 49.83279550660623],
                zoom: 7.5
            }
        });

        // Create 2DRoad map component
        this.metsense = new WallMap(this.context, {
            title: "MetSense &mdash; 2DRoad",
            itemClass: "RoadWeatherStation",
            itemModel: "2DRoad",
            scale: 2.4,
            mapOptions: {
                style: "Dark",
                center: [-35.64671858532623, 55.91348823656995],
                zoom: 3.2
            },
        });

        // Create Ulm map component
        this.ulm = new WallMap(this.context, {
            title: "Traffic counters &mdash; Ulm",
            areaId: "662fc6bc0b052f44d9157028",
            itemClass: "TrafficCounter",
            scale: 1,
            mapOptions: {
                style: "Dark",
                center: [9.971076, 48.401362],
                zoom: 14
            },
        });

        // Create ASD3U map component
        this.asd3u = new WallMap(this.context, {
            title: "Traffic counters &mdash; ASD3U",
            itemClass: "TrafficCounter",
            itemModel: "ASD3,ASD3U",
            scale: 2.4,
            mapOptions: {
                style: "Czechia",
                center: [15.456977938319937, 49.83279550660623],
                zoom: 7.5
            },
        });

        // Create WIM map component
        this.wim = new WallMap(this.context, {
            title: "Weigh-in-Motion &mdash; CrossWIM",
            itemClass: "WimStation",
            scale: 2.4,
            mapOptions: {
                style: "Czechia",
                center: [15.456977938319937, 49.83279550660623],
                zoom: 7.5
            },
        });

        // Create cameras component
        this.metis = new WallMap(this.context, {
            title: "Cameras &mdash; Meteo",
            itemClass: "Camera",
            scale: 2.4,
            mapOptions: {
                style: "Czechia",
                center: [15.456977938319937, 49.83279550660623],
                zoom: 7.5
            },
        });

        // 2DRoad camera view
        this.road = new Wall2droad(this.context);

        // Metis camera view
        this.cameras = new WallCameras(this.context);

        // Register components that will be automatically attached
        this.registerComponent(this.c2x, "c2x");
        this.registerComponent(this.zlin, "zlin");
        this.registerComponent(this.olomouc, "olomouc");
        this.registerComponent(this.hradec, "hradec");
        this.registerComponent(this.meteo, "meteo");
        this.registerComponent(this.ulm, "ulm");
        this.registerComponent(this.asd3u, "asd3u");
        this.registerComponent(this.metsense, "metsense");
        this.registerComponent(this.wim, "wim");
        this.registerComponent(this.road, "road");
        this.registerComponent(this.metis, "metis");
        this.registerComponent(this.cameras, "cameras");
    }

    public onResize() {
        // Resize all maps
        this.c2x.map.resize();
        this.zlin.map.resize();
        this.olomouc.map.resize();
        this.hradec.map.resize();
        this.meteo.map.resize();
        this.ulm.map.resize();
        this.asd3u.map.resize();
        this.metsense.map.resize();
        this.wim.map.resize();
        this.metis.map.resize();
    }

}
