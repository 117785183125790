import "./watchlist-vehicle-detail.scss";
import * as template from "./watchlist-vehicle-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { WatchlistVehicleDetailOptions } from "./types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { ImageDetail } from "../../common/image-detail/image-detail";

export class WatchlistVehicleDetail extends Detail<InvipoContext, WatchlistVehicleDetailOptions> {

    // Properties
    public vehicle: any;

    // Components
    public map: BasicMap;
    public layer: WatchlistVehicleDetailOptions;

    constructor(context: InvipoContext, options: WatchlistVehicleDetailOptions) {
        super(context, template, options);
    }

    public selectImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Show
        detail.attach();
    }

    public async load(): Promise<void> {
        // Load item
        this.vehicle = await this.context.invipo.getManagement(`watchlists/vehicles/${this.options.vehicle.id}`);

        // Redraw with all components
        this.update();
    }
}
