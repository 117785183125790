import "./travel-time-feed-route-browser.scss";
import * as template from "./travel-time-feed-route-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterItem } from "muklit/components/filter/types";
import { RangeInput } from "muklit/components/range-input/range-input";
import { TravelTimeFeedRouteBrowserOptions } from "./types";
import { TravelTimeFeedRouteDetail } from "../travel-time-feed-route-detail/travel-time-feed-route-detail";
import { TravelTimeFeedRouteDetailOptions } from "../travel-time-feed-route-detail/types";
import { Helpers } from "hiyo/helpers";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";
import { ItemSelect } from "../../common/item-select/item-select";
import { Select } from "../../../../muklit/components/select/select";

export class TravelTimeFeedRouteBrowser extends MuklitComponent<InvipoContext, TravelTimeFeedRouteBrowserOptions> {

    // Components
    public table: FilterTable;
    public detail: TravelTimeFeedRouteDetail;

    constructor(context: InvipoContext, options?: TravelTimeFeedRouteBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    public createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/travel-time-feeds-routes`,
            http: this.context.invipo.http,
            filter: {
                title: "components.TravelTimeFeedRouteBrowser.title",
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "General",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "route.origin.id",
                                label: "forms.fields.origin",
                                placeholderText: "forms.placeholders.all",
                                distinct: "TravelTimeFeedsRoutes.origins",
                                items: [],
                                multiselect: true
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "route.destination.id",
                                label: "forms.fields.destination",
                                placeholderText: "forms.placeholders.all",
                                distinct: "TravelTimeFeedsRoutes.destinations",
                                items: [],
                                multiselect: true
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "level",
                                label: "forms.fields.level",
                                placeholderText: "forms.placeholders.all",
                                items: [
                                    {
                                        name: "0",
                                        label: "enums.TravelTimeLevel.SmoothTraffic"
                                    },
                                    {
                                        name: "1",
                                        label: "enums.TravelTimeLevel.LightTraffic"
                                    },
                                    {
                                        name: "2",
                                        label: "enums.TravelTimeLevel.ModerateTraffic"
                                    },
                                    {
                                        name: "3",
                                        label: "enums.TravelTimeLevel.HeavyTraffic"
                                    },
                                    {
                                        name: "4",
                                        label: "enums.TravelTimeLevel.StandStill"
                                    },
                                    {
                                        name: "5",
                                        label: "enums.TravelTimeLevel.Unknown"
                                    }
                                ],
                                multiselect: true
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "interval.from",
                        type: "DateTime",
                        property: "interval.from",
                        label: "tables.columns.timestamp",
                        width: 160,
                        selected: true,
                        sortable: true,
                        descendent: true
                    },
                    {
                        name: "level",
                        type: "Number",
                        property: "level",
                        label: "tables.columns.level",
                        align: "Center",
                        width: 100,
                        sortable: true
                    },
                    {
                        name: "delay",
                        type: "Duration",
                        property: (data: any) => {
                            return data.currentTime - data.historicTime;
                        },
                        label: "tables.columns.delay",
                        align: "Center",
                        width: 100
                    },
                    {
                        name: "route.name",
                        type: "String",
                        property: "route.name",
                        label: "tables.columns.name",
                        minWidth: 200,
                        ellipsis: true
                    }
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    protected openDetail(data: any): void {
        // Detail options
        let options: TravelTimeFeedRouteDetailOptions = {
            style: "Light",
            route: data,
            title: data.route.name,
            subtitle: `${Helpers.toDateTimeString(data.interval.from)} &mdash; ${Helpers.toShortTimeString(data.interval.to)}`,
            printable: false,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Redraw completely
            this.detail.invalidate();

            // Not continue
            return;
        }

        // New detail
        this.detail = new TravelTimeFeedRouteDetail(this.context, options);

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.route.id);
            this.detail = null;
        }

        // Show detail
        this.detail.attach();
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Light",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: true,
            items: [
                {
                    name: "TravelTimeFeedRoutesCsv",
                    label: "components.ServerExportForm.types.Csv",
                    checked: true
                }
            ],
            query: this.table.getQuery(),
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }
}
