import "./code-area.scss";
import * as template from "./code-area.hbs";
import { Context } from "hiyo/context";
import { CodeAreaOptions } from "./types";
import { Input } from "../input/input";
import { html } from '@codemirror/lang-html'
import { EditorView, keymap } from "@codemirror/view"
import { indentWithTab } from "@codemirror/commands"
import { indentUnit } from "@codemirror/language";
import { basicSetup } from "codemirror";

export class CodeArea extends Input<Context, CodeAreaOptions> {

    // Properties
    public editor: EditorView;

    constructor(context: Context, options: CodeAreaOptions) {
        super(context, template, options);
    }

    public onAttach() {
        // Create CodeMirror editor
        this.editor = new EditorView({
            doc: this.options.value,
            extensions: [
                basicSetup,
                keymap.of([indentWithTab]),
                indentUnit.of(" ".repeat(4)),
                html()
            ],
            parent: this.element,

        })

        // Add input listener
        this.editor.dom.addEventListener("input", () => {
           this.options.value = this.editor.state.doc.toString();
        });

        // Add paste listener
        this.editor.dom.addEventListener("paste", () => {
            this.options.value = this.editor.state.doc.toString();
        });

        // Autofocus editor?
        if (this.options.autofocus) {
            this.editor.focus();
        }
    }

    public setValue(value: any): void {
        // Sets value to options
        this.options.value = value;

        // Reattach select to render it properly
        if (this.isAttached()) {
            this.update();
        }

    }

}
