import "./offense-preview.scss";
import * as template from "./offense-preview.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Log } from "../../../../hiyo/log";
import { OffensePreviewOptions } from "./types";

// Requires
let Handlebars = require("handlebars/dist/handlebars");

const TEMPLATE_KEY = "OffenseTemplate";

export class OffensePreview extends Detail<InvipoContext, OffensePreviewOptions> {

    // Properties
    public html: string;

    public constructor(context: InvipoContext, options: OffensePreviewOptions) {
        super(context, template, options);
    }

    public onCreate() {
        // Item expand?
        if (this.options.data?.item?.id) {
            this.options.data.item = this.context.data.getItem(this.options.data.item.id);
        }

        // Area expand?
        if (this.options.data?.area?.id) {
            this.options.data.area = this.context.data.getItem(this.options.data.area.id);
        }
    }

    public print() {
        // Iframe element
        let iframe = this.query<HTMLIFrameElement>("iframe");

        // Print iframe document
        iframe.contentWindow.print();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load template
        let template = await this.context.invipo.getTemplate(this.options.key || TEMPLATE_KEY);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Not template?
        if (!template) {
            Log.w(`OffensePreview: Missing template with key ${this.options.key || TEMPLATE_KEY}`);
            return;
        }

        // Data
        let data = {
            ...this.options.data,
            host: this.context.options.host
        };

        // Get HTML from template
        //let t = Handlebars.compile(template.value);
        this.html = Handlebars.compile(template.value)(data);

        console.info(data);

        // Iframe element
        let iframe = this.query<HTMLIFrameElement>("iframe");

        // Set content
        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(this.html);
        iframe.contentWindow.document.close();

        // Auto adjust height
        iframe.height = `${iframe.contentWindow.document.body.scrollHeight}`;
    }

}
