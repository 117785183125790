import "./template-form.scss";
import * as template from "./template-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { TemplateFormOptions } from "./types";
import { TextInput } from "../../../../muklit/components/text-input/text-input";
import { CodeArea } from "../../../../muklit/components/code-area/code-area";
import { TextArea } from "../../../../muklit/components/text-area/text-area";

export class TemplateForm extends Detail<InvipoContext, TemplateFormOptions> {

    // Components
    public form: Form;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: TemplateFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
    }

    private createForm(): void {
        // Fields
        let fields: any[] = [];

        // Key?
        if (this.options.template != null) {
            fields.push(
                new CodeArea(this.context, {
                    name: "value",
                    value: this.options.template?.value,
                    messageText: "components.TemplateForm.handlebars",
                    width: 1200,
                    height: 760,
                    autofocus: true,
                    required: true,
                    bright: true,
                })
            );
        }
        else {
            fields.push(
                new TextInput(this.context, {
                    name: "key",
                    label: "forms.fields.key",
                    value: this.options.template?.key,
                    width: 1200,
                    autofocus: true,
                    required: true,
                    bright: true,
                    disabled: this.options.template != null
                })
            )
            fields.push(
                new TextArea(this.context, {
                    name: "value",
                    label: "forms.fields.text",
                    value: this.options.template?.value,
                    messageText: "components.TemplateForm.handlebars",
                    width: 1200,
                    rows: 38,
                    required: true,
                    bright: true
                })
            );
        }

        // User profile form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: fields
                }
            ]
        });

        // Submit form on enter
        this.form.onSubmit = async () => {
            await this.submit();
        }
    }

    public async submit(): Promise<void> {
        // Basic form validation?
        if (!this.form.validate()) {
            return;
        }

        // Form data
        let data = this.form.getData();

        // Show loader
        this.showLoader();

        // Create new template with two form merged together
        try {
            if (!this.options.template) {
                // Create new
                await this.context.invipo.createTemplate(data);
            }
            else {
                // Set to options
                this.options.template = {
                    ...this.options.template,
                    ...data,
                    timestamp: new Date().toISOString()
                }

                // Update existing
                await this.context.invipo.updateTemplate(this.options.template.key, data);
            }
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnProfileSubmit handler
        this.onSubmit();
    }

}
