import "./notification-manager.scss";
import * as template from "./notification-manager.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { Search } from "muklit/components/search/search";
import { NotificationManagerOptions } from "./types";
import { NotificationDetailOptions } from "../notification-detail/types";
import { NotificationDetail } from "../notification-detail/notification-detail";
import { NotificationForm } from "../notification-form/notification-form";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterItem } from "muklit/components/filter/types";

export class NotificationManager extends MuklitComponent<InvipoContext, NotificationManagerOptions> {

    // Components
    public table: FilterTable;
    public detail: NotificationDetail;

    constructor(context: InvipoContext, options?: NotificationManagerOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    private createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/notifications`,
            http: this.context.invipo.http,
            filter: {
                title: "components.NotificationManager.title",
                items: [
                    {
                        name: "Add",
                        label: "labels.add"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new Search(this.context, {
                                style: "Light",
                                name: "name",
                                label: "forms.fields.name",
                                placeholderText: "forms.placeholders.name",
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                style: "Light",
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id",
                    decorator: (data: any): string => {
                        return data.disabled ? "disabled" : null;
                    }
                },
                columns: [
                    {
                        name: "name",
                        type: "String",
                        property: "name",
                        label: "tables.columns.name",
                        width: 320,
                        ellipsis: true,
                        sortable: true,
                        selected: true
                    },
                    {
                        name: "type",
                        type: "String",
                        property: (data: any): any => {
                            return this.context.locale.getMessage(`components.NotificationManager.types.${data.type}.label`);
                        },
                        label: "tables.columns.type",
                        width: 240,
                        ellipsis: true,
                        sortable: true
                    },
                    {
                        name: "actor.name",
                        type: "String",
                        property: "actor.name",
                        label: "tables.columns.actor",
                        width: 160,
                        ellipsis: true,
                        sortable: true
                    },
                    {
                        name: "channel",
                        type: "String",
                        property: (data: any): any => {
                            if (data.channel?.inbox) {
                                return this.context.locale.getMessage(`components.NotificationManager.channels.Inbox`);
                            }
                            else if (data.channel?.email) {
                                return this.context.locale.getMessage(`components.NotificationManager.channels.Email`);
                            }
                            else if (data.channel?.sms) {
                                return this.context.locale.getMessage(`components.NotificationManager.channels.Sms`);
                            }
                            else if (data.channel?.toast) {
                                return this.context.locale.getMessage(`components.NotificationManager.channels.Toast`);
                            }
                            else {
                                return null;
                            }
                        },
                        formatter: (value: any, data: any) => {
                            let html = "";

                            if (data.channel?.inbox) {
                                html += `${this.context.locale.getMessage(`components.NotificationManager.channels.Inbox`)}, `;
                            }
                            if (data.channel?.email) {
                                html += `${this.context.locale.getMessage(`components.NotificationManager.channels.Email`)}, `;
                            }
                            if (data.channel?.sms) {
                                html += `${this.context.locale.getMessage(`components.NotificationManager.channels.Sms`)}, `;
                            }
                            if (data.channel?.toast) {
                                html += `${this.context.locale.getMessage(`components.NotificationManager.channels.Toast`)}, `;
                            }

                            if (html.endsWith(", ")) {
                                html = html.slice(0, -2);
                            }

                            return `<div class="cell">${html}</div>`
                        },
                        label: "tables.columns.channel",
                        minWidth: 220,
                        ellipsis: true,
                        sortable: true
                    },
                ]
            }
        });

        // Handle menu
        this.table.onItemSelect = (item: FilterItem) => {
            // Add new user?
            if (item.name == "Add") {
                this.openNew();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    private async openDetail(data: any): Promise<void> {
        // Detail options
        let options: NotificationDetailOptions = {
            style: "Light",
            title: data.name,
            subtitle: `components.NotificationManager.types.${data.type}.label`,
            notificationId: data.id,
            closable: true,
            menu: [
                {
                    name: "Delete",
                    label: "labels.delete",
                    escalated: true
                }
            ]
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = {
                ...this.detail.options,
                ...options
            };

            // Force reload
            await this.detail.load();

            // Not continue
            return;
        }

        // New detail
        this.detail = new NotificationDetail(this.context, options);

        // Menu select
        this.detail.onMenuSelect = (item: MenuItem)=> {
            // Delete action?
            if (item.name == "Delete") {
                this.detail.delete();
            }
        }

        // Refresh table on detail update
        this.detail.onDetailUpdate = async () => {
            await this.table.load();
        }

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.notificationId);
            this.detail = null;
        }

        // Attach detail and redraw map because of viewport changed
        this.detail.attach();
    }

    public openNew(): void {
        // Create notification form
        let form = new NotificationForm(this.context, {
            style: "Light",
            notification: {
                name: null,
                type: null
            },
            title: "components.NotificationForm.title",
            overlay: true,
            closable: true
        });

        // Referesh when created
        form.onSubmit = async () => {
            await this.table.load();
        }

        // Attach
        form.attach();
    }
}
