// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../muklit/assets/images/markers/road/24/24-markers-road-accident.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../muklit/assets/images/markers/road/24/24-markers-road-restriction_02.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../../muklit/assets/images/markers/road/24/24-markers-road-danger.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../../muklit/assets/images/markers/road/24/24-markers-road-roadwork.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../../../muklit/assets/images/markers/road/24/24-markers-road-road_non_weather.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.invipo-traffic-event-list div.content div.item div.icon-accident {
  background-color: #ec383a;
}
div.invipo-traffic-event-list div.content div.item div.icon-accident:after {
  -webkit-mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
div.invipo-traffic-event-list div.content div.item div.icon-closure {
  background-color: #ffbb00;
}
div.invipo-traffic-event-list div.content div.item div.icon-closure:after {
  -webkit-mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
div.invipo-traffic-event-list div.content div.item div.icon-danger {
  background-color: #c128b9;
}
div.invipo-traffic-event-list div.content div.item div.icon-danger:after {
  -webkit-mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
div.invipo-traffic-event-list div.content div.item div.icon-roadworks {
  background-color: #298cff;
}
div.invipo-traffic-event-list div.content div.item div.icon-roadworks:after {
  -webkit-mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
div.invipo-traffic-event-list div.content div.item div.icon-info {
  background-color: #47cff5;
}
div.invipo-traffic-event-list div.content div.item div.icon-info:after {
  -webkit-mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}`, "",{"version":3,"sources":["webpack://./src/invipo/components/traffic/traffic-event-list/traffic-event-list.scss"],"names":[],"mappings":"AASQ;EACE,yBAAA;AARV;AAUU;EACE,2DAAA;AARZ;AAYQ;EACE,yBAAA;AAVV;AAYU;EACE,2DAAA;AAVZ;AAcQ;EACE,yBAAA;AAZV;AAcU;EACE,2DAAA;AAZZ;AAgBQ;EACE,yBAAA;AAdV;AAgBU;EACE,2DAAA;AAdZ;AAkBQ;EACE,yBAAA;AAhBV;AAkBU;EACE,2DAAA;AAhBZ","sourcesContent":["@import \"~muklit/styles/colors\";\r\n@import \"~muklit/styles/mixins\";\r\n@import \"../../../styles/colors\";\r\n\r\ndiv.invipo-traffic-event-list {\r\n\r\n  div.content {\r\n    div.item {\r\n      div.icon {\r\n        &-accident {\r\n          background-color: #ec383a;\r\n\r\n          &:after {\r\n            -webkit-mask-image: url(\"~muklit/assets/images/markers/road/24/24-markers-road-accident.svg\");\r\n          }\r\n        }\r\n\r\n        &-closure {\r\n          background-color: #ffbb00;\r\n\r\n          &:after {\r\n            -webkit-mask-image: url(\"~muklit/assets/images/markers/road/24/24-markers-road-restriction_02.svg\");\r\n          }\r\n        }\r\n\r\n        &-danger {\r\n          background-color: #c128b9;\r\n\r\n          &:after {\r\n            -webkit-mask-image: url(\"~muklit/assets/images/markers/road/24/24-markers-road-danger.svg\");\r\n          }\r\n        }\r\n\r\n        &-roadworks {\r\n          background-color: #298cff;\r\n\r\n          &:after {\r\n            -webkit-mask-image: url(\"~muklit/assets/images/markers/road/24/24-markers-road-roadwork.svg\");\r\n          }\r\n        }\r\n\r\n        &-info {\r\n          background-color: #47cff5;\r\n\r\n          &:after {\r\n            -webkit-mask-image: url(\"~muklit/assets/images/markers/road/24/24-markers-road-road_non_weather.svg\");\r\n          }\r\n        }\r\n      }\r\n    }\r\n  }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
